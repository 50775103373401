import { useEffect, useState } from "react";

import { Card, Grid } from "@mui/material";

import SoftBox from "components/SoftBox";

import DashboardLayout from "pages/layout/dashboard-layout";
import ProductsDataTable from "pages/admin/products/components/products-data-table";
import ActionCell from "pages/admin/products/components/action-cell";

import productsTableData from "pages/admin/products/data/table-data";
import { get, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";

const Products = (props) => {
  const [data, setData] = useState(productsTableData);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(null);

  useEffect(() => {
    get(`${SERVER_URL_PREFIX}/api/admin/products`)
      .then(response => response.json())
      .then(data => {
        return data.map(p => ({
          product: [p.name, { image: `${RESOURCES_SERVER_URL_PREFIX}${p.imageLocation}` }],
          unitPrice: [{ price: p.unitPriceBam, currency: "BAM" }, { price: p.unitPriceEur, currency: "EUR" }],
          size: p.quantity,
          sales: p.specialOffers.map(offer => ({ quantity: offer.quantity, price: offer.priceBAM, currency: "BAM", secondPrice: offer.priceEUR, secondCurrency: "EUR" })),
          status: p.status,
          action: <ActionCell productId={p.id} />
        }))
      })
      .then(mappedProducts => {
        setData({ ...productsTableData, rows: mappedProducts });
        setDataError(null);
      })
      .catch(error => setDataError(error))
      .finally(() => setDataLoading(false));
  }, []);

  return (
    <DashboardLayout>
      <Grid container>
        <Grid item xs={12}>
          <SoftBox my={3}>
            <Card>
              <ProductsDataTable
                table={data}
                entriesPerPage={{
                  defaultValue: 7,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
                loading={dataLoading}
                error={dataError}
              />
            </Card>
          </SoftBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default Products;