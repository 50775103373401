import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const ProductGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {/* Main Image with Fixed Height and Full Width */}
      <Box
        sx={{
          width: "100%",
          height: { xs: 400, md: 800 },
          overflow: "hidden",
          mb: 2,
          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
      >
        <img src={selectedImage} alt="Selected product" />
      </Box>

      {/* Thumbnail Images */}
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        overflow="auto"
        gap={0.5}
        px={1}
        sx={{
          padding: "6px 6px 20px 6px",
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => handleThumbnailClick(image)}
            sx={{
              width: 80,
              height: 80,
              objectFit: "cover",
              cursor: "pointer",
              transition: "transform 0.2s ease",
              border: image === selectedImage ? "3px solid #7edde3" : "3px solid transparent",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

ProductGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProductGallery;
