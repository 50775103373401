import React, { useState } from "react";
import { Box } from "@mui/material";
import { useSwipeable } from "react-swipeable";

const MobileReviewGallery = ({ screenshots }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === screenshots.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? screenshots.length - 1 : prevIndex - 1));
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventScrollOnSwipe: true,
    trackTouch: true,
  });

  return (
    <Box
      sx={{
        position: "relative",
        maxWidth: "100%",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Image Display with Swipe Handlers */}
      <Box
        {...swipeHandlers}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          height: "300px",
          overflow: "hidden",
        }}
      >
        <Box
          component="img"
          src={screenshots[currentIndex]}
          alt={`Screenshot ${currentIndex + 1}`}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
            transition: "transform 0.3s ease",
          }}
        />
      </Box>

      {/* Pagination Dots */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
          gap: "0.5rem",
        }}
      >
        {screenshots.map((_, index) => (
          <Box
            key={index}
            onClick={() => handleDotClick(index)}
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor: index === currentIndex ? "primary.main" : "lightgrey",
              cursor: "pointer",
              transition: "background-color 0.3s",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MobileReviewGallery;
