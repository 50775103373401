import React, { useRef, useState, useEffect } from "react";
import { Box, IconButton, Icon } from "@mui/material";
import SuggestedProduct from "pages/user/product-pages/components/suggested-product";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import { useSoftUIController } from "context";
import config from "config/config";

const ScrollableProductList = ({ products, selectedCurrency, handleAddToCart }) => {
  const scrollRef = useRef(null);
  const [showArrows, setShowArrows] = useState({ left: false, right: false });

  const [controller] = useSoftUIController();
  const { cartItems } = controller;

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setShowArrows({
          left: scrollLeft > 0,
          right: scrollLeft + clientWidth < scrollWidth,
        });
      }
    };

    const handleResize = () => {
      handleScroll();
    };

    const delayedCheck = setTimeout(() => {
      handleScroll();
    }, 100);

    const currentScrollRef = scrollRef.current;
    currentScrollRef?.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(delayedCheck);
      currentScrollRef?.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scroll = (direction) => {
    const scrollAmount = direction === "left" ? -270 : 270;
    scrollRef.current?.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  const getCartItemQuantity = (productName) => {
    const product = cartItems.find((item) => item.name === productName);
    return product ? product.quantity : 0;
  };

  const getMaxCartQuantity = (productName) => {
    return config.appSettings.maxQuantityPerItem - getCartItemQuantity(productName);
  };

  return (
    <Box position="relative" pt="2rem">
      {/* Left Arrow */}
      {showArrows.left && (
        <IconButton
          onClick={() => scroll("left")}
          sx={{
            position: "absolute",
            top: "50%",
            left: "0.5rem",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: "white",
            boxShadow: 3,
            "&:hover": { backgroundColor: "lightgray" },
          }}
        >
          <Icon>chevron_left</Icon>
        </IconButton>
      )}

      {/* Right Arrow */}
      {showArrows.right && (
        <IconButton
          onClick={() => scroll("right")}
          sx={{
            position: "absolute",
            top: "50%",
            right: "0.5rem",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: "white",
            boxShadow: 3,
            "&:hover": { backgroundColor: "lightgray" },
          }}
        >
          <Icon>chevron_right</Icon>
        </IconButton>
      )}

      {/* Scrollable container */}
      <Box
        ref={scrollRef}
        sx={{
          display: "flex",
          justifyContent:
            products.length * 270 < scrollRef.current?.clientWidth ? "center" : "flex-start", // Dynamically center if items fit
          alignItems: "center", // Optional: vertical alignment
          overflowX: "auto",
          maxWidth: "100%",
          px: "1rem",
          gap: "1rem",
          scrollBehavior: "smooth",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {products.map((product) => (
          <Box key={product.id} sx={{ minWidth: "270px", flex: "0 0 auto" }}>
            <SuggestedProduct
              id={product.id.toString()}
              title={product.name}
              type={product.type}
              compositeProductItems={product.compositeProductItems}
              price={
                selectedCurrency.value === "bam"
                  ? product.unitPriceBam.toString()
                  : product.unitPriceEur.toString()
              }
              currency={selectedCurrency}
              quantity={product.quantity}
              image={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`}
              onAddToCart={handleAddToCart}
              specialOffers={product.specialOffers}
              lazyLoading={true}
              canAddToCart={getMaxCartQuantity(product.name) > 0}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ScrollableProductList;
