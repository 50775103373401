import React from 'react';
import { useSoftUIController } from 'context';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = () => {
  const [controller] = useSoftUIController();
  const { isAuthenticated } = controller;

  return (
    isAuthenticated ? <Outlet /> : <Navigate to="/login" />
  );
};

export default PrivateRoute;