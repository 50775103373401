import { Icon, IconButton } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import QuantityCounter from "pages/user/configurator/quantity-counter";
import PropTypes from "prop-types";
import { formatPrice } from "utils/formatPrice";

const ProductItem = ({
  id,
  name,
  price,
  unitQuantity,
  currency = "BAM",
  realPrice = 0,
  calculatedPrice = 0,
  quantity = 1,
  img,
  handleIncreaseQuantity,
  handleDecreaseQuantity,
  handleClose,
  readOnly,
  variant = "default",
}) => {
  if (variant === "preview") {
    return (
      <SoftBox display="flex" gap="1rem">
        <SoftBox>
          <SoftBox
            component="img"
            src={img}
            alt={`Proizvod ${name}`}
            width="5rem"
            borderRadius="lg"
          />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" gap="0.7rem" width="100%">
          <SoftBox display="flex" justifyContent="space-between" alignItems="start">
            <SoftBox display="flex" flexDirection="column">
              <SoftBox display="flex" alignItems="center">
                <SoftTypography
                  sx={({ typography }) => ({
                    fontSize: typography.size.sm,
                    fontWeight: typography.fontWeightMedium,
                  })}
                >
                  {name}
                  {unitQuantity ? "," : ""}&nbsp;&nbsp;
                </SoftTypography>
                <SoftTypography
                  sx={({ typography }) => ({
                    fontSize: typography.size.xs,
                  })}
                >
                  {unitQuantity}
                </SoftTypography>
              </SoftBox>

              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.xs,
                  fontWeight: typography.fontWeightMedium,
                  color: "#98999e",
                })}
              >
                {formatPrice(Number(price), currency)}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" justifyContent="space-between" alignItems="baseline">
            <SoftBox display="flex" alignItems="end" gap="0.3rem">
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.xs,
                  fontWeight: typography.fontWeightRegular,
                  color: "#98999e",
                  pb: "0.05rem",
                })}
              >
                Količina: {quantity} kom
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" alignItems="baseline">
              <SoftBox display="flex" alignItems="baseline">
                {realPrice !== calculatedPrice && (
                  <SoftTypography
                    sx={({ typography }) => ({
                      fontSize: typography.size.xs,
                      fontWeight: typography.fontWeightRegular,
                      color: "#98999e",
                      fontStyle: "italic",
                      textDecoration: "line-through",
                    })}
                  >
                    {realPrice} {currency}
                  </SoftTypography>
                )}
                <SoftTypography
                  sx={({ typography }) => ({
                    fontSize: typography.size.xs,
                    fontWeight: typography.fontWeightBold,
                    color: "#98999e",
                  })}
                >
                  &nbsp;&nbsp;{formatPrice(Number(calculatedPrice), currency)}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    );
  }

  return (
    <SoftBox display="flex" gap="1rem">
      <SoftBox>
        <SoftBox
          component="img"
          src={img}
          alt={`Proizvod ${name}`}
          width="5rem"
          borderRadius="lg"
        />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" gap="0.7rem" width="100%">
        <SoftBox display="flex" justifyContent="space-between" alignItems="start">
          <SoftBox display="flex" flexDirection="column">
            <SoftBox display="flex" alignItems="center">
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.sm,
                  fontWeight: typography.fontWeightMedium,
                })}
              >
                {name}
                {unitQuantity ? "," : ""}&nbsp;&nbsp;
              </SoftTypography>
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.xs,
                })}
              >
                {unitQuantity}
              </SoftTypography>
            </SoftBox>

            {variant === "minimal" ? (
              <SoftBox display="flex" alignItems="end" gap="0.3rem">
                <QuantityCounter
                  value={quantity}
                  onIncrease={() => {
                    handleIncreaseQuantity(id);
                  }}
                  onDecrease={() => {
                    handleDecreaseQuantity(id);
                  }}
                  readOnly={true}
                />
                <SoftTypography
                  sx={({ typography }) => ({
                    fontSize: typography.size.xs,
                    fontWeight: typography.fontWeightRegular,
                    color: "#98999e",
                    pb: "0.05rem",
                  })}
                >
                  kom
                </SoftTypography>
              </SoftBox>
            ) : (
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.xs,
                  fontWeight: typography.fontWeightMedium,
                  color: "#98999e",
                })}
              >
                {formatPrice(Number(price), currency)}
              </SoftTypography>
            )}
          </SoftBox>
          {!readOnly && (
            <IconButton
              size="small"
              color="secondary"
              sx={{ padding: 0 }}
              variant="contained"
              onClick={() => {
                handleClose(id);
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          )}
        </SoftBox>
        {variant !== "minimal" && (
          <SoftBox display="flex" justifyContent="space-between" alignItems="baseline">
            <SoftBox display="flex" alignItems="end" gap="0.3rem">
              <QuantityCounter
                value={quantity}
                onIncrease={() => {
                  handleIncreaseQuantity(id);
                }}
                onDecrease={() => {
                  handleDecreaseQuantity(id);
                }}
                readOnly={readOnly}
              />
              {readOnly && (
                <SoftTypography
                  sx={({ typography }) => ({
                    fontSize: typography.size.xs,
                    fontWeight: typography.fontWeightRegular,
                    color: "#98999e",
                    pb: "0.05rem",
                  })}
                >
                  kom
                </SoftTypography>
              )}
            </SoftBox>
            <SoftBox display="flex" alignItems="baseline">
              <SoftBox display="flex" alignItems="baseline">
                {realPrice !== calculatedPrice && (
                  <SoftTypography
                    sx={({ typography }) => ({
                      fontSize: typography.size.xs,
                      fontWeight: typography.fontWeightRegular,
                      color: "#98999e",
                      fontStyle: "italic",
                      textDecoration: "line-through",
                    })}
                  >
                    {formatPrice(Number(realPrice), currency)}
                  </SoftTypography>
                )}
                <SoftTypography
                  sx={({ typography }) => ({
                    fontSize: typography.size.xs,
                    fontWeight: typography.fontWeightBold,
                    color: "#98999e",
                  })}
                >
                  &nbsp;&nbsp;{formatPrice(Number(calculatedPrice), currency)}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        )}
      </SoftBox>
    </SoftBox>
  );
};

ProductItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.string,
  unitQuantity: PropTypes.string,
  currency: PropTypes.string,
  realPrice: PropTypes.number,
  calculatedPrice: PropTypes.number,
  quantity: PropTypes.number,
  img: PropTypes.string,
  handleIncreaseQuantity: PropTypes.func,
  handleDecreaseQuantity: PropTypes.func,
  handleClose: PropTypes.func,
  readOnly: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "read-only", "minimal", "preview"]),
};

export default ProductItem;
