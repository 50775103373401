import { Divider, Grid, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";
import { HashLink as Link } from "react-router-hash-link";
import ProductImage from "../about-products/product-image";
import ProductDescription from "../about-products/product-description";
import brand from "assets/images/logos/mini-logo-transparent-green.png";
import CoverText from "pages/user/components/cover-text";

import breakpoints from "assets/theme/base/breakpoints";
import { useEffect, useMemo, useState } from "react";
import SocialFollowComponent from "pages/user/components/social-follow-component";
import { useDocumentTitle } from "hooks/use-document-title";
import { useSoftUIController } from "context";
import { SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import Product from "pages/user/products/product";
import { get } from "api";
import ProductSkeleton from "../products/product/skeleton";
import { addCartItem } from "context";
import { updateProduct } from "context";
import { setOpenConfigurator } from "context";
import CoverImageWithText from "pages/user/components/cover-image-with-text";
import ProductPriceDetails from "pages/user/components/product-price-details";
import Signature from "pages/user/components/signature";
import FAQSection from "pages/user/home/components/faq-section";
import GridBox from "pages/user/components/grid-box";
import config from "config/config";
import MobileReviewGallery from "./components/MobileReviewGallery/MobileReviewGallery";
import { useMetaTag } from "hooks/use-document-title";
import ProblemSolutionSection from "pages/user/home/components/problem-solution-section";
import useGTMEvent from "hooks/use-gtm-event";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "utils/formatPrice";

export function mapProductNameToFrontPageURI(productName) {
  const mappings = {
    "Krema protiv bora": "krema-protiv-bora",
    "Zeolitna maska za lice": "zeolitna-maska-za-lice",
    "Serum za lice": "serum-za-lice",
    "All-In-One set": "all-in-one-set",
    "Krema-Serum set": "krema-serum-set",
    "Maska-Serum set": "maska-serum-set",
  };

  const formattedName = productName.trim();

  return "/aboutproducts/" + (mappings[formattedName] || "mama-za-pet-setovi");
}

const reviewScreenshots = [
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review1.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review2.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review3.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review4.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review5.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review6.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review7.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review8.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review9.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review10.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review11.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review12.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review13.webp`,
  `${RESOURCES_SERVER_URL_PREFIX}/static/images/reviews/review14.webp`,
];

const Home = (props) => {
  useDocumentTitle("Mama za pet | Prirodna Kozmetika za Lice – Krema, Maska, Serum");
  useMetaTag(
    "description",
    "Otkrijte prirodnu kozmetiku sa zeolitom i eteričnim uljima! Mama za pet donosi kremu, masku i serum koji njeguju vašu kožu i uklanjaju nepravilnosti."
  );
  useMetaTag(
    "keywords",
    "prirodna kozmetika, krema za lice, zeolitna maska za lice, serum za lice, zeolit, eterična ulja"
  );

  const [controller, dispatch] = useSoftUIController();
  const { selectedCurrency, cartItems } = controller;
  const sendEventToGTM = useGTMEvent();
  const navigate = useNavigate();

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const handleAddToCart = (product, eventSource) => {
    const currentProduct = cartItems.find((item) => item.id === product.id);
    get(
      `${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${
        currentProduct ? currentProduct.quantity + 1 : product.quantity
      }&currency=${selectedCurrency.value.toUpperCase()}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!currentProduct) {
          addCartItem(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
          });
          sendEventToGTM("add_to_cart", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            quantity: 1,
            page_source: "Home",
            event_source: eventSource,
          });
        } else {
          const newQuantity = currentProduct.quantity + 1;
          updateProduct(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
            quantity: newQuantity,
          });
          sendEventToGTM("update_cart_quantity", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            new_quantity: newQuantity,
            page_source: "Home",
            event_source: eventSource,
          });
        }
      });
  };

  const handleAdd = (product, eventSource) => {
    setTimeout(handleConfiguratorOpen, 300);
    handleAddToCart(product, eventSource);
  };

  const getCartItemQuantity = (productName) => {
    const product = cartItems.find((item) => item.name === productName);

    if (product) {
      return product.quantity;
    }

    return 0;
  };

  const isProductMaxQuantityPerItemReached = (productName) => {
    return getCartItemQuantity(productName) >= config.appSettings.maxQuantityPerItem;
  };

  const getProductsAddToCardData = (productName) => {
    const product = products.find((p) => p.name === productName);

    if (product) {
      const { id, name, imageLocation, quantity, unitPriceBam, unitPriceEur } = product;
      const price = selectedCurrency?.value === "bam" ? unitPriceBam : unitPriceEur;

      return {
        id: String(id),
        name: name,
        img: `${RESOURCES_SERVER_URL_PREFIX}${imageLocation}`,
        quantity: 1,
        unitPrice: price,
        unitQuantity: quantity,
      };
    }

    return undefined;
  };

  const styleForCoverImage = useMemo(() => {
    if (innerWidth < breakpoints.values.md) {
      if (innerWidth <= 365) {
        return {
          width: "100%",
          height: "310px",
        };
      }
      if (innerWidth > 400) {
        return {
          width: "100%",
          height: "360px",
        };
      }
    }
    if (innerWidth < breakpoints.values.xl) {
      return {
        maxHeight: "450px",
        height: "450px",
      };
    }
    if (innerWidth < breakpoints.values.xxl) {
      return {
        maxHeight: "500px",
        height: "500px",
      };
    }
    if (innerWidth < 1950) {
      return {
        maxHeight: "600px",
        height: "600px",
      };
    }
    return {
      maxHeight: "650px",
      height: "650px",
    };
  }, [innerWidth]);

  useEffect(() => {
    window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
    return () => window.removeEventListener("resize", () => setInnerWidth(window.innerWidth));
  }, []);

  return (
    <PageLayout>
      <Grid container>
        <Grid
          item
          sx={{
            ...styleForCoverImage,
            backgroundImage: `url(${RESOURCES_SERVER_URL_PREFIX}/static/images/cover-cream-natalija-3840.webp)`,
            backgroundSize: { xs: "cover", md: "cover" },
            backgroundPosition: {
              xs: "calc(100% + 14rem) center", // Focus on the right edge with 3rem offset
              md: "center", // Default center position for larger screens
            },
            width: "100%",
            display: "flex",
            alignItems: "center",
            pl: { xs: "0.2rem", md: "1rem", xl: "4rem" },
          }}
        >
          {/* Pokazni template za tekst preko slike - NE BRISATI */}
          <CoverText
            sx={{
              // backgroundColor: "rgba(128, 128, 128, 0.1)",
              padding: { xs: "1rem", xxl: "2rem" },
              borderRadius: "1rem",
              maxWidth: { xs: "60%", sm: "60%", md: "55%", xxl: "700px" },
              textAlign: "left",
            }}
            title="Zaboravi na skupe anti–age tretmane lica i neujednačen ten"
            description={
              innerWidth < breakpoints.values.sm
                ? "Sve što ti treba spakovano je u Mama za pet proizvodima sa zeolitom i biljnim eteričnim uljima."
                : "Sve što ti treba spakovano je u Mama za pet proizvodima sa zeolitom i biljnim eteričnim uljima koji dubinski hrane kožu, vraćaju njen prirodni sjaj i usporavaju znakove starenja."
            }
            // hideDescription={innerWidth < breakpoints.values .lg ? true : false}
            titleVariant={innerWidth < breakpoints.values.md ? "h5" : "h3"}
            descriptionVariant={innerWidth < breakpoints.values.md ? "body2" : "body2"}
            component={
              <SoftButton
                component={Link}
                to="/store"
                color="info"
                // variant="gradient"
                sx={{ maxWidth: "20rem", fontSize: "0.7rem" }}
              >
                {/* <Icon>shopping_bag</Icon>&nbsp;  */}
                Posjeti prodavnicu
              </SoftButton>
            }
            variant={innerWidth < breakpoints.values.md ? "h5" : "h3"}
            content={[
              {
                order: 0,
                textArray: [
                  "U svakom pakovanju čeka te eterično ulje smilja koje još",
                  "nazivalju i zlatnim uljem za lice",
                ],
              },
              {
                order: 1,
                textArray: [
                  "Zaboravi na skupe anti-age i detoks tretmane lica",
                  "Sve što ti treba spakovano je u Kremi protiv",
                  "bora Mama za pet sa zeolitom",
                ],
              },
            ]}
          />
        </Grid>
      </Grid>

      <Signature sx={{ pt: "3rem" }} />

      {/* Traka zeolit, ulja, priroda, fale ikonice */}
      <Divider sx={{ mb: "0" }} />
      <SoftBox
        display="flex"
        alignItems="flex-start"
        position="relative"
        // minHeight="16rem"
        justifyContent="space-between"
        flexDirection={innerWidth < breakpoints.values.lg ? "column" : "row"}
        px={
          innerWidth < breakpoints.values.lg
            ? innerWidth < breakpoints.values.md
              ? "1rem"
              : "4rem"
            : innerWidth > breakpoints.values.xl
            ? "5rem"
            : "1rem"
        }
        py={innerWidth < breakpoints.values.lg ? "2rem" : "2.5rem"}
        gap={innerWidth < breakpoints.values.lg ? "3rem" : "3rem"}
        sx={{
          // backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          //   `${linearGradient(
          //     rgba("#fafafa", 0.5),
          //     rgba("#fafafa", 0.9),
          //   )}`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
          // backgroundColor: "#f8f8f8",
        }}
      >
        <GridBox
          imageUrl={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/nature-leaf-icon.png`}
          title="Prirodni proizvodi"
          description={`U svakom pakovanju čekaju te zeolit i eterična ulja koja pomažu kod akni, ožiljaka, dermatitisa, ekcema i snažno regenerišu kožu. Formulacije bogate aktivnim sastojcima pružaju dubinsku hidrataciju, usporavaju proces starenja i vraćaju koži njen prirodni sjaj i elastičnost.`}
          sx={{ width: innerWidth < breakpoints.values.lg ? "100%" : "90%" }}
        />
        <GridBox
          imageUrl={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/styled/hot-stones.png`}
          title="Zeolit"
          description={`Preventivno djeluje na brojna oboljenja jer iz organizma eliminiše živu, toksine, viruse, alergene i brojne druge štetne supstance. Zeolit dubinski prodire u ćelije kože i čisti ih. To znači da svakodnevnim nanošenjem proizvoda Mama za pet uklanjaš zagađenja, toksine i teške metale. Lice ne izgleda umorno, već zdravo i njegovano. Takva koža manje je sklona nastanku bora.`}
          sx={{ width: innerWidth < breakpoints.values.lg ? "100%" : "90%" }}
        />
        <GridBox
          imageUrl={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/styled/eye-dropper.png`}
          title="Eterična ulja"
          description={`Zamisli da tvoja skincare rutina obuhvata 100% prirodna eterična ulja koja su se tokom godina najbolje pokazala u ublažavanju crvenila, smanjenju bora i sprečavanju nastanka novih. To bi značilo da treba da nanosiš najmanje 20 proizvoda na lice odjednom, što svakako nije praktično niti finansijski isplativo. Mi smo sve to spakovali u samo tri proizvoda iz seta Mama za pet.`}
          sx={{ width: innerWidth < breakpoints.values.lg ? "100%" : "90%" }}
        />
      </SoftBox>
      <Divider sx={{ mb: "0", mt: innerWidth < breakpoints.values.lg ? "2rem" : 0 }} />

      <SoftBox display="flex" justifyContent="space-between" px="2rem" py="3rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <SoftBox
              component="img"
              src={brand}
              alt="Mama za pet brand logo"
              sx={{ height: "4rem", mb: "1rem" }}
            />
            {/* <SoftTypography variant="h4" sx={{ color: "#000" }}>
                Da li su ovo tvoji problemi i kako ih rješavamo?
              </SoftTypography> */}
            <ProblemSolutionSection />
          </SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

      <CoverImageWithText
        imageUrl={`${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting/landscape/resize00001.webp`}
        title="Iskombinuj svoj set po prazničnoj cijeni!"
        description={
          "Svi proizvodi ujednačavaju ten. Koži vraćaju elastičnost i vlagu, smanjuju nepravilnosti. Lice je hidrirano. Dovoljno su blagi za svakodnevnu upotrebu, a istovremeno učinkoviti u borbi protiv tamnih mrlja, bubuljica i masnog sjaja. Savršeno rješenje za sve tipove kože, u svakom trenutku dana!"
        }
        buttonText="Posjeti prodavnicu"
        onButtonClick={() => navigate("/aboutproducts/all-in-one-set")}
        width="90%"
      />

      {/* Text divider */}
      {innerWidth > breakpoints.values.md && (
        <SoftBox display="flex" justifyContent="space-between" px="2rem" py="5rem">
          <SoftBox></SoftBox>
          <SoftBox>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <SoftBox
                component="img"
                src={brand}
                alt="Mama za pet brand logo"
                sx={{ height: "4rem", mb: "1rem" }}
              />
              <SoftTypography variant="h3" sx={{ color: "#000" }}>
                Recepture Mama za pet proizvoda stare su više od 20 godina
              </SoftTypography>
              <SoftTypography variant="body2" sx={{ color: "#000", mt: "1rem" }}>
                U svakom pakovanju čeka te zeolit i 12 biljnih eteričnih ulja koji pomažu kod akni,
                ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox></SoftBox>
        </SoftBox>
      )}

      {innerWidth < breakpoints.values.md && (
        <>
          <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="3rem" pb="1rem">
            <SoftBox></SoftBox>
            <SoftBox>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <SoftBox
                  component="img"
                  src={brand}
                  alt="Mama za pet brand logo"
                  sx={{ height: "4rem", mb: "1rem" }}
                />
                <SoftTypography variant="h4" sx={{ color: "#000" }}>
                  Šta kažu naši zadovoljni korisnici?
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
          <MobileReviewGallery screenshots={reviewScreenshots} />
          <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="5rem" pb="1rem">
            <SoftBox></SoftBox>
            <SoftBox>
              <SoftBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <SoftBox
                  component="img"
                  src={brand}
                  alt="Mama za pet brand logo"
                  sx={{ height: "4rem", mb: "1rem" }}
                />
                <SoftTypography variant="h4" sx={{ color: "#000" }}>
                  Pogledajte ponudu Mama za pet proizvoda
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
        </>
      )}
      {/* Kupi kremu za lice */}
      <Divider sx={{ mb: "0" }} />
      <Grid container sx={{ position: "relative" }}>
        <ProductImage
          image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/common/krema-square.webp`}
          link={mapProductNameToFrontPageURI("Krema protiv bora")}
        />
        <Grid
          item
          lg={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
          px={{ xs: "1rem", lg: "3rem" }}
          pt={{ xs: "1rem", lg: "0" }}
        >
          <ProductDescription
            title="Krema protiv bora"
            titleLink={mapProductNameToFrontPageURI("Krema protiv bora")}
            textVariant="body"
            textArray={[
              "Sve što treba suvoj, kombinovanoj i masnoj koži. Dubinski hidrira, hrani i štiti kožu, istovremeno smanjujući vidljivost bora i sitnih linija. Njena bogata formula vraća koži elastičnost i pruža joj svilenkasto glatki izgled.",
              // "Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot(takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
              // "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice."
            ]}
          />
          {products && products.length > 0 && (
            <ProductPriceDetails
              product={products.find((p) => p.name === "Krema protiv bora")}
              selectedCurrency={selectedCurrency?.value}
            />
          )}
          <SoftBox display="flex" alignItems="center" mt="1rem" gap="1rem">
            <SoftButton
              disabled={
                !products.find((p) => p.name === "Krema protiv bora") ||
                isLoadingProducts ||
                !!isProductMaxQuantityPerItemReached("Krema protiv bora")
              }
              variant="contained"
              color="success"
              onClick={() =>
                handleAdd(getProductsAddToCardData("Krema protiv bora"), "add_to_cart_button")
              }
            >
              <Icon sx={{ marginRight: "0.5rem" }}>add_shopping_cart</Icon>
              Dodaj u korpu
            </SoftButton>
            <SoftButton
              variant="text"
              color="info"
              href={mapProductNameToFrontPageURI("Krema protiv bora")}
            >
              Saznaj više
              <Icon sx={{ marginLeft: "0.5rem" }}>navigate_next</Icon>
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>

      {/* Kupi masku za lice */}
      <Divider
        sx={{
          mt: { xs: "4rem", lg: "0" },
          mb: "0",
        }}
      />
      <Grid container flexDirection={{ xs: "column-reverse", lg: "row" }}>
        <Grid
          item
          lg={6}
          md={12}
          px={{ xs: "1rem", lg: "3rem" }}
          pt={{ xs: "1rem", lg: "0" }}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <ProductDescription
            title="Zeolitna maska za lice"
            titleLink={mapProductNameToFrontPageURI("Zeolitna maska za lice")}
            textVariant="body"
            textArray={[
              "Zeolit u maski uklanja mrtve ćelije s površine kože, a da to i ne osjetiš, omogućavajući joj da ponovo prodiše. Zato ćeš primijetiti da ti je lice tonirano i zaglađeno. Maska se ne primjećuje na licu i izgleda kao da si nanijela kremu. Redovnom upotrebom koža postaje čvršća, blistavija i vidljivo podmlađena.",
            ]}
            // textArray={[
            //   "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice. Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot (takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
            //   "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice. Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot (takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti."
            // ]}
          />
          {products && products.length > 0 && (
            <ProductPriceDetails
              product={products.find((p) => p.name === "Zeolitna maska za lice")}
              selectedCurrency={selectedCurrency?.value}
            />
          )}
          <SoftBox display="flex" alignItems="center" mt="1rem" gap="1rem">
            <SoftButton
              disabled={
                !products.find((p) => p.name === "Zeolitna maska za lice") ||
                isLoadingProducts ||
                !!isProductMaxQuantityPerItemReached("Zeolitna maska za lice")
              }
              variant="contained"
              color="success"
              onClick={() =>
                handleAdd(getProductsAddToCardData("Zeolitna maska za lice"), "add_to_cart_button")
              }
            >
              <Icon sx={{ marginRight: "0.5rem" }}>add_shopping_cart</Icon>
              Dodaj u korpu
            </SoftButton>
            <SoftButton
              variant="text"
              color="info"
              href={mapProductNameToFrontPageURI("Zeolitna maska za lice")}
            >
              Saznaj više
              <Icon sx={{ marginLeft: "0.5rem" }}>navigate_next</Icon>
            </SoftButton>
          </SoftBox>
        </Grid>
        <ProductImage
          image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/common/maska-square.webp`}
          link={mapProductNameToFrontPageURI("Zeolitna maska za lice")}
        />
      </Grid>

      {/* Kupi serum za lice */}
      <Divider
        sx={{
          mt: { xs: "4rem", lg: "0" },
          mb: "0",
        }}
      />
      <Grid container>
        <ProductImage
          image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/common/serum-square.webp`}
          link={mapProductNameToFrontPageURI("Serum za lice")}
        />
        <Grid
          item
          lg={6}
          md={12}
          px={{ xs: "1rem", lg: "3rem" }}
          pt={{ xs: "1rem", lg: "0" }}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <ProductDescription
            title="Serum za lice"
            titleLink={mapProductNameToFrontPageURI("Serum za lice")}
            textVariant="body"
            textArray={[
              "Sve što treba suvoj, kombinovanoj i masnoj koži. Intenzivno hidrira, revitalizuje i ujednačava ten, prilagođavajući se svim tipovima kože. Njegova lagana tekstura brzo se upija, ostavljajući kožu mekom, nahranjenom i blistavom tokom cijelog dana.",
              // "Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot(takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
              // "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice."
            ]}
          />
          {products && products.length > 0 && (
            <ProductPriceDetails
              product={products.find((p) => p.name === "Serum za lice")}
              selectedCurrency={selectedCurrency?.value}
            />
          )}
          <SoftBox display="flex" alignItems="center" mt="1rem" gap="1rem">
            <SoftButton
              disabled={
                !products.find((p) => p.name === "Serum za lice") ||
                isLoadingProducts ||
                !!isProductMaxQuantityPerItemReached("Serum za lice")
              }
              variant="contained"
              color="success"
              onClick={() =>
                handleAdd(getProductsAddToCardData("Serum za lice"), "add_to_cart_button")
              }
            >
              <Icon sx={{ marginRight: "0.5rem" }}>add_shopping_cart</Icon>
              Dodaj u korpu
            </SoftButton>
            <SoftButton
              variant="text"
              color="info"
              href={mapProductNameToFrontPageURI("Serum za lice")}
            >
              Saznaj više
              <Icon sx={{ marginLeft: "0.5rem" }}>navigate_next</Icon>
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>

      {/* Kupi setove */}
      <Divider sx={{ m: "0", mt: innerWidth < breakpoints.values.lg ? "1rem" : "0" }} />
      <SoftBox display="flex" justifyContent="space-between" px="2rem" py="3rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <SoftBox
              component="img"
              src={brand}
              alt="Mama za pet brand logo"
              sx={{ height: "4rem", mb: "1rem" }}
            />
            <SoftTypography variant="h3" sx={{ color: "#000" }}>
              Mama za pet setovi
            </SoftTypography>
            {innerWidth > breakpoints.values.md && (
              <SoftTypography variant="body2" sx={{ color: "#000", mt: "1rem" }}>
                Svi proizvodi ujednačavaju ten, vraćaju koži elastičnost i vlagu te smanjuju
                nepravilnosti. Lice ostaje hidrirano i blistavo.
              </SoftTypography>
            )}
          </SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>
      <Divider sx={{ m: "0" }} />
      <SoftBox
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ backgroundColor: "#7eddd3", py: "1rem" }}
      >
        <Icon
          sx={{
            color: "#fff",
            fontSize: innerWidth < breakpoints.values.md ? "2rem !important" : "3rem !important",
          }}
        >
          new_releases
        </Icon>
        <SoftTypography
          variant="h5"
          fontSize={{ xs: "18px", md: "20px" }}
          sx={{ color: "#fff", mt: "1rem", textAlign: "center" }}
        >
          <b>Specijalna novogodišnja ponuda</b>
          {innerWidth < breakpoints.values.md ? <br /> : " -"} Umjesto {formatPrice(106, "KM")},
          platite samo <b>{formatPrice(97, "KM")}</b>!
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ m: "0" }} />
      <Grid
        container
        spacing={1}
        sx={{
          rowGap: "5rem",
        }}
      >
        {isLoadingProducts
          ? Array.from(new Array(3)).map((_, index) => <ProductSkeleton key={index} />)
          : products
              .filter((p) => p.type === "COMPOSITE")
              .map((product) => (
                <Product
                  key={product.id}
                  id={product.id.toString()}
                  title={product.name}
                  type={product.type}
                  compositeProductItems={product.compositeProductItems}
                  price={
                    selectedCurrency.value === "bam"
                      ? product.unitPriceBam.toString()
                      : product.unitPriceEur.toString()
                  }
                  currency={selectedCurrency}
                  quantity={product.quantity}
                  image={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`}
                  onAddToCart={handleAddToCart}
                  specialOffers={product.specialOffers}
                  lazyLoading={true}
                  isProductMaxCartQuantityPerItemReached={
                    !!isProductMaxQuantityPerItemReached(product.name)
                  }
                  showBadge={product.name === "All-In-One Set" ? true : false}
                  badgeText="NAJPRODAVANIJI"
                  badgeColor="#7eddd3"
                  badgeIcon="new_releases"
                  priceBeforeSale={106}
                  description={
                    product.name === "All-In-One set"
                      ? "Za prvu kupovinu ili ako ste potrošli sve zalihe, preporučujemo kombinaciju sva tri proizvoda."
                      : product.name === "Krema-Serum set"
                      ? "Zeolitna maska se sporije troši, pa ukoliko ti nedostaje kreme i seruma – ovo je pravi set za tebe."
                      : product.name === "Maska-Serum set"
                      ? "Ako imaš tinejdžera u kući koji zeolitnu masku nanosi skoro svako veče na bubuljice, a i tebi nedostaje jedna, ovaj set je pun pogodak."
                      : ""
                  }
                />
              ))}
      </Grid>

      <Divider sx={{ mt: "2rem", mb: innerWidth < breakpoints.values.lg ? "4rem" : "8rem" }} />
      {innerWidth > breakpoints.values.md && (
        <Grid container height="100%">
          <Grid item xs={12} xl={6} minHeight="100%">
            <SoftBox
              display="flex"
              justifyContent="space-between"
              px={{ xs: "2rem", lg: "4rem" }}
              minHeight="100%"
            >
              <SoftBox></SoftBox>
              <SoftBox>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  minHeight="100%"
                >
                  <SoftBox
                    component="img"
                    src={brand}
                    alt="Mama za pet brand logo"
                    sx={{ height: "4rem", mb: "1rem" }}
                  />
                  <SoftTypography variant="h3" sx={{ color: "#000" }}>
                    Šta kažu naši zadovoljni korisnici?
                  </SoftTypography>
                  <>
                    <SoftTypography variant="body2" sx={{ color: "#000", mt: "1rem" }}>
                      Pročitajte iskustva ljudi širom regiona koji koriste Mama za pet proizvode i
                      saznajte zašto ih svi preporučuju za jednostavniju i ljepšu svakodnevicu!
                    </SoftTypography>
                    <SoftButton
                      component={Link}
                      to="/store"
                      color="info"
                      // variant="gradient"
                      sx={{ maxWidth: "20rem", fontSize: "0.7rem", mt: "2rem" }}
                    >
                      {/* <Icon>shopping_bag</Icon>&nbsp;  */}
                      Posjeti prodavnicu
                    </SoftButton>
                  </>
                </SoftBox>
              </SoftBox>
              <SoftBox></SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12} xl={6}>
            <SoftBox
              sx={{ display: "flex", justifyContent: "center" }}
              mt={{ xs: "1rem", xl: 0 }}
              width="100%"
            >
              <SoftBox
                component="img"
                src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/home/reviews-shots-large.webp`}
                alt="Reviews"
                sx={{ objectFit: "cover" }}
                width={{ xs: "100%", lg: "80%", xl: "100%" }}
              />
            </SoftBox>
          </Grid>
        </Grid>
      )}

      {/* Text divider */}
      {/* <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="0" pb="5rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SocialFollowComponent />
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

      <Grid container>
        <Grid item md={0} sx={{ width: "100%", maxHeight: "40rem", minHeight: "20rem" }}>
          <Grid
            item
            sx={{
              ...styleForCoverImage,
              backgroundImage: `url(${RESOURCES_SERVER_URL_PREFIX}/static/images/cover-cream-natalija-3840.webp)`,
              backgroundSize: "cover",
              width: "100%",
              backgroundPosition: "center",
              display: "flex",
              alignItems: "center",
              pl: { xs: "1rem", xxl: "6rem" },
            }}
          ></Grid>
        </Grid>
      </Grid> */}

      {/* <Grid container> */}
      {/* <CardMedia
            component="video"
            src={`${RESOURCES_SERVER_URL_PREFIX}/static/videos/video-krema-1.mp4`}
            autoPlay
            loop
            muted
            loading="lazy"
            sx={{ borderRadius: "0", p: 0, m: 0, height: "100%", width: "100%", objectFit: "cover" }} /> */}
      {/* <Grid item md={12} sx={{ display: { xs: "none" } }}>
          <CardMedia
            component="video"
            src={`${RESOURCES_SERVER_URL_PREFIX}/static/videos/video-lice-2.mp4`}
            autoPlay
            loop
            muted
            sx={{ borderRadius: "0", p: 0, m: 0, height: "100%", width: "100%", objectFit: "cover" }} />
        </Grid> */}
      {/* </Grid> */}

      {/* Text divider */}
      {/* <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="5rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="2rem"
            textAlign="center"
          >
            <SoftTypography variant="h3" sx={{ color: "#000" }}>
              Uživajte u slikama
            </SoftTypography>
            <SoftBox display="flex" gap="3rem">
              <SoftTypography variant="body2" sx={{ color: "#000" }}>
                Svaki put kada zelite da uzivate, pogledajte slike ispod
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox> */}

      {/* Gallery */}
      {/* <SoftBox
        sx={{
          mt: { xs: "3rem", md: "5rem" },
        }}
      >
        <SimpleGallery
          galleryId="my-test-gallery"
          images={[
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-1.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-1.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-2.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-2.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-3.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-3.webp`,
              width: 5760,
              height: 3840,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-2.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-2.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-1.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-1.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-3.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-3.webp`,
              width: 5760,
              height: 3840,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg",
              width: 1875,
              height: 2500,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg",
              width: 1669,
              height: 2500,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-1.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-1.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-2.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-2.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg",
              width: 1875,
              height: 2500,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg",
              width: 1669,
              height: 2500,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg",
              width: 1875,
              height: 2500,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg",
              width: 1669,
              height: 2500,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg",
              width: 1875,
              height: 2500,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-1.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-1.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-2.webp`,
              thumbnailURL: `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-2.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
            {
              largeURL: "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg",
              thumbnailURL:
                "https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg",
              width: 2500,
              height: 1666,
            },
          ]}
        />
      </SoftBox> */}

      <Divider sx={{ mt: innerWidth < breakpoints.values.lg ? "4rem" : "7rem", mb: "0" }} />

      <CoverImageWithText
        imageUrl={`${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting/new/gallery-shooting-10.webp`}
        title="Najbolji prirodni proizvodi za njegu kože"
        description={`Mama za pet linija proizvoda, bazirana na moćnom zeolitu, nudi sve što vam treba za osvježen i njegovan izgled. Isprobajte moje proizvode i prigrlite prirodnu njegu. Pogledajte ponudu proizvoda u našoj prodavnici.`}
        buttonText="Posjeti prodavnicu"
        onButtonClick={() => navigate("/aboutproducts/all-in-one-set")}
        width="90%"
      />
      <Divider sx={{ mt: "0", mb: "4rem" }} />
      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="2rem" pb="2rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="2rem"
            textAlign="center"
          >
            <SoftBox
              component="img"
              src={brand}
              alt="Mama za pet brand logo"
              sx={{ height: "4rem", margin: "0 auto" }}
            />
            <SoftTypography variant="h3" sx={{ color: "#000" }}>
              Često postavljana pitanja
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>
      <FAQSection
        faqData={[
          {
            question: "Da li je krema protiv bora i dnevna i noćna?",
            answer:
              "Kremu protiv bora nanosite ujutro i naveče u tankom sloju, nakon čišćenja lica, i to na cijelo lice, područje oko očiju i dekolte. Krema ima bogatu, a ne vodenastu teksturu, zbog čega se nanosi u tankom sloju.",
          },
          {
            question: "Koliko često nanosim zeolitnu masku?",
            answer:
              "Zeolitnu masku/piling nanosiš jednom do dva puta sedmično prije spavanja. Dobro umasiraš na lice i dekolte i ostaviš tako preko noći (ne vidi se na licu, kao da si nanijela kremicu). Ujutru očistiš lice i naneseš kombinaciju seruma i kremice ili samo kremu Mamazapet.",
          },
          {
            question: "Da li serum zbog ulja masti lice?",
            answer:
              "Serum u kombinaciji sa kremicom i maskom uklanja mitisere i bubuljice i sprečava nastanak novih. Serum hidrira i tonira lice u roku od nekoliko sekundi. Brzo se upija i ne ostavlja masni trag. Sadrži vitamin E, umiruje kožu. Miriše predivno i Mama za pet ga koristi kao neku vrstu terapije prije spavanja.😊",
          },
          {
            question: "Da li prozvode mogu da koriste i tinejdžeri?",
            answer:
              "Roditelji za tinejdžere uzimaju kremicu i masku. Utiske imate i u recenzijama. Kremicu nanose, nakon čišćenja lica, ujutru i naveče u tankom sloju. Zeolitnu masku/piling nanose jednom do dva puta sedmično prije spavanja. Ne vidi se na licu, kao da su nanijeli kremicu. Maska može i svako veče na bubuljice.",
          },
          {
            question: "Da li proizvode mogu da koriste i muškarci?",
            answer:
              "Naravno. Nakon brijanja, krema i serum umiruju kožu, ublažavaju crvenilo, a zeolitna maska jednom sedmično pilinguje kožu i dubinski čisti pore. Rezultat je zdrava koža i ujednačen ten.",
          },
          {
            question: "Da li Mama za pet set mogu da koriste trudnice i porodilje?",
            answer:
              "Sasvim sigurno je da je za vas najbolje da tokom trudnoće i dojenja koristite samo prirodne proizvode, nasuprot agresivne hemije. Međutim, ako imate bilo kakvih nedoumica pitajte svog ljekara.",
          },
        ]}
      />

      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="3rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SocialFollowComponent />
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>
    </PageLayout>
  );
};

export default Home;
