import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";

import { useSoftUIController, setLayout } from "context";

function PageLayout({ background = "default", children, sx }) {
  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);

  return (
    <SoftBox
      // width="100vw"
      height="100%"
      // minHeight="100vh"
      bgColor={background}
      sx={{ ...sx }}
    >
      {children}
    </SoftBox>
  );
}

PageLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default PageLayout;
