import { useCallback } from 'react';

const useGTMEvent = () => {
  const sendEventToGTM = useCallback((eventName, eventData) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: eventName,
        ...eventData,
      });
      // console.log(`GTM Event Sent: ${eventName}`, eventData); // Debugging
    } else {
      console.warn('GTM dataLayer is not available.');
    }
  }, []);

  return sendEventToGTM;
};

export default useGTMEvent;
