import { Divider } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import config from "config/config";
import { useMetaTag } from "hooks/use-document-title";
import { useDocumentTitle } from "hooks/use-document-title";
import PageLayout from "pages/layout/page-layout";

const PrivacyPolicy = (props) => {
  useDocumentTitle("Politika Privatnosti | Mama za pet – Prirodni Proizvodi za Lice");
  useMetaTag(
    "description",
    "Pročitajte Politiku Privatnosti naše web stranice. Saznajte kako prikupljamo, koristimo i štitimo vaše lične podatke."
  );
  useMetaTag(
    "keywords",
    "mama za pet politika privatnosti, mama za pet privatnost, mama za pet privatnost podataka, mama za pet lični podaci"
  );

  return (
    <PageLayout>
      <Divider sx={{ mt: "0" }} />
      <SoftBox display="flex" justifyContent="center" px="2rem">
        <SoftTypography
          sx={{ color: "black !important" }}
          variant="h4"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Politika Privatnosti
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ mb: "2rem" }} />
      <SoftBox mt="2rem" px="2rem" maxWidth="1000px" margin="0 auto">
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Ova Politika Privatnosti opisuje kako Mamazapet ("mi", "nas", "naš") prikuplja, koristi i
          štiti vaše lične podatke kada posjećujete i koristite našu web stranicu mamazapet.com
          ("Web stranica"). Vaša privatnost nam je izuzetno važna, i mi se trudimo da zaštitimo vaše
          podatke u skladu sa važećim zakonima i propisima.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Prikupljanje informacija
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" component="div">
          Možemo prikupljati različite vrste ličnih podataka kada posjećujete našu Web stranicu, kao
          što su:
          <SoftBox component="ul" pl="1.5rem" sx={{ listStyleType: "disc" }}>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Informacije koje nam direktno pružite, kao što su vaše ime, adresa e-pošte i broj
              telefona kada se registrujete ili nas kontaktirate.
            </SoftBox>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Informacije o vašem korišćenju Web stranice, kao što su IP adresa, vrsta pretraživača,
              i podaci o posjećenim stranicama.
            </SoftBox>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Podaci prikupljeni putem kolačića i drugih tehnologija za praćenje.
            </SoftBox>
          </SoftBox>
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Korišćenje informacija
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" component="div">
          Prikupljene informacije koristimo kako bismo:
          <SoftBox component="ul" pl="1.5rem" sx={{ listStyleType: "disc" }}>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Omogućili pravilno funkcionisanje Web stranice i pružili vam tražene usluge.
            </SoftBox>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Poboljšali korisničko iskustvo, optimizovali naš sadržaj i analizirali korišćenje Web
              stranice.
            </SoftBox>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Komunicirali s vama u vezi sa našim proizvodima, uslugama i promocijama.
            </SoftBox>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Obezbijedili sigurnost naših sistema i zaštitili vaše podatke.
            </SoftBox>
          </SoftBox>
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Dijeljenje informacija
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" component="div">
          Vaše lične podatke nećemo prodavati, iznajmljivati ili dijeliti s trećim stranama bez
          vašeg pristanka, osim u sledećim situacijama:
          <SoftBox component="ul" pl="1.5rem" sx={{ listStyleType: "disc" }}>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Kada je to potrebno radi ispunjavanja zakonskih obaveza ili odgovora na pravne
              zahtjeve.
            </SoftBox>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Kada je to potrebno radi zaštite prava, imovine ili sigurnosti Mamazapet-a ili naših
              korisnika.
            </SoftBox>
            <SoftBox component="li" sx={{ color: "black !important" }}>
              Kada angažujemo treće strane da nam pružaju usluge koje podržavaju našu Web stranicu,
              pod uslovom da su te strane obavezane ugovorom da zaštite vaše podatke.
            </SoftBox>
          </SoftBox>
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Sigurnost podataka
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Preduzimamo odgovarajuće tehničke i organizacione mjere kako bismo zaštitili vaše lične
          podatke od neovlašćenog pristupa, upotrebe ili otkrivanja. Ipak, imajte u vidu da nijedna
          metoda prenosa podataka putem interneta nije 100% sigurna, te ne možemo garantovati
          apsolutnu sigurnost.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Vaša prava
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          U skladu sa važećim zakonima, imate pravo da pristupite svojim ličnim podacima, ispravite
          ih, obrišete ih, ograničite njihovu obradu ili uložite prigovor na njihovu obradu. Takođe,
          imate pravo na prenosivost podataka. Ako želite da ostvarite svoja prava, molimo vas da
          nas kontaktirate putem e-maila na {config.contact.infoEmail}.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Promjene u Politici Privatnosti
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Možemo povremeno ažurirati ovu Politiku Privatnosti kako bismo odražavali promjene u
          tehnologiji, zakonima i našim poslovnim praksama. Svaka promjena će biti objavljena na
          ovoj stranici, i vaš nastavak korišćenja naše Web stranice će značiti da prihvatate ove
          izmjene.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Kontaktirajte nas
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Ako imate bilo kakva pitanja o ovoj Politici Privatnosti, molimo vas da nas kontaktirate
          putem e-maila na {config.contact.infoEmail}.
        </SoftTypography>
      </SoftBox>
    </PageLayout>
  );
};

export default PrivacyPolicy;
