import PropTypes from "prop-types";

import SoftTypography from "components/SoftTypography";

function DefaultCell({ value, suffix }) {
  return (
    <div
      style={{
        textAlign: "right",
      }}
    >
      <SoftTypography
        variant="caption"
        fontWeight="medium"
        sx={({ palette }) => ({
          // color: palette["black"].main,
        })}
      >
        {value}
        {suffix && (
          <SoftTypography variant="caption" fontWeight="medium" color="secondary">
            &nbsp;&nbsp;{suffix}
          </SoftTypography>
        )}
      </SoftTypography>
    </div>
  );
}

DefaultCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DefaultCell;
