/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";

// Custom styles for SoftSelect
import styles from "components/SoftSelect/styles";

const SoftSelect = forwardRef(
  (
    { size = "medium", error, success, borderWidth = 1, minWidth = "160", withoutBorders, ...rest },
    ref
  ) => {
    const { light } = colors;

    return (
      <Select
        {...rest}
        ref={ref}
        styles={styles(size, error, success, borderWidth, minWidth, withoutBorders)}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: light.main,
            primary: light.main,
          },
        })}
      />
    );
  }
);

// Typechecking props for the SoftSelect
SoftSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  borderWidth: PropTypes.number,
  minWidth: PropTypes.string,
  withoutBorders: PropTypes.bool,
};

export default SoftSelect;
