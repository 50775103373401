import PropTypes from "prop-types";

import { Box, Modal } from "@mui/material";

import ImportOrdersForm from "pages/admin/orders/components/import-orders-form";

const ImportOrdersModal = ({
  open,
  handleOpen,
  handleClose,
  editMode,
  orderId,
  setForceUpdate,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ImportOrdersForm handleClose={handleClose} setForceUpdate={setForceUpdate} />
        </Box>
      </Modal>
    </div>
  );
};

ImportOrdersModal.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  editMode: PropTypes.bool,
  orderId: PropTypes.number,
  setForceUpdate: PropTypes.func,
};

export default ImportOrdersModal;
