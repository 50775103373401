/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Shop from "icons/Shop";
import Basket from "icons/Basket";
import Document from "icons/Document";
import Orders from "pages/admin/orders";
import Products from "pages/admin/products";
import Dashboard from "pages/admin/dashboard";
import OrderDetails from "pages/admin/order-details";
import ProductDetails from "pages/admin/product-details";
import LoginPage from "pages/admin/login";
import CustomerSupport from "icons/CustomerSupport";
import Reviews from "pages/admin/reviews";

const routes = [
  { type: "title", title: "Opcije", key: "title-pages-opcije" },
  {
    type: "collapse",
    name: "Kontrolna tabla",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    icon: <Shop size="12px" />,
    noCollapse: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Narudžbe",
    key: "orders",
    route: "/orders",
    component: <Orders />,
    icon: <Basket size="12px" />,
    noCollapse: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Proizvodi",
    key: "products",
    route: "/products",
    component: <Products />,
    icon: <Document size="12px" />,
    noCollapse: true,
    private: true,
  },
  {
    type: "collapse",
    name: "Recenzije",
    key: "reviews",
    route: "/reviews",
    component: <Reviews />,
    icon: <CustomerSupport size="12px" />,
    noCollapse: true,
    private: true,
  },
  {
    key: "product-details",
    route: "/products/:productId",
    component: <ProductDetails />,
    private: true,
  },
  {
    key: "order-details",
    route: "/order/:orderId",
    component: <OrderDetails />,
    private: true,
  },
  {
    key: "login",
    route: "/login",
    component: <LoginPage />,
  },
  { type: "divider", key: "divider-0" },
];

export default routes;
