import { Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import PageLayout from "pages/layout/page-layout";
import ProductDescription from "pages/user/product-pages/krema-protiv-bora/product-description";
import Reviews from "pages/user/reviews";
import { useDocumentTitle } from "hooks/use-document-title";
import { SERVER_URL_PREFIX } from "api";
import ProductDetails from "pages/user/components/product-details";
import { Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "api";
import { useSoftUIController, setOpenConfigurator } from "context";
import ScrollableProductList from "pages/user/product-pages/components/scrollable-product-list";
import SoftTypography from "components/SoftTypography";
import { addCartItem, updateProduct } from "context";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import config from "config/config";
import { useMetaTag } from "hooks/use-document-title";
import useGTMEvent from "hooks/use-gtm-event";
import ProductDetailsSkeleton from "pages/user/components/product-details/product-details-skeleton";

const KremaProtivBora = () => {
  useDocumentTitle("Krema protiv bora | Mama za pet – Prirodna Zeolitna Krema");
  useMetaTag(
    "description",
    "Krema sa zeolitom, eteričnim uljem smilja i čajevcem pruža dubinsku njegu, regeneraciju i borbu protiv bora. Isprobajte prirodnu snagu!"
  );
  useMetaTag(
    "keywords",
    "krema protiv bora, prirodna krema za lice, zeolit krema, eterično ulje smilja"
  );

  const [tabIndex, setTabIndex] = useState(0);
  const sendEventToGTM = useGTMEvent();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [controller, dispatch] = useSoftUIController();
  const { selectedCurrency, cartItems } = controller;
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const getProductsAddToCardData = (productName, productQuantity) => {
    const product = products.find((p) => p.name === productName);

    if (product) {
      const { id, name, imageLocation, quantity, unitPriceBam, unitPriceEur } = product;
      const price = selectedCurrency?.value === "bam" ? unitPriceBam : unitPriceEur;

      return {
        id: String(id),
        name: name,
        img: `${RESOURCES_SERVER_URL_PREFIX}${imageLocation}`,
        quantity: productQuantity,
        unitPrice: price,
        unitQuantity: quantity,
      };
    }

    return undefined;
  };

  const handleAddToCart = (product, eventSource) => {
    const currentProduct = cartItems.find((item) => item.id === product.id);
    get(
      `${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${currentProduct ? currentProduct.quantity + 1 : product.quantity
      }&currency=${selectedCurrency.value.toUpperCase()}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!currentProduct) {
          addCartItem(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
          });
          sendEventToGTM("add_to_cart", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            quantity: 1,
            page_source: "Krema protiv bora",
            event_source: eventSource,
          });
        } else {
          const newQuantity = currentProduct.quantity + product.quantity;
          updateProduct(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
            quantity: newQuantity,
          });
          sendEventToGTM("update_cart_quantity", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            new_quantity: newQuantity,
            page_source: "Krema protiv bora",
            event_source: eventSource,
          });
        }
      });
  };

  const handleAdd = (product, eventSource) => {
    setTimeout(handleConfiguratorOpen, 300);
    handleAddToCart(getProductsAddToCardData(product.name, product.quantity), eventSource);
  };

  const getSetsContainingProduct = (targetProduct, products) => {
    return products.filter((product) =>
      product.compositeProductItems?.some((item) => item.itemProduct?.id === targetProduct.id)
    );
  };

  const getCartItemQuantity = (productName) => {
    const product = cartItems.find((item) => item.name === productName);

    if (product) {
      return product.quantity;
    }

    return 0;
  };

  const getMaxCartQuantity = (productName) => {
    return config.appSettings.maxQuantityPerItem - getCartItemQuantity(productName);
  };

  return (
    <PageLayout sx={{ overflow: "hidden" }}>
      <Divider sx={{ mb: "0" }} />
      {products && products.length > 0 && !isLoadingProducts ? (
        <ProductDetails
          product={products.find((p) => p.id === 1)}
          onAddToCart={(product) => handleAdd(product, "add_to_cart_button")}
          productImages={[
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/gallery/krema-protiv-bora/krema-gallery-1.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/gallery/krema-protiv-bora/krema-gallery-2.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/gallery/krema-protiv-bora/krema-gallery-3.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/gallery/krema-protiv-bora/krema-gallery-4.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting/new/gallery-shooting-3.webp`,
          ]}
          descriptionContentArray={[
            "Receptura sa tradicijom preko dvije decenije prilagođena njezi suve, kombinovane i masne kože. ",
            "Sadrži zeolit, prirodni mineral koji prodire duboko u ćelije kože i čisti ih od zagađenja, toksina, teških metala i vraća koži zdrav izgled.",
            "Antibakterijska svojstva čajevca i bergamota ciljano djeluju na akne, ožiljke i crvenilo i  sve druge nepravilnosti.",
            "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod bubljica, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice.",
            "Krema se lako upija i ne ostavlja masni trag, što je čini savršenom za dnevnu i noćnu upotrebu.",
          ]}
          sets={getSetsContainingProduct(
            products.find((p) => p.name === "Krema protiv bora"),
            products
          )}
          maxQuantity={getMaxCartQuantity("Krema protiv bora")}
        />
      ) : (
        <ProductDetailsSkeleton />
      )}
      <Grid
        container
        width={{ xs: "100%", lg: "100%", xl: "100%" }}
        mt={{ xs: "3rem", lg: "0" }}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: "0rem",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          sx={{
            width: "100%",
            mb: "1rem",
            backgroundColor: "white !important",
            borderBottom: "1px solid #e0e0e0",
            borderTop: "1px solid #e0e0e0",
            borderRadius: "0",
            padding: "0",
            "& .MuiTab-root": {
              minHeight: "64px !important",
              color: "black",
              maxWidth: "220px !important",
              "&:hover": {
                color: "#6ac4bb !important",
              },
            },
            "& .Mui-selected": {
              color: "#6ac4bb !important",
              borderBottom: "3px solid #7eddd3",
              borderRadius: "0",
            },
            "& .MuiTabs-indicator": {
              display: "none",
              borderBottom: "1px solid black",
            },
          }}
        >
          <Tab label="O PROIZVODU" sx={{ fontSize: "14px" }} />
          <Tab label="SASTOJCI" sx={{ fontSize: "14px" }} />
          <Tab label="NAČIN UPOTREBE" sx={{ fontSize: "14px" }} />
        </Tabs>

        {tabIndex === 0 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Daje rezultate već nakon 7 dana!"
            textVariant="body2"
            textArray={[
              "Dubinsko čišćenje kože: Sadrži zeolit, prirodni mineral koji prodire duboko u ćelije kože i čisti ih od zagađenja, toksina i teških metala. Uklanja prašinu, hemikalije iz šminke i higijenskih proizvoda, sprječavajući nakupljanje u porama.",
              "Njega i regeneracija kože: Eterično ulje smilja, poznato kao \"zlatno ulje za lice\", pomaže kod akni, ožiljaka, dermatitisa i ekcema, regenerišući kožu i vraćajući joj mladolik izgled. Ulja poput eukaliptusa, ruže, bergamota i još sedam hranjivih ulja ciljano djeluju na sve nepravilnosti kože.",
              "Antibakterijsko djelovanje: Australijski čajevac uspješno uklanja akne, herpes i smiruje kožu sklonu infekcijama i upalama.",
              "Prevencija bora: Dubinsko čišćenje i hidratacija smanjuju umor kože, čineći je elastičnijom i manje sklonom nastanku bora. Potiče regeneraciju i osvježava umoran izgled lica.",
              "Višenamjenska krema – 3 u 1: Zamjenjuje dnevnu, noćnu kremu i kremu za područje oko očiju i dekolte. Krema se lako upija i ne ostavlja masni trag, što je čini savršenom za dnevnu i noćnu upotrebu.",
            ]}
          />
        )}
        {tabIndex === 1 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Ključni sastojci i njihove prednosti"
            textVariant="body2"
            textArray={[
              "Zeolit: Dubinski čisti ćelije kože od toksina, zagađenja i teških metala. Pomaže u uklanjanju zagađenja koja se talože u porama, smanjujući pojavu bora, bubuljica i proširenih pora. Hidrira ćelije kože, pomažeu regeneraciji i osvježavanju tena.",
              "Australijski čajevac: Snažno antibakterijsko djelovanje – uspješno uklanja akne, herpes i crvenilo. Pomaže u smirivanju kože sklonoj iritacijama i upalama.",
              "Eterično ulje smilja: Poznato kao \"zlatno ulje za lice\", smilje regeneriše kožu i smanjuje vidljivost akni, ožiljaka, dermatitisa i ekcema.Podstiče prirodni proces obnove kože, vraćajući joj mladolik izgled.",
              "Bergamot i eukaliptus: Efikasni u tretiranju kožnih infekcija i upala. Pomažu u detoksikaciji kože i vraćanju njene prirodne ravnoteže.",
              "Lavanda i ruža: Umiruju osjetljivu kožu, smanjuju crvenilo i njeguju suhu, zrelu i osjetljivu kožu. Pružaju osjećaj svježine i vitalnosti. Smanjuje bore i fine linije. Zahvaljujući hranjivim sastojcima bogatim antioksidansima i vitaminima, krema djeluje dubinski kako bi smanjila vidljivost bora i usporila proces starenja kože.",
            ]}
          />
        )}
        {tabIndex === 2 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Način upotrebe"
            textVariant="body2"
            textArray={[
              "Jedan proizvod, tri funkcije – Mamazapet krema mijenja dnevnu, noćnu i kremu za područje oko očiju i dekolte, čineći njegu kože jednostavnom i učinkovitom.",
              "Krema se nanosi na čisto i suho lice kako bi se postigli najbolji rezultati.",
              "Zbog bogate teksture, dovoljno je nanijeti malu količinu kreme u tankom sloju na cijelo lice, područje oko očiju i dekolte.  Lagano masiraj kremu kružnim pokretima dok se potpuno ne upije u kožu.",
              "Prvi način primjene: Ujutro kao dnevna krema za zaštitu i hidrataciju.",
              "Drugi način primjene: Naveče kao noćna krema za regeneraciju kože dok spavaš",
              "Treći način primjene: Kao podloga za šminku – pruža savršeno glatku bazu i spriječava isušivanje kože tokom dana.",
              "Saveti: Savjet: Za najbolje rezultate, koristi  kremu redovno i kombinuj je sa zeolitnom maskom i serumom za lice. Krema ima bogatu, a ne vodenastu teksturu i zato se nanosi u tankom sloju.",
            ]}
          />
        )}
      </Grid>

      <Divider sx={{ mb: "0", mt: "3rem" }} />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        pt="1rem"
        px={{ xs: "1rem", md: "0" }}
      >
        <SoftTypography variant="h4" sx={{ color: "#000" }}>
          Pogledajte i ostale proizvode koji bi vam se mogli svidjeti
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ mb: "0" }} />

      <ScrollableProductList
        products={products.filter((p) => p.name !== "Krema protiv bora")}
        selectedCurrency={selectedCurrency}
        handleAddToCart={(product) => handleAdd(product, "suggestions_add_to_cart_button")}
      />

      <SoftBox display="flex" justifyContent="center" mt="4rem" />

      <Reviews productIds={[1]} />
    </PageLayout>
  );
};

export default KremaProtivBora;
