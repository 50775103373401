import { jwtDecode } from 'jwt-decode';

const checkJwtValidity = (token) => {
  try {
    const decodedToken = jwtDecode(token);

    // Check for token expiration
    if (decodedToken.exp < Date.now() / 1000) {
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};

const getDecodedToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    return null;
  }
};

export { checkJwtValidity, getDecodedToken };