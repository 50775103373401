import { Divider } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import config from "config/config";
import { useMetaTag } from "hooks/use-document-title";
import { useDocumentTitle } from "hooks/use-document-title";
import PageLayout from "pages/layout/page-layout";

const TermsAndConditions = (props) => {
  useDocumentTitle("Uslovi Korišćenja | Mama za pet – Prirodni Proizvodi za Lice");
  useMetaTag(
    "description",
    "Uslovi korišćenja web stranice Mama za pet. Pročitajte uslove korišćenja pre korišćenja web stranice."
  );
  useMetaTag(
    "keywords",
    "mama za pet uslovi korišćenja, mama za pet pravila korišćenja, mama za pet pravila, mama za pet"
  );

  return (
    <PageLayout>
      <Divider sx={{ mt: "0" }} />
      <SoftBox display="flex" justifyContent="center" px="2rem">
        <SoftTypography
          sx={{ color: "black !important" }}
          variant="h4"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Uslovi Korišćenja
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ mb: "2rem" }} />
      <SoftBox mt="2rem" px="2rem" maxWidth="1000px" margin="0 auto">
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Dobrodošli na web stranicu mamazapet.com ("Web stranica"). Ovi Uslovi Korišćenja
          ("Uslovi") regulišu vaš pristup i korišćenje Web stranice. Korišćenjem naše Web stranice,
          saglasni ste s ovim Uslovima. Ako se ne slažete sa bilo kojim dijelom ovih Uslova, molimo
          vas da ne koristite našu Web stranicu.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Korišćenje Web Stranice
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Web stranica Mamazapet je namijenjena za vašu ličnu upotrebu i ne smije se koristiti u
          komercijalne svrhe bez našeg izričitog pristanka. Slažete se da ćete koristiti Web
          stranicu u skladu sa svim važećim zakonima i propisima.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Intelektualna Svojina
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Svi sadržaji na ovoj Web stranici, uključujući tekstove, slike, logotipe, grafike i
          softver, vlasništvo su Mamazapet-a ili njegovih pružalaca licenci i zaštićeni su zakonima
          o autorskim pravima i intelektualnoj svojini. Zabranjeno je kopiranje, distribucija ili
          modifikacija bilo kog dijela Web stranice bez našeg prethodnog pisanog odobrenja.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Ograničenje Odgovornosti
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Mamazapet ne garantuje da će Web stranica biti dostupna bez prekida ili grešaka.
          Korišćenje Web stranice vršite na sopstveni rizik. Mamazapet neće biti odgovoran za bilo
          kakvu štetu koja nastane kao rezultat vašeg korišćenja Web stranice ili oslanjanja na
          informacije koje ona pruža.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Promjene Uslova
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Mamazapet zadržava pravo da u bilo kom trenutku izmijeni ili ažurira ove Uslove. Sve
          promjene stupaju na snagu odmah nakon objavljivanja na ovoj stranici. Preporučujemo vam da
          redovno pregledate ove Uslove kako biste bili informisani o svim promjenama. Vaše dalje
          korišćenje Web stranice nakon izmjena podrazumijeva prihvatanje novih Uslova.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Vaše Obaveze
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Kao korisnik naše Web stranice, obavezujete se da ćete poštovati sve relevantne zakone i
          propise. Takođe, saglasni ste da nećete koristiti Web stranicu za nezakonite aktivnosti,
          širenje malicioznog softvera, ili povredu prava drugih korisnika.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Mjerodavno Pravo
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Ovi Uslovi regulišu se i tumače u skladu sa zakonima Bosne i Hercegovine, bez obzira na
          sukobe zakonskih odredbi. Svi sporovi koji nastanu iz ili u vezi sa ovim Uslovima biće
          riješeni pred nadležnim sudovima u Bosni i Hercegovini.
        </SoftTypography>

        <SoftTypography sx={{ color: "black !important" }} variant="h6" fontWeight="bold" mt="2rem">
          Kontaktirajte Nas
        </SoftTypography>
        <SoftTypography sx={{ color: "black !important" }} variant="body2" paragraph>
          Ako imate bilo kakva pitanja ili komentare u vezi sa ovim Uslovima, molimo vas da nas
          kontaktirate putem e-maila na {config.contact.infoEmail}.
        </SoftTypography>
      </SoftBox>
    </PageLayout>
  );
};

export default TermsAndConditions;
