/* eslint-disable react/prop-types */
import moment from "moment/moment";

import { Box } from "@mui/system";
import { Stack } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";

import IdCell from "pages/admin/orders/components/id-cell";
import PriceCell from "pages/admin/orders/components/price-cell";
import DefaultCell from "pages/admin/orders/components/date-cell";
import CountryCell from "pages/admin/orders/components/country-cell";
import CustomerCell from "pages/admin/orders/components/customer-cell";
import PaymentStatusCell from "pages/admin/orders/components/payment-status-cell";
import ShipmentStatusCell from "pages/admin/orders/components/shipment-status-cell";

const shipmentStatusMapping = new Map([
  ["ORDERED", "Naručeno"],
  ["READY_TO_SHIP", "Spremno za isporuku"],
  ["SHIPPED", "Isporučeno"],
  ["RETURNED", "Vraćeno"],
  ["DELIVERED", "Dostavljeno"],
  ["CANCELED", "Otkazano"]
]);

const paymentStatusMapping = new Map([
  ["PAID", "Plaćeno"],
  ["NOT_PAID", "Nije plaćeno"]
]);

const dataTableData = {
  columns: [
    {
      Header: "id",
      accessor: "id",
      Cell: ({ value }) => <IdCell id={value} />,
      disableSortBy: true,
    },
    {
      Header: "Datum",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={moment(new Date(value)).format("DD.MM.yyyy - HH:mm")} />,
      disableSortBy: true,
    },
    {
      Header: "Država",
      accessor: "country",
      Cell: ({ value: { name, image } }) => <CountryCell name={name} image={image} />,
      filter: (rows, columns, filterValue) =>
        filterValue.length === 0 ? rows : rows.filter(r =>
          filterValue.some(fv => r.values[columns[0]].name.toLowerCase().includes(fv.toLowerCase()))
        ),
      disableSortBy: true,
    },
    {
      Header: "Status narudžbe",
      accessor: "shipmentStatus",
      Cell: ({ value }) => {
        let status;

        if (value === "ORDERED") {
          status = <ShipmentStatusCell icon="add" color="dark" status="Naručeno" />;
        } else if (value === "READY_TO_SHIP") {
          status = <ShipmentStatusCell icon="upload" color="dark" status="Spremno za isporuku" />;
        } else if (value === "SHIPPED") {
          status = <ShipmentStatusCell icon="local_shipping" color="dark" status="Isporučeno" />;
        } else if (value === "RETURNED") {
          status = <ShipmentStatusCell icon="replay" color="warning" status="Vraćeno" />;
        } else if (value === "DELIVERED") {
          status = <ShipmentStatusCell icon="done" color="success" status="Dostavljeno" />;
        } else if (value === "CANCELED") {
          status = <ShipmentStatusCell icon="close" color="error" status="Otkazano" />;
        } else {
          status = <ShipmentStatusCell icon="close" color="error" status="Otkazano" />;
        }

        return status;
      },
      filter: (rows, columns, filterValue) =>
        filterValue.length === 0 ? rows : rows.filter(r =>
          filterValue.some(fv =>
            shipmentStatusMapping.get(r.values[columns[0]]).toLowerCase().includes(shipmentStatusMapping.get(fv).toLowerCase()))
        ),
      disableSortBy: true,
    },
    {
      Header: "Status plaćanja",
      accessor: "paymentStatus",
      Cell: ({ value }) => {
        let status;

        if (value === "PAID") {
          status = <PaymentStatusCell icon="done" color="success" status="Plaćeno" />;
        } else if (value === "NOT_PAID") {
          status = <PaymentStatusCell icon="close" color="error" status="Nije plaćeno" />;
        } else {
          status = <PaymentStatusCell icon="close" color="error" status="Nije plaćeno" />;
        }

        return status;
      },
      filter: (rows, columns, filterValue) =>
        filterValue.length === 0 ? rows : rows.filter(r =>
          filterValue.some(fv =>
            paymentStatusMapping.get(r.values[columns[0]]).toLowerCase() === (paymentStatusMapping.get(fv).toLowerCase()))
        ),
      disableSortBy: true,
    },
    {
      Header: "Kupac",
      accessor: "customer",
      Cell: ({ value: [name, data] }) => (
        <CustomerCell image={data.image} color={data.color || "dark"} name={<SoftBox sx={({ palette }) => ({
          // color: palette["black"].main,
        })}>{name}</SoftBox>} />
      ),
      filter: (rows, columns, filterValue) => rows.filter(r => r.values[columns[0]][0].toLowerCase().includes(filterValue.toLowerCase())),
      disableSortBy: true,
    },
    {
      Header: "Proizvodi",
      accessor: "products",
      Cell: ({ value }) => (
        value ?
          <Stack direction="column" spacing={1}>
            {value.map((product, i) =>
              <Stack key={i} direction="row" spacing="0.5rem">
                <Box display="flex" alignItems="center">
                  <SoftTypography container variant="h1" component="span" sx={{ fontSize: "0.75rem" }}>{`${Math.floor(product.quantity)} x`}</SoftTypography>
                </Box>
                <SoftBadge key={i} variant="contained" color="secondary" badgeContent={
                  <SoftBox sx={({ palette }) => ({
                    fontWeight: 500,
                    fontFamily: '"Open Sans","Helvetica","Arial",sans-serif'
                    // color: palette["black"].main,
                  })}>
                    {product.name}
                  </SoftBox>} />
              </Stack>)}
          </Stack> : null),
      disableSortBy: true,
    },
    {
      Header: "Cijena",
      accessor: "price",
      Cell: ({ value }) => <PriceCell value={value.text} preffix={value.preffix} />,
      disableSortBy: true,
    },
    {
      Header: "Opcije",
      accessor: "actions",
      disableSortBy: true,
    },
  ],

  rows: [
  ],
};

export default dataTableData;
