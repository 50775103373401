import PropTypes from "prop-types";

import { Divider, Stack } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";

const outOfStock = (
  <SoftBadge variant="contained" color="error" size="sm" badgeContent="Nedostupno" container />
);
const inStock = (
  <SoftBadge variant="contained" color="success" size="sm" badgeContent="Dostupno" container />
);

function ProductInfo({
  name = "Nepoznati proizvod",
  price = 0.0,
  currency = "BAM",
  secondPrice = 0.0,
  secondCurrency = "EUR",
  status = "NOT_AVAILABLE",
  description = [],
  sales = [],
}) {
  return (
    <SoftBox>
      <SoftBox mb={1}>
        <SoftTypography variant="h4" fontWeight="bold">
          {name}
        </SoftTypography>
      </SoftBox>
      {status === "AVAILABLE" ? inStock : outOfStock}
      <SoftBox mt={1}>
        <SoftTypography variant="caption" fontWeight="bold">
          Cijena
        </SoftTypography>
      </SoftBox>
      <SoftBox mb={1}>
        <SoftBox display="flex">
          <SoftTypography variant="h6">
            {price} {currency}
          </SoftTypography>
          <Divider
            orientation="vertical"
            sx={{ height: "1.5rem", mx: "0.7rem", backgroundColor: "#000" }}
          />
          <SoftTypography variant="h6">
            {secondPrice} {secondCurrency}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={1}>
        <SoftTypography variant="caption" fontWeight="bold">
          Akcije
        </SoftTypography>
      </SoftBox>
      <SoftBox mb={1} mt={0.5}>
        <Stack direction="column" spacing={1}>
          {sales && sales.length > 0 ? (
            sales.map((sale, i) => (
              <SoftBox key={i} display="flex" alignItems="center" gap="0.875rem">
                <SoftBadge
                  key={i}
                  variant="contained"
                  color="secondary"
                  size="sm"
                  badgeContent={`${sale.quantity}`}
                  container
                ></SoftBadge>
                <SoftBox display="flex">
                  <SoftTypography variant="h6">
                    {sale.price} {sale.currency}
                  </SoftTypography>
                  <Divider
                    orientation="vertical"
                    sx={{ height: "1.5rem", mx: "0.7rem", backgroundColor: "#000" }}
                  />
                  <SoftTypography variant="h6">
                    {sale.secondPrice} {sale.secondCurrency}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            ))
          ) : (
            <SoftTypography variant="body2">Akcije nisu definisane</SoftTypography>
          )}
        </Stack>
      </SoftBox>
      <SoftBox mt={3} mb={1} ml={0.5}>
        <SoftTypography variant="caption" fontWeight="bold">
          Opis proizvoda
        </SoftTypography>
      </SoftBox>
      <SoftBox component="ul" m={0} pl={4} mb={2}>
        {description.map((text, i) => (
          <SoftBox key={i} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
            <SoftTypography variant="body2" color="text" verticalAlign="middle">
              {text}
            </SoftTypography>
          </SoftBox>
        ))}
      </SoftBox>
    </SoftBox>
  );
}

ProductInfo.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  currency: PropTypes.string,
  secondPrice: PropTypes.number,
  secondCurrency: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.array,
  sales: PropTypes.array,
};

export default ProductInfo;
