import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Icon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PropTypes from 'prop-types';

const FAQSection = ({ faqData }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <SoftBox px={{ xs: "1rem", lg: "2rem" }}>
      {faqData.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            mb: "1rem",
            borderRadius: "0.5rem",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            sx={{
              backgroundColor: "#f8f8f8",
              borderRadius: "0.5rem",
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <SoftTypography variant="h6" sx={{ color: "black", fontWeight: "700" }}>{item.question}</SoftTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "#fff", borderRadius: "0.5rem" }}>
            <SoftTypography variant="body2" sx={{ fontSize: "14px" }}>{item.answer}</SoftTypography>
          </AccordionDetails>
        </Accordion>
      ))}
    </SoftBox>
  );
};

FAQSection.propTypes = {
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FAQSection;