import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import ConfiguratorRoot from "pages/user/configurator/ConfiguratorRoot";
import { ReactComponent as ShoppingCartOffIcon } from 'assets/icons/shopping_cart_off.svg';

import {
  useSoftUIController,
  setOpenConfigurator,
  updateProduct,
  removeCartItem
} from "context";
import ProductItem from "pages/user/configurator/product-item";
import { get, SERVER_URL_PREFIX } from "api";
import { useDocumentTitle } from "hooks/use-document-title";

function Configurator() {
  useDocumentTitle("Mama za pet - Korpa");

  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, cartItems, selectedCurrency } = controller;

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);

  const handleIncreaseQuantity = (productId) => {
    const product = cartItems.find(item => item.id === productId);
    get(`${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${product.quantity + 1}&currency=${selectedCurrency.value.toUpperCase()}`)
      .then(response => response.json())
      .then(data => {
        updateProduct(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice, quantity: product.quantity + 1 });
      })
  }

  const handleDecreaseQuantity = (productId) => {
    const product = cartItems.find(item => item.id === productId);
    get(`${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${product.quantity - 1}&currency=${selectedCurrency.value.toUpperCase()}`)
      .then(response => response.json())
      .then(data => {
        updateProduct(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice, quantity: product.quantity - 1 });
      })
  }

  const handleRemoveItem = (productId) => {
    removeCartItem(dispatch, productId);
  }

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox>
          <SoftTypography variant="h5">Korpa</SoftTypography>
          {/* <SoftTypography variant="body2" color="text">
            Poštarina za BIH je besplatna
          </SoftTypography> */}
        </SoftBox>
        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SoftBox>
      <Divider />
      <SoftBox pt={1.25} pb={3} px={3}>
        {
          cartItems && cartItems.length !== 0 ?
            <SoftBox display="flex" flexDirection="column" gap="3rem">
              {cartItems.map((item, i) => (
                <ProductItem
                  key={i}
                  id={String(item.id)}
                  name={item.name}
                  price={String(item.unitPrice)}
                  unitQuantity={String(item.unitQuantity)}
                  currency={selectedCurrency.value.toUpperCase()}
                  realPrice={item.realPrice}
                  calculatedPrice={item.calculatedPrice}
                  quantity={item.quantity}
                  img={item.img}
                  handleIncreaseQuantity={handleIncreaseQuantity}
                  handleDecreaseQuantity={handleDecreaseQuantity}
                  handleClose={handleRemoveItem}
                />
              ))}
            </SoftBox> :
            (<>
              <SoftBox display="flex" justifyContent="center" alignItems="center" mt={0}>
                <ShoppingCartOffIcon
                  width="7rem"
                  height="7rem"
                  fill="#ddd"
                />
              </SoftBox>
              <SoftTypography variant="body2" sx={{ textAlign: "center", mt: "1rem" }}>Trenutno nemate proizvoda u korpi</SoftTypography>
            </>
            )
        }
        {
          cartItems.length !== 0 && <Divider />
        }
        {cartItems.length !== 0 &&
          <SoftBox mt={3} mb={2}>
            <SoftBox mb={2}>
              <SoftButton
                component={Link}
                href="/checkout"
                color="info"
                variant="gradient"
                fullWidth
                disabled={cartItems.length === 0}
              >
                Završi kupovinu
              </SoftButton>
            </SoftBox>
          </SoftBox>
        }
      </SoftBox>
    </ConfiguratorRoot >
  );
}

export default Configurator;
