import React, { useState } from 'react';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import colors from 'assets/theme/base/colors';
import { Icon } from '@mui/material';

const ImageFilePicker = ({ productId, setImages }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadError, setUploadError] = useState(null);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const maxFileSize = 4 * 1024 * 1024; // 4 MB file size limit

    const validFiles = files.filter((file) => {
      if (file.size > maxFileSize) {
        setUploadError(`Fajl ${file.name} je premašila granicu od 4 MB.`);
        return false;
      }
      setUploadError(null);
      return true;
    })
      .filter((file) => selectedFiles.includes(file.name) === false);

    if (validFiles.length > 0) {
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...validFiles.map((file) => file.name),
      ]);

      setImages((images) => ({
        ...images,
        [productId]: [...(images[productId] || []), ...validFiles],
      }));
    }

    event.target.value = '';
  };

  const handleRemoveFile = (fileName) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileName));
    setImages(images => ({
      ...images,
      [productId]: images[productId].filter(file => file.name !== fileName)
    }));
  };

  return (
    <SoftBox display="flex" flexDirection="column" gap="0.5rem">
      <SoftBox>
        <SoftTypography variant="caption" fontWeight="bold">
          {
            selectedFiles.length > 0 && "Odabrane slike:"
          }
        </SoftTypography>
        {selectedFiles.length > 0 && (
          <SoftBox sx={{ display: "flex", flexWrap: "wrap", gap: "0.5rem", padding: 0 }}>
            {selectedFiles.map((fileName, index) => (
              <SoftBox
                key={index}
                sx={{
                  backgroundColor: '#f2f2f2',
                  padding: '2px 10px',
                  margin: '5px 0',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: "0.2rem"
                }}
                onClick={() => handleRemoveFile(fileName)}
              >
                <SoftTypography variant="caption">{fileName}</SoftTypography>
                <Icon sx={{ fontSize: "1.2rem !important" }}>close</Icon>
              </SoftBox>
            ))}
          </SoftBox>
        )}
      </SoftBox>
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id={`file-input-${productId}`}
      />
      <label htmlFor={`file-input-${productId}`} style={{ width: "fit-content" }}>
        <SoftBox
          component="span"
          sx={{
            padding: '0.5rem 1rem',
            cursor: 'pointer',
            borderRadius: '5px',
            display: 'inline-block',
            backgroundColor: colors.primary.main,
            transition: `all 150ms ease-in`,
            '&:hover': {
              transform: "scale(1.02)",
            }
          }}
        >
          <SoftTypography
            sx={{
              color: colors.white.main,
              fontSize: '0.75rem',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}>
            Dodaj slike
          </SoftTypography>
        </SoftBox>
      </label>
      {uploadError && <SoftTypography variant="caption" color="error">{uploadError}</SoftTypography>}
    </SoftBox>
  );
};

export default ImageFilePicker;
