import { Divider, Grid, Icon } from "@mui/material";
import PageLayout from "pages/layout/page-layout";
import Product from "pages/user/products/product";
import { addCartItem, updateProduct } from "context";
import { useSoftUIController } from "context";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { get, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import { useDocumentTitle } from "hooks/use-document-title";
import ProductSkeleton from "./product/skeleton";
import CoverText from "pages/user/components/cover-text";
import breakpoints from "assets/theme/base/breakpoints";
import FAQSection from "pages/user/home/components/faq-section";
import SocialFollowComponent from "pages/user/components/social-follow-component";
import GridBox from "pages/user/components/grid-box";
import config from "config/config";
import { useMetaTag } from "hooks/use-document-title";
import useGTMEvent from "hooks/use-gtm-event";

const Products = (props) => {
  useDocumentTitle("Prodavnica | Mama za pet – Prirodni Proizvodi za Lice");
  useMetaTag(
    "description",
    "Pregledajte našu ponudu prirodne kozmetike – krema za lice, zeolitna maska za lice i serum za lice. Idealno za sve tipove kože."
  );
  useMetaTag(
    "keywords",
    "prirodna kozmetika, krema za lice, zeolitna maska za lice, serum za lice, mama za pet, mama za pet prirodni proizvodi, mama za pet kozmetika, mama za pet proizvodi za lice"
  );

  const [controller, dispatch] = useSoftUIController();
  const { cartItems, selectedCurrency } = controller;
  const [products, setProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const sendEventToGTM = useGTMEvent();

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const handleAddToCart = (product) => {
    const currentProduct = cartItems.find((item) => item.id === product.id);
    get(
      `${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${
        currentProduct ? currentProduct.quantity + 1 : product.quantity
      }&currency=${selectedCurrency.value.toUpperCase()}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!currentProduct) {
          addCartItem(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
          });
          sendEventToGTM("add_to_cart", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            quantity: 1,
            page_source: "Prodavnica",
          });
        } else {
          const newQuantity = currentProduct.quantity + 1;
          updateProduct(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
            quantity: newQuantity,
          });
          sendEventToGTM("update_cart_quantity", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            new_quantity: newQuantity,
            page_source: "Prodavnica",
            button_type: "add_to_cart",
          });
        }
      });
  };

  const getCartItemQuantity = (productName) => {
    const product = cartItems.find((item) => item.name === productName);

    if (product) {
      return product.quantity;
    }

    return 0;
  };

  const isProductMaxQuantityPerItemReached = (productName) => {
    return getCartItemQuantity(productName) >= config.appSettings.maxQuantityPerItem;
  };

  return (
    <PageLayout>
      <Divider sx={{ m: 0 }} />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="24rem"
        justifyContent="space-between"
        flexDirection={{ xs: "column-reverse", lg: "row" }}
        px={{ xs: "0", lg: "3rem" }}
        sx={{
          backgroundImage: {
            xs: "none",
            lg: "linear-gradient(rgba(250, 250, 250, 0.5), rgba(250, 250, 250, 0.9))",
          },
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <SoftBox
          sx={{
            // background: {
            //   xs: "linear-gradient(rgba(250, 250, 250, 0.5), rgba(250, 250, 250, 0.9))",
            //   lg: "transparent",
            // },
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", lg: "flex-start" },
            py: { xs: "2rem", lg: "0" },
            // px: { xs: "1rem", lg: "0" }
          }}
        >
          <CoverText
            sx={{
              // backgroundColor: "rgba(128, 128, 128, 0.1)",
              // padding: { xs: "1rem", xxl: "2rem" },
              borderRadius: "1rem",
              maxWidth: { xs: "90%", lg: "70%", xxl: "700px" },
              textAlign: { xs: "center", lg: "left" },
              display: "flex",
              alignItems: { xs: "center", lg: "flex-start" },
            }}
            title={
              <SoftBox
                display="flex"
                flexDirection={{ xs: "column", xl: "column" }}
                justifyContent="center"
                alignItems={{ xs: "center", lg: "flex-start" }}
              >
                <SoftBox
                  component="img"
                  src={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/nature-leaf-icon.png`}
                  alt="List ikonica"
                  width="3.3rem"
                  pr="1rem"
                  sx={{ verticalAlign: "middle" }}
                  display={{ xs: "none", xl: "flex" }}
                />
                <SoftTypography
                  variant={innerWidth < breakpoints.values.md ? "h4" : "h3"}
                  sx={{ color: "#000", fontWeight: "600" }}
                >
                  Prirodni luksuz u svakom dodiru
                </SoftTypography>
              </SoftBox>
            }
            // title="Dobrodošli u svijet vrhunske kozmetike!"
            description={
              innerWidth < breakpoints.values.sm
                ? "Ovde ćeš pronaći sve što ti je potrebno za prirodnu njegu kože – od anti-age kreme koja efikasno smanjuje bore, zeolitne maske koja pilinguje i čisti kožu, do seruma za blistavo i hidrirano lice."
                : "Ovde ćeš pronaći sve što ti je potrebno za prirodnu njegu kože – od anti-age kreme koja efikasno smanjuje bore, zeolitne maske koja pilinguje i čisti kožu, do seruma za blistavo i hidrirano lice."
            }
            // hideDescription={innerWidth < breakpoints.values .lg ? true : false}
            titleVariant={"span"}
            descriptionVariant={innerWidth < breakpoints.values.md ? "body2" : "body2"}
          />
        </SoftBox>
        <SoftBox px={{ xs: "1rem", md: "0" }}>
          {/* <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/header-postolje-ruza.webp`} alt="Zaglavlje proizvodi na postolju" sx={{ maxHeight: "22rem", width: { xs: "100%" } }} /> */}
          <SoftBox
            component="img"
            src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/shop/header-postolje.webp`}
            alt="Zaglavlje proizvodi na postolju"
            sx={{ maxHeight: "22rem", width: { xs: "100%" } }}
          />
        </SoftBox>
      </SoftBox>
      <Divider sx={{ m: 0 }} />
      <SoftBox display="flex" justifyContent="center" py="2rem">
        <SoftTypography variant="h4">SVI PROIZVODI</SoftTypography>
      </SoftBox>
      <Grid
        container
        spacing={1}
        sx={{
          rowGap: "5rem",
        }}
      >
        {isLoadingProducts
          ? Array.from(new Array(6)).map((_, index) => <ProductSkeleton key={index} />)
          : products.map((product) => (
              <Product
                key={product.id}
                id={product.id.toString()}
                title={product.name}
                type={product.type}
                compositeProductItems={product.compositeProductItems}
                price={
                  selectedCurrency.value === "bam"
                    ? product.unitPriceBam.toString()
                    : product.unitPriceEur.toString()
                }
                currency={selectedCurrency}
                quantity={product.quantity}
                image={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`}
                onAddToCart={handleAddToCart}
                specialOffers={product.specialOffers}
                showBadge={
                  (product.specialOffers && product.specialOffers.length > 0) || product.id === 4
                }
                badgeText={product.id === 4 ? "NAJPRODAVANIJI" : "AKCIJA"}
                badgeIcon={product.id === 4 ? "new_releases" : "star"}
                badgeColor={product.id === 4 ? "#7eddd3" : undefined}
                isProductMaxCartQuantityPerItemReached={
                  !!isProductMaxQuantityPerItemReached(product.name)
                }
                priceBeforeSale={product.type === "COMPOSITE" ? 106 : undefined}
              />
            ))}
      </Grid>
      {/* Text divider */}
      <Divider sx={{ mt: "8rem" }} />
      {/* FAQ section */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" mt="2rem" pb="1rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="2rem"
            textAlign="center"
          >
            <SoftTypography variant="h3" sx={{ color: "#000" }}>
              Često postavljana pitanja
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>
      <Divider sx={{ width: "100%" }} />
      <FAQSection
        faqData={[
          {
            question: "Kako mogu naručiti proizvode?",
            answer:
              "Proizvode možete naručiti direktno preko naše online prodavnice. Dodajte željene proizvode u korpu i unesite podatke za dostavu. Nakon toga pregledajte i potvrdite narudžbu i u roku od nekoliko minuta ćete dobiti potvrdu putem e-pošte.",
          },
          {
            question: "Koji su načini plaćanja dostupni?",
            answer:
              "Trenutno je dostupno plaćanje pouzećem i dostava samo unutar Bosne i Hercegovine (kada se narudžba vrši putem web shop-a). U budućnosti ćemo dodati i druge načine plaćanja. Molimo vas da nas kontaktirate ako imate posebne zahtjeve.",
          },
          {
            question: "Za koliko mogu da očekujem paket na svojoj adresi?",
            answer:
              "Dostava traje između 2 i 5 radnih dana, u zavisnosti od vaše lokacije. Bićete obaviješteni putem e-pošte kada vaša narudžba bude poslata.",
          },
          {
            question: "Da li vršite međunarodnu dostavu?",
            answer:
              "Za narudžbe putem web shop-a, trenutno vršimo dostavu samo unutar Bosne i Hercegovine. Ako želite dostavu u inostranstvo, molimo vas da nas kontaktirate putem socijalnih mreža ili e-pošte na info@mamazapet.com.",
          },
          {
            question: "Kolika je cijena dostave unutar Bosne i Hercegovine?",
            answer: "Cijena dostave unutar Bosne i Hercegovine je 8 KM.",
          },
          {
            question: "Šta ako želim otkazati ili promijeniti narudžbu?",
            answer: `Ako želite otkazati ili promijeniti svoju narudžbu, molimo Vas da nas kontaktirate što prije putem e-pošte na ${config.contact.infoEmail}, pozivom na broj ${config.contact.telephone} ili putem socijalnih mreža. Rok za otkazivanje narudžbe je 12 sati od trenutka naručivanja. Nakon toga, narudžba će biti poslata i ne može se otkazati.`,
          },
          {
            question: "Kako mogu dobiti kupon/promotivni kod?",
            answer:
              "Kupon možete dobiti na sljedeći način: Nakon što izvršite kupovinu, 15 dana kasnije dobićete e-mail sa linkom do stranice na kojoj možete ostaviti recenziju proizvoda. Kada ostavite recenziju, naš admin tim će je pregledati i odobriti. Nakon odobrenja recenzije, biće vam poslat kupon na e-mail. Imajte na umu da kupon možete dobiti najviše jednom u 3 mjeseca. Ukoliko ne vidite e-mail sa linkom za kreiranje recenzije u navedenom roku, provjerite spam ili promotions foldere. Ako i dalje ne možete pronaći e-mail, molimo vas da nas kontaktirate putem e-pošte na info@mamazapet.com.",
          },
          {
            question: "Nakon koliko dana kupon/promotivni kod postaje nevažeći?",
            answer:
              "Kupon možete iskoristiti u roku od 14 dana od dana kada ga primite. Nakon isteka tog roka, kupon više neće biti važeći.",
          },
          {
            question: "Kako mogu iskoristiti kupon/promotivni kod za popust?",
            answer:
              'Tokom procesa kupovine, unesite promotivni kod u odgovarajuće polje prilikom popunjavanja podataka o dostavi. Nakon što unesete promotivni kod i pritisnete "Potvrdi", popust će automatski biti primijenjen na ukupan iznos narudžbe.',
          },
          {
            question: "Kako mogu stupiti u kontakt sa podrškom?",
            answer: `Možete me kontaktirati putem e-pošte na ${config.contact.infoEmail}, telefonom na broj ${config.contact.telephone} ili putem društvenih mreža (Facebook/Instagram/Tiktok).`,
          },
        ]}
      />

      <SocialFollowComponent sx={{ mt: "5rem" }} />
    </PageLayout>
  );
};

export default Products;
