import { Card, CardContent, CardMedia, Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import PageLayout from "pages/layout/page-layout";
import ProductDescription from "../about-products/product-description";
import ProductImage from "../about-products/product-image";
import SocialFollowComponent from "pages/user/components/social-follow-component";
import { useDocumentTitle } from "hooks/use-document-title";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import { useMetaTag } from "hooks/use-document-title";
import SoftTypography from "components/SoftTypography";
import Link from "@mui/material/Link";
import SoftButton from "components/SoftButton";

const About = () => {
  useDocumentTitle("O meni | Mama za pet – Prirodna Kozmetika za Lice");
  useMetaTag("description", "Upoznajte Nataliju Todorović, poznatiju kao Mama za pet, i saznajte kako je nastala krema protiv bora sa zeolitom i 12 biljnih eteričnih ulja.");
  useMetaTag("keywords", "mama za pet, mama za pet kozmetika, mama za pet krema protiv bora, mama za pet zeolit, mama za pet eterična ulja, mama za pet prirodna kozmetika, mama za pet krema za lice, natalija todorović, mama za pet o meni");

  const links = [
    {
      title: "Portal 24h je pisao o meni kao instagram senzaciji iz BiH",
      url: "https://www.24sata.hr/lifestyle/instagram-senzacija-iz-bih-savjetima-osvaja-svijet-a-sve-je-krenulo-kad-je-dobila-otkaz-843974",
      thumbnail: `${RESOURCES_SERVER_URL_PREFIX}/static/images/mediji/24-sata.webp`,
    },
    {
      title: "Gostovanje u Jutarnjem programu RTRS-a",
      url: "https://www.instagram.com/tv/CSOoJCYgklk/?hl=en",
      thumbnail: `${RESOURCES_SERVER_URL_PREFIX}/static/images/mediji/rtrs.png`,
    },
    {
      title: "Emisija „Centar dana“ na Atv-u",
      url: "https://www.instagram.com/tv/CTVMjfmAz2q/?hl=en",
      thumbnail: `${RESOURCES_SERVER_URL_PREFIX}/static/images/mediji/atv.png`,
    },
    {
      title: "A kako sam jedan životni neuspjeh preokrenula u svoju korist pričala sam za Suzy Time blog",
      url: "https://www.suzytime.com/mama-za-pet-natalija-todorovic/",
      thumbnail: `${RESOURCES_SERVER_URL_PREFIX}/static/images/mediji/suzytime.jpg`,
    },
  ];
  return (
    <PageLayout>
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{ mt: "0" }} />
          <SoftBox display="flex" justifyContent="space-between" px="2rem">
            <SoftBox></SoftBox>
            <SoftBox>
              {/* <SoftBox>Pomažem damama da ubrzaju kućne poslove, ali i da ne bacaju novac na kreme koje im samo skupljaju prašinu.</SoftBox> */}
              <SoftBox
                component="img"
                src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/potpis.png`}
                alt="Natalija Todorovic potpis"
                sx={{ height: "2.4rem", px: "0.4rem" }}
              />
            </SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
          <Divider sx={{ mb: "0" }} />
        </Grid>
      </Grid>
      <SoftTypography variant="h5" fontSize={{ xs: "22px", md: "24px" }} sx={{ color: "#000", textAlign: "center", pt: "3rem", pb: "1rem", px: "1rem" }}>
        Zdravo! Ja sam Natalija Todorović poznatija kao Mama za pet.
      </SoftTypography>
      <SoftTypography variant="h5" fontSize={{ xs: "16px", sm: "18px", md: "18px" }} sx={{ color: "#000", textAlign: "center", pb: "3rem", fontWeight: "400" }}>
        Na četiri društvene mreže, na kojima sam trenutno aktivna, imam više od <b>700 000</b> pratilaca.
      </SoftTypography>
      <Divider sx={{ m: 0 }} />
      <Grid container >
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/about/natalija-about-1.webp`} />
        <Grid item lg={6} md={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: { xs: "2rem", md: "3rem" } }}>
          <ProductDescription
            title="Kako sam od diplomirane novinarke zaposlene na televiziji postala Mama za pet?"
            titleSx={{ pb: "2rem", fontSize: "24px !important", fontWeight: "600" }}
            textArray={[
              "Deset godina radila sam na televiziji gdje sam uređivala i vodila dvije autorske emisije. Nakon otkaza na porodiljskom odsustvu, okrenula sam se društvenim mrežama i svakodnevnoj edukaciji iz oblasti digitalnog marketinga.",
              "Kao pravi novinar, odmah po završetku studija, počela sam u jedan rokovnik zapisivati sve savjete i trikove radnih kolegica, mame, bake, komšinica... Recepte i savjete za vođenje domaćinstva i ekološko čišćenje doma dijelila sam prvo preko Vibera, a onda sam shvatila da sve želim objediniti na jednom mjestu. I tako je nastala Mamazapet.",
              "Nakon što sam, prije 10 godina, iz kuće izbacila svu tešku hemiju – krenula sam da tragam za prirodnim proizvodima za njegu lica. U ruke sam dobila kremu, masku i serum od zeolita, biljnih eteričnih i hladno cijeđenih ulja. To je, sjećam se, bila prva kozmetika od koje mi nisu suzile oči i izlazile nove bubuljice. Lice mi se očistilo, a nakon nekoliko mjeseci tamne fleke od drugog poroda su potpuno nestale. Recepture napravljenje samo za moje lice, spontano sam odlučila da predstavim i na društvenim mrežama. Do sada su mi dame i muškarci poslali preko 1000 recenzija koje možete pročitati u sačuvanim pričama na Instagramu."
            ]}
          />
        </Grid>
      </Grid>
      <Grid container display="flex" flexDirection={{ xs: "column-reverse", lg: "row" }}>
        <Grid item lg={6} md={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: { xs: "2rem", md: "3rem" } }}>
          <ProductDescription
            title="Kako izgleda moja rutina korištenja kozmetike Mamazapet?"
            titleSx={{ pb: "2rem", fontSize: "24px !important", fontWeight: "600" }}
            textArray={[
              "Kada sam prestala da koristim tešku hemiju u kući, prvo sam krenula od svog lica. Evo kako izgleda moja rutina:",
              " • Ujutru prvo očistim lice sa Mamazapet tonikom od kamilice (uskoro ću ga predstaviti i vama).",
              " • Na lice ujutru obavezno nanosim kombinaciju 12 biljnih eteričnih i hladno cijeđenih ulja koja su već pomiješana u serumu Mamazapet. Sa par kapljica izmasiram lice i istog trenutka osjetim kako je moja koža dubinsi hidrirana. Ne ostavlja masan trag, a uz to i predivno miriše.",
              " • Nakon seruma, lice toniram sa kremicom protiv bora. Zeolit u kremici sadrži do 290 minerala koji čiste ćelije i u nih odmah donosi vodu. Tu je i kombinacija 12 eteričnih ulja i zato moje lice uvijek izgleda svježe, elastično i bez crvenila.",
              " • Tokom dana ne nanosim puder niti dodatne proizvode. Ne trebaju mi jer je lice toliko lijepo hidrirano i ne sjaji se.",
              " • Pred spavanje nanosim ili kremicu ili serum, a obavezan sastojak moje noćne rutine jednom sedmično je i zeolitna maska. Ona čisti mrtve ćelije sa površine kože, uklanja mitisere i bubuljice, a ujutru me probudi tonirana i zaglađena koža, kao da sam upravo nanijela puder.",
              "Napiši u recenzijama koja je tvoja rutina sa Mamazapet proizvodima. I ne zaboravi da sa mnom podijeliš neki savjet ili trik za brzo obavljanje kućnih poslova."
            ]}
          />
        </Grid>
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/about/natalija-about-2.webp`} />
      </Grid>
      <Divider sx={{ m: 0 }} />
      <SoftTypography variant="h5" fontSize={{ xs: "22px", md: "24px" }} sx={{ color: "#000", textAlign: "center", pb: "1rem", px: "1rem" }} pt={{ xs: "2rem", md: "6rem" }}>
        Nisam samo zanimljiva damama koje me prate na Instagramu
      </SoftTypography>
      <SoftBox display="flex" justifyContent="center" sx={{ width: "100%" }}>
        <SoftTypography variant="h5" fontSize={{ xs: "16px", sm: "18px", md: "18px" }} sx={{ color: "#000", textAlign: "center", pb: "3rem", fontWeight: "400", maxWidth: "1000px" }}>
          Vodeće televizije u BiH, medicinski časopisi, ali i internet magazini pitali su me kako sam došla na ideju da pokrenem Mamazapet i zašto toliko vjerujem u proizvode sa mojim potpisom.
        </SoftTypography>
      </SoftBox>
      <Grid container spacing={3} px={{ xs: "1rem", md: "2rem" }}>
        {links.map((link, index) => (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <Card
              variant="outlined"
              component="a"
              href={link.url}
              target="_blank"
              rel="noreferrer"
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                textDecoration: "none", // Removes underline on hover
                color: "inherit", // Keeps the text color consistent
                "&:hover": {
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)", // Subtle shadow
                },
              }}
            >
              <CardMedia
                component="img"
                image={link.thumbnail}
                alt={link.title}
                sx={{ height: "160px", objectFit: "cover" }}
              />
              <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                <SoftTypography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                  {link.title}
                </SoftTypography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ m: 0, mt: "3rem" }} />
      <SoftTypography variant="h5" fontSize={{ xs: "20px", sm: "22px", md: "30px" }} sx={{ color: "#000", textAlign: "center", pt: "4rem" }}>
        S ljubavlju, vaša Mama za pet!
      </SoftTypography>
      <SoftBox display="flex" justifyContent="center" sx={{ mt: "2rem" }}>
        <SoftBox
          component="img"
          src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/potpis.png`}
          alt="Natalija Todorovic potpis"
          sx={{ height: "2.4rem", px: "0.4rem" }}
        />
      </SoftBox>
      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" pt={{ xs: "2rem", lg: "3rem" }}>
        <SoftBox></SoftBox>
        <SoftBox>
          <SocialFollowComponent onlyIcons />
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

    </PageLayout >
  )
}

export default About;