import * as Yup from "yup";

import checkout from "pages/admin/orders/components/create-new-order-form/schemas/form";

const {
  formField: { product, quantity },
} = checkout;

const validations = [
  Yup.object().shape({
    [product.name]: Yup.object().required(product.errorMsg),
    [quantity.name]: Yup.string().required(quantity.errorMsg),
  }),
];

export default validations;
