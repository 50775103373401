import { CircularProgress, Divider, Grid } from "@mui/material";
import { post, SERVER_URL_PREFIX } from "api";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useSoftUIController } from "context";
import PageLayout from "pages/layout/page-layout";
import CheckoutDetails from "pages/user/checkout-preview/checkout-details";
import CheckoutProducts from "pages/user/checkout/checkout-products";
import selectData from "pages/user/checkout/checkout-form/data/selectData";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetCartItems } from "context";
import { useDocumentTitle } from "hooks/use-document-title";
import { useMetaTag } from "hooks/use-document-title";

const CheckoutPreview = (props) => {
  useDocumentTitle("Pregled narudžbe | Mama za pet – Sigurna Kupovina");
  useMetaTag("description", "Pregledajte Mama za pet proizvode koje ste dodali u korpu i potvrdite narudžbu. Dostava na kućnu adresu u roku od 2-3 radna dana.");
  useMetaTag("keywords", "mama za pet pregled narudžbe, mama za pet korpa, mama za pet proizvodi u korpi, mama za pet potvrda narudžbe, mama za pet sigurna kupovina");

  const [controller, dispatch] = useSoftUIController();
  const navigate = useNavigate();
  const { cartItems, checkoutValues, coupon } = controller;
  const [createOrderLoading, setCreateOrderLoading] = useState(false);
  const [createOrderError, setCreateOrderError] = useState(false);

  const handleSubmitGlobal = (values, actions) => {
    createOrder(values);
  }

  const createOrder = () => {
    const requestData = {
      customer: {
        fullName: checkoutValues.fullName,
        phoneNumber: checkoutValues.phoneNumber,
        streetAndNumber: checkoutValues.streetAndNumber,
        postalCode: checkoutValues.postalCode,
        country: {
          name: checkoutValues.country,
          label: selectData.country.find(c => c.value === checkoutValues.country).label.props.name
        },
        email: checkoutValues.email,
        city: checkoutValues.city
      },
      notes: checkoutValues.notes,
      products: cartItems,
      coupon: coupon?.code
    }

    setCreateOrderLoading(true);
    post(`${SERVER_URL_PREFIX}/api/web/order`, requestData)
      .then(response => response.text())
      .then(data => {
        resetCartItems(dispatch);
        navigate(`/thankyou/${data}`)
      })
      .catch(response => {
        console.error(response);
        setCreateOrderError(true);
      })
      .finally(() => {
        setCreateOrderLoading(false);
      })
  }

  return (
    <PageLayout>
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{ mt: "0" }} />
          <SoftBox display="flex" justifyContent="space-between" px="2rem">
            <SoftBox></SoftBox>
            <SoftBox>PREGLED NARUDŽBE</SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
          <Divider sx={{ mb: "0.1rem" }} />
          {cartItems.length !== 0 ?
            <Grid container>
              <Grid item xs={12} lg={6} sx={{ py: { sx: "0", lg: "2rem" }, display: "flex", justifyContent: "end" }}>
                <SoftBox display="flex" justifyContent="end" width="100%">
                  <CheckoutDetails />
                </SoftBox>
              </Grid>
              <Grid item xs={12} display={{ xs: 'block', lg: 'none' }}>
                <Divider sx={{ mb: "3rem" }} />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ py: { xs: "0", lg: "4.5rem" }, px: { xs: "1rem", lg: "2rem" } }} display="flex" flexDirection="column" gap="1rem">
                <SoftBox display="flex" >
                  <CheckoutProducts readOnly couponPreview />
                </SoftBox>
                <Grid container>
                  <Grid item xs={12} xl={9} xxl={8} maxWidth={{ xs: "auto", lg: "600px !important" }}>
                    <SoftButton
                      variant="gradient"
                      color="success"
                      type="submit"
                      onClick={handleSubmitGlobal}
                      fullWidth
                      disabled={createOrderLoading}
                    >
                      {(createOrderLoading ? <CircularProgress size={20} color="inherit" /> : "")}
                      &nbsp; Potvrdi narudžbu
                    </SoftButton>
                    {createOrderError && !createOrderLoading && <SoftTypography variant="caption" component="div" color="error" textAlign="center" margin="1rem auto" width="100%">Došlo je do greške prilikom kreiranja narudžbe, molimo pokušajte ponovo</SoftTypography>}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            :
            <SoftBox display="flex" justifyContent="center" mt="2rem">
              <SoftTypography variant="caption">Trenutno nemate proizvoda u korpi</SoftTypography>
            </SoftBox>
          }
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default CheckoutPreview;