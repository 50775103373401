import React from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import Sales from "pages/user/components/sales";
import Price from "pages/user/components/price";

const ProductPriceDetails = ({ product, selectedCurrency }) => {
  if (!product) return null;
  const { unitPriceBam, unitPriceEur, specialOffers } = product;

  const unitPrice = selectedCurrency === "bam" ? unitPriceBam : unitPriceEur;
  const currency = selectedCurrency === "bam" ? "BAM" : "EUR";

  return (
    <SoftBox display="flex" flexDirection="column" gap="1rem">
      <Price originalPrice={unitPrice} currency={currency} beforeSalePrice={product.type === 'COMPOSITE' ? "106" : undefined} />
      <SoftBox display="flex" gap="1rem" flexDirection={{ xs: "column", sm: "row" }}>
        {specialOffers &&
          specialOffers.map((offer, i) => (
            <Sales
              key={i}
              quantity={Number(offer.quantity)}
              salesPrice={selectedCurrency === "bam" ? offer.priceBAM : offer.priceEUR}
              originalPrice={unitPrice * offer.quantity}
              currency={currency}
            />
          ))}
      </SoftBox>
    </SoftBox>
  );
};

ProductPriceDetails.propTypes = {
  product: PropTypes.shape({
    unitPriceBam: PropTypes.number.isRequired,
    unitPriceEur: PropTypes.number.isRequired,
    specialOffers: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.string.isRequired,
        priceBAM: PropTypes.number.isRequired,
        priceEUR: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  selectedCurrency: PropTypes.string.isRequired,
};

export default ProductPriceDetails;
