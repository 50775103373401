import * as Yup from "yup";

import checkout from "pages/admin/orders/components/create-new-order-form/schemas/formGlobal";

const {
  formField: { fullName, phoneNumber, streetAndNumber, city, postalCode, country, email },
} = checkout;

const validations = [
  Yup.object().shape({
    [fullName.name]: Yup.string()
      .min(2, "Ime i prezime mora imati najmanje 2 karaktera")
      .max(50, "Ime i prezime ne može biti duže od 50 karaktera")
      .required(fullName.errorMsg),
    [phoneNumber.name]: Yup.string()
      .matches(/^(?:\+387|00387|0)?(6[1-9])(\s?\d\s?){6,7}$/, "Broj telefona mora odgovarati brojevima telefona podržanim u Bosni i Hercegovini")
      .required(phoneNumber.errorMsg),
    [streetAndNumber.name]: Yup.string()
      .min(5, "Ulica i broj moraju imati najmanje 5 karaktera")
      .max(100, "Ulica i broj ne mogu biti duži od 100 karaktera")
      .required(streetAndNumber.errorMsg),
    [city.name]: Yup.string()
      .min(2, "Grad mora imati najmanje 2 karaktera")
      .max(50, "Grad ne može biti duži od 50 karaktera")
      .required(city.errorMsg),
    [postalCode.name]: Yup.string()
      .matches(/^\d{5}$/, "Poštanski broj mora sadržavati tačno 5 cifara")
      .required(postalCode.errorMsg),
    // [country.name]: Yup.string()
    //   .min(2, "Country must be at least 2 characters")
    //   .max(50, "Country can't be longer than 50 characters")
    //   .required(country.errorMsg),
    [email.name]: Yup.string()
      .email("Neispravan format email adrese")
      .required(email.errorMsg),
  }),
];

export default validations;
