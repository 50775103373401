import checkout from "pages/admin/orders/components/create-new-order-form/schemas/form";

const {
  formField: {
    product,
    quantity,
  },
} = checkout;

const initialValues = {
  [product.name]: "",
  [quantity.name]: ""
};

export default initialValues;
