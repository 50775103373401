import { CardMedia, Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";
import ProductDescription from "pages/user/about-products/product-description";
import ProductImage from "pages/user/about-products/product-image";
import brand from "assets/images/logos/mini-logo-transparent-green.png";
import { useDocumentTitle } from "hooks/use-document-title";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

const AboutProducts = () => {
  useDocumentTitle("Mama za pet - O Proizvodima");

  return (
    <PageLayout sx={{ overflow: "hidden" }}>
      {/* Video i potpis */}
      <Grid container>
        <Grid container sx={{ minHeight: "35rem", maxHeight: "35rem" }}>
          <Grid item md={12} sx={{ minHeight: "35rem", maxHeight: "35rem" }} >
            <SoftBox sx={{ overflow: "hidden", minHeight: "35rem", maxHeight: "35rem", position: "relative" }}>
              <CardMedia
                component="video"
                autoPlay
                loop
                muted
                src={`${RESOURCES_SERVER_URL_PREFIX}/static/videos/video-o-proizvodima-1.mp4`}
                sx={{
                  borderRadius: "0",
                  p: 0,
                  m: 0,
                  height: "100%",
                  width: "100%",
                  objectFit: "cover",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }} />
            </SoftBox>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Divider sx={{ mt: "0" }} />
          <SoftBox display="flex" justifyContent="space-between" px="2rem">
            <SoftBox></SoftBox>
            <SoftBox display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" py="2rem">
              <SoftBox component="img" src={brand} alt="Mama za pet brand" sx={{ height: "4rem", mb: "1rem" }} />
              <SoftBox
                component="img"
                src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/potpis.png`}
                alt="Natalija Todorovic potpis"
                sx={{ height: "2.4rem", px: "0.4rem" }}
              />
            </SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
          <Divider sx={{ mb: "0" }} />
        </Grid>
      </Grid>

      {/* Krema i divider */}
      <Grid container id="about-krema-protiv-bora">
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/voda-krema-protiv-bora.webp`} />
        <Grid item lg={6} md={12} sx={{ display: "flex", alignItems: "center", px: "3rem" }}>
          <ProductDescription
            title="Krema protiv bora"
            textArray={[
              "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice.",
              "Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot(takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
              "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice."
            ]}
          />
        </Grid>
      </Grid>

      {/* Maska */}
      <Grid container id="about-zeolitna-maska-za-lice">
        <Grid item lg={6} md={12} sx={{ display: "flex", alignItems: "center", px: "3rem" }}>
          <ProductDescription
            title="Zeolitna maska za lice"
            textArray={[
              "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice. Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot (takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti."
            ]}
          />
        </Grid>
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/voda-zeolitna-maska-za-lice.webp`} />
      </Grid>

      {/* Etericna ulja */}
      <Grid container id="about-etericna-ulja">
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/etericna-ulja.webp`} />
        <Grid item lg={6} md={12} sx={{ display: "flex", alignItems: "center", px: "3rem" }}>
          <ProductDescription
            title="Eterična ulja"
            textArray={[
              "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice. Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot(takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
            ]}
          />
        </Grid>
      </Grid>

      {/* Zeolit */}
      <Grid container id="about-zeolit">
        <Grid item lg={6} md={12} sx={{ display: "flex", alignItems: "center", px: "3rem" }}>
          <ProductDescription
            title="Zeolit"
            textArray={[
              "Nakon erupcija vulkana koja su počele prije više od 20 miliona godina nastala je vulkanska prašina. Vjetrom nošena, na zemlji je formirala zeolit – jedan od rijetkih minerala u prirodi sa negativnim ionskim nabojem. To znači da on ima moć da preventivno djeluje na brojna oboljenja jer iz organizma eliminiše živu, toksine, viruse, alergene i brojne druge štetne supstance.",
              "To znači da svakodnevnim nanošenjem Kreme protiv bora Mama za pet uklanjaš zagađenja, toksine i teške metale. Lice ne izgleda umorno već zdravo i njegovano. Takva koža manje je sklona nastanku bora.",
              "Piling ne podrazumjeva da se koža treba zacrveniti tako što će sitne čestice oštetiti kožu. Zeolit u kremi uklanja mrtve ćelije sa površine kože bez da to osjetiš i tako joj omogućava da ponovo prodiše. Zato ćeš primjetiti da ti je lice tonirano i zaglađeno.",
              "Višak sebuma dovodi do zapušenih pora. Tako nastaju bubuljice koje niko ne voli da vidi na svom licu. Tu na scenu stupa zeolit koji upija višak masnoće, a lice čisti od svih nečistoća. I tako svaki dan."
            ]}
          />
        </Grid>
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/zeolite-stock.webp`} />

      </Grid>
      <Divider sx={{ mt: "0" }} />

      {/* Tekst i logo divider */}
      <SoftBox display="flex" justifyContent="space-between" px="0" py="0" sx={{ mt: "3rem" }}>
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <SoftBox component="img" src={brand} alt="Mama za pet brand logo" sx={{ height: "4rem", mb: "1rem" }} />
            <SoftTypography variant="h3">Zaboravi na skupe anti-age i detoks tretmane lica</SoftTypography>
            <SoftTypography variant="body">Sve što ti treba spakovano je u Kremi protiv bora Mama za pet sa zeolitom</SoftTypography></SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

    </PageLayout >
  )
}

export default AboutProducts;