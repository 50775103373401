import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ActionCell({ productId = -1, onClick }) {
  return (
    <SoftBox display="flex" alignItems="center" disabled={true} onClick={onClick}>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Ukloni stavku" placement="top">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

ActionCell.propTypes = {
  productId: PropTypes.number,
  onClick: PropTypes.func,
};

export default ActionCell;
