import { useEffect, useState } from "react";

import Swal from "sweetalert2";
import { Formik } from "formik";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import DataTable from "pages/admin/orders/components/order-products-data-table";
import FormField from "pages/admin/orders/components/form-field";
import ProductCell from "pages/admin/orders/components/product-cell";
import DefaultCell from "pages/admin/orders/components/default-cell";
import SelectBox from "pages/admin/orders/components/create-new-order-form/select-box";
import ActionCell from "pages/admin/orders/components/create-new-order-form/action-cell";

import form from "pages/admin/orders/components/create-new-order-form/schemas/form";
import selectData from "pages/admin/orders/components/create-new-order-form/data/selectData";
import formGlobal from "pages/admin/orders/components/create-new-order-form/schemas/formGlobal";
import validations from "pages/admin/orders/components/create-new-order-form/schemas/validations";
import dataTableData from "pages/admin/orders/components/create-new-order-form/data/dataTableData";
import initialValues from "pages/admin/orders/components/create-new-order-form/schemas/initialValues";
import validationsGlobal from "pages/admin/orders/components/create-new-order-form/schemas/validationsGlobal";
import initialValuesGlobal from "pages/admin/orders/components/create-new-order-form/schemas/initialValuesGlobal";
import { CircularProgress, Divider, Icon } from "@mui/material";
import { get, post, put, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import { useSoftUIController } from "context";
import { formatPrice } from "utils/formatPrice";

function getSteps() {
  return ["Podaci o kupcu", "Izbor proizvoda"];
}

function CreateNewOrderForm({ handleModalClose, editMode, orderId }) {
  const [firstStepValues, setFirstStepValues] = useState(initialValuesGlobal);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;
  const { formId, formField } = form;
  const { formId: formIdGlobal, formField: formFieldGlobal } = formGlobal;
  const { fullName, phoneNumber, streetAndNumber, city, postalCode, country, email, notes } =
    formFieldGlobal;
  const { product, quantity } = formField;
  const [products, setProducts] = useState([]);
  const [productsSelect, setProductsSelect] = useState([]);
  const [countrySelect, setCountrySelect] = useState(selectData.country);
  const [chosenProducts, setChosenProducts] = useState([]);
  const [productsTableData, setProductsTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  // CALCULATIONS
  const [controller] = useSoftUIController();
  const { selectedCurrency } = controller;
  const [basePrice, setBasePrice] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [countries, setCountries] = useState([]);

  const [currentChosenProducts, setCurrentChosenProducts] = useState([]);

  useEffect(() => {
    get(`${SERVER_URL_PREFIX}/api/web/countries`)
      .then((response) => response.json())
      .then((data) => {
        setCountries(data);
      });
  }, []);

  useEffect(() => {
    setBasePrice(calculateBasePrice());
  }, [currentChosenProducts]);

  useEffect(() => {
    setSubTotal(calculateSubTotal());
  }, [currentChosenProducts]);

  useEffect(() => {
    setTotal(calculateTotal());
  }, [subTotal]);

  const calculateSubTotal = () => {
    return currentChosenProducts.reduce(
      (accumulator, currentValue) => accumulator + currentValue.specialOfferPrice,
      0
    );
  };

  const calculateTotal = () => {
    return subTotal + getCorrespondingShippingPrice();
  };

  const calculateBasePrice = () => {
    return currentChosenProducts.reduce(
      (accumulator, currentValue) => accumulator + currentValue.unitPrice * currentValue.quantity,
      0
    );
  };

  const getCorrespondingShippingPrice = () => {
    if (!firstStepValues.country || countries.length === 0) return "";
    const country = countries.find(
      (c) => c.shortName.toLowerCase() === firstStepValues.country.value.toLowerCase()
    );
    return country.shippingPrice;
  };
  // END OF CALCULATIONS

  useEffect(() => {
    get(`${SERVER_URL_PREFIX}/api/admin/countries`)
      .then((response) => response.json())
      .then((data) =>
        setCountrySelect(data.map((c) => ({ value: c.shortName.toLowerCase(), label: c.label })))
      );
  }, []);

  useEffect(() => {
    get(`${SERVER_URL_PREFIX}/api/admin/products`)
      .then((response) => response.json())
      .then((data) => setProducts(data));
  }, []);

  useEffect(() => {
    if (editMode) {
      get(`${SERVER_URL_PREFIX}/api/admin/order/${orderId}`)
        .then((response) => response.json())
        .then((data) => {
          setFirstStepValues({
            city: data.customer.city,
            country: { value: data.customer.country.name, label: data.customer.country.label },
            email: data.customer.email,
            fullName: data.customer.fullName,
            phoneNumber: data.customer.phoneNumber,
            postalCode: data.customer.postalCode,
            streetAndNumber: data.customer.streetAndNumber,
            notes: data.notes,
          });
          setChosenProducts(
            data.products.map((p) => ({
              product: {
                value: p.id,
                label: p.name,
              },
              quantity: p.quantity,
            }))
          );
        });
    }
  }, []);

  useEffect(() => {
    setProductsSelect(
      products
        .map((p) => ({ value: p.id, label: p.name }))
        .filter((p) => !chosenProducts.find((cp) => cp.product.value === p.value))
    );
  }, [products, chosenProducts]);

  const handleNext = (handleSubmit, values) => {
    handleSubmit();
    setFirstStepValues(values);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleSubmitInner = (values, actions) => {
    setChosenProducts((prevProducts) => [...prevProducts, values]);
    actions.resetForm();
  };

  const deleteItemFromOrders = (productId) => {
    setChosenProducts((prevProducts) => prevProducts.filter((p) => p.product.value !== productId));
  };

  useEffect(() => {
    if (products.length > 0) {
      setCurrentChosenProducts(
        chosenProducts.map((p) => {
          const productInfo = products.find((pr) => pr.id === p.product.value);
          let quantity = parseInt(p.quantity);
          let sum = 0;
          const countryValue = firstStepValues.country.value.toLowerCase();
          const currency = countryValue === "bih" ? "BAM" : "EUR";
          const unitPriceField = countryValue === "bih" ? "unitPriceBam" : "unitPriceEur";
          const salePriceField = countryValue === "bih" ? "priceBAM" : "priceEUR";
          const specialOffers = productInfo.specialOffers || [];

          // Sort special offers by quantity in descending order
          specialOffers.sort((a, b) => b.quantity - a.quantity);

          // Dynamic programming array for the minimum cost
          const dp = new Array(quantity + 1).fill(Infinity);
          dp[0] = 0; // Base case: 0 cost for 0 items

          for (let i = 1; i <= quantity; i++) {
            // Option 1: Buy one item at unit price
            dp[i] = dp[i - 1] + productInfo[unitPriceField];

            // Option 2: Use special offers if applicable
            for (const offer of specialOffers) {
              if (i >= offer.quantity) {
                dp[i] = Math.min(dp[i], dp[i - offer.quantity] + offer[salePriceField]);
              }
            }
          }
          // The total minimum cost for the given quantity
          sum = dp[quantity];
          return {
            productId: productInfo.id,
            productName: productInfo.name,
            quantity: p.quantity,
            unitPrice: currency === "BAM" ? productInfo.unitPriceBam : productInfo.unitPriceEur,
            specialOfferPrice: sum ? parseFloat(sum.toFixed(2)) : 0,
            currency: currency,
            productImageLocation: productInfo.imageLocation,
          };
        })
      );
    }
  }, [products, chosenProducts, firstStepValues]);

  useEffect(() => {
    if (products.length > 0) {
      setProductsTableData(
        currentChosenProducts.map((p) => {
          return {
            product: (
              <ProductCell
                image={`${RESOURCES_SERVER_URL_PREFIX}${p.productImageLocation}`}
                name={p.productName}
              />
            ),
            quantity: <DefaultCell>{`${parseInt(p.quantity)}`}</DefaultCell>,
            price: <DefaultCell>{formatPrice(p.specialOfferPrice, p.currency)}</DefaultCell>,
            action: <ActionCell onClick={() => deleteItemFromOrders(p.productId)} />,
          };
        })
      );
    }
  }, [currentChosenProducts]);

  const createOrder = (e) => {
    const requestData = {
      customer: {
        fullName: firstStepValues.fullName,
        phoneNumber: firstStepValues.phoneNumber,
        streetAndNumber: firstStepValues.streetAndNumber,
        postalCode: firstStepValues.postalCode,
        country: {
          name: firstStepValues.country.value,
          label: firstStepValues.country.label,
        },
        email: firstStepValues.email,
        city: firstStepValues.city,
      },
      notes: firstStepValues.notes,
      products: chosenProducts.map((cp) => ({ id: cp.product.value, quantity: cp.quantity })),
    };

    setLoading(true);
    setTimeout(() => {
      post(`${SERVER_URL_PREFIX}/api/admin/order`, requestData)
        .then((response) => {
          setLoading(false);
          handleModalClose(e, false, true, false, false);
        })
        .catch((err) => {
          setLoading(false);
          handleModalClose(e, false, true, false, true);
        });
    }, [1000]);
  };

  const updateOrder = (e) => {
    const requestData = {
      customer: {
        fullName: firstStepValues.fullName,
        phoneNumber: firstStepValues.phoneNumber,
        streetAndNumber: firstStepValues.streetAndNumber,
        postalCode: firstStepValues.postalCode,
        country: {
          name: firstStepValues.country.value,
          label: firstStepValues.country.label,
        },
        email: firstStepValues.email,
        city: firstStepValues.city,
      },
      notes: firstStepValues.notes,
      products: chosenProducts.map((cp) => ({ id: cp.product.value, quantity: cp.quantity })),
    };

    setLoading(true);
    setTimeout(() => {
      put(`${SERVER_URL_PREFIX}/api/admin/order/${orderId}`, requestData)
        .then((response) => response.text())
        .then((data) => {
          if (data) {
            setLoading(false);
            handleModalClose(e, false, false, true, false);
          }
        })
        .catch((err) => {
          setLoading(false);
          handleModalClose(e, false, false, true, true);
        });
    }, [1000]);
  };

  const declineOrder = (e) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-info",
        cancelButton: "button button-error",
      },
    });

    newSwal
      .fire({
        title: "Otkaži izmjene?",
        text: "Želite li otkazati izmjene na narudžbi?",
        showCancelButton: true,
        confirmButtonText: "Otkaži",
        cancelButtonText: "Nazad",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          handleModalClose(e, false, false, false, false);
          // newSwal.fire("Deleted!", "Your file has been deleted.", "success");
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          // newSwal.fire("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });
  };

  const handleSubmitGlobal = (values, actions) => {
    // console.log('GLOBAL FORM - values', values);
    // console.log('GLOBAL FORM - actions', actions);
  };

  return (
    <Card id="basic-info" sx={{ width: "100%", height: "100%" }}>
      <SoftBox p={3}>
        <SoftTypography variant="h5" component="span">
          Kreiranje narudžbe
        </SoftTypography>
        {activeStep === 0 && (
          <SoftTypography variant="h6" component="span">
            {" "}
            - Podaci o kupcu
          </SoftTypography>
        )}
        {activeStep === 1 && (
          <SoftTypography variant="h6" component="span">
            {" "}
            - Izbor proizvoda
          </SoftTypography>
        )}
      </SoftBox>
      <SoftBox
        component="div"
        pb={3}
        px={3}
        width="100%"
        height="100%"
        sx={{ overflowY: "scroll" }}
      >
        {activeStep === 0 && (
          <Formik
            validationSchema={validationsGlobal[0]}
            onSubmit={(values, { setSubmitting, errors }) => {
              handleNext(() => setSubmitting(false), values);
            }}
            initialValues={{
              ...firstStepValues,
              ...(editMode ? {} : { country: countrySelect.find((c) => c.value === "bih") }),
            }}
            key={JSON.stringify(firstStepValues)}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xs={12} sm={8}>
                    <FormField
                      type={fullName.type}
                      label={fullName.label}
                      name={fullName.name}
                      placeholder={fullName.placeholder}
                      value={values.fullName}
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.fullName && touched.fullName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      type={phoneNumber.type}
                      label={phoneNumber.label}
                      name={phoneNumber.name}
                      placeholder={phoneNumber.placeholder}
                      value={values.phoneNumber}
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.phoneNumber && touched.phoneNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      type={streetAndNumber.type}
                      label={streetAndNumber.label}
                      name={streetAndNumber.name}
                      placeholder={streetAndNumber.placeholder}
                      value={values.streetAndNumber}
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.streetAndNumber && touched.streetAndNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      type={city.type}
                      label={city.label}
                      name={city.name}
                      placeholder={city.placeholder}
                      value={values.city}
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.city && touched.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      type={postalCode.type}
                      label={postalCode.label}
                      name={postalCode.name}
                      placeholder={postalCode.placeholder}
                      value={values.postalCode}
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.postalCode && touched.postalCode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} pb={{ xs: "1rem" }}>
                    <SelectBox
                      caption={country.label}
                      field={country}
                      value={values.country}
                      options={countrySelect}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      errorMessage={country.errorMsg}
                      validationFailed={
                        Object.keys(touched).some((k) => k.includes("react-select-")) &&
                        !values.country
                      }
                      touched={touched}
                      isDisabled
                      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                      sx={{ mt: "-10px" }}
                    />
                    <SoftTypography component="div" variant="caption" sx={{ fontStyle: "italic" }}>
                      Narudžbe se trenutno primaju samo iz <br /> Bosne i Hercegovine.
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      type={email.type}
                      label={email.label}
                      name={email.name}
                      placeholder={email.placeholder}
                      value={values.email}
                      onChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors.email && touched.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      multiline
                      rows={5}
                      label={notes.label}
                      name={notes.name}
                      placeholder={notes.placeholder}
                      value={values.notes}
                      onChange={handleChange}
                      handleBlur={handleBlur}
                    />
                  </Grid>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={1}
                    width="100%"
                    justifyContent="flex-end"
                    mb={2}
                    mt={4}
                  >
                    <Grid item>
                      <SoftButton variant="outlined" color="error" onClick={(e) => declineOrder(e)}>
                        Otkaži
                      </SoftButton>
                    </Grid>
                    <Grid item>
                      <SoftButton
                        variant="gradient"
                        color="success"
                        type="submit"
                        disabled={
                          // Check if there are validation errors or if any required field is empty
                          Object.keys(errors).length > 0 ||
                          Object.entries(values).some(
                            ([key, value]) =>
                              key !== "notes" &&
                              (value === null ||
                                value === undefined ||
                                value === "" ||
                                (Array.isArray(value) && value.length === 0))
                          )
                        }
                      >
                        Dalje
                      </SoftButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        )}
        {activeStep === 1 && (
          <Grid container rowSpacing={3} columnSpacing={1}>
            <Grid item xs={12} sm={12}>
              <SoftBox mt={2} mb={6}>
                <Formik
                  onSubmit={handleSubmitInner}
                  initialValues={initialValues}
                  validationSchema={validations[0]}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isSubmitting,
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    return (
                      <Grid container columnSpacing={2} display="flex" alignItems="flex-end" my={4}>
                        <Grid item xs={12} sm={6}>
                          <SelectBox
                            caption={product.label}
                            field={product}
                            value={values.product}
                            options={productsSelect}
                            setFieldValue={setFieldValue}
                            handleBlur={handleBlur}
                            errorMessage={product.errorMsg}
                            validationFailed={
                              Object.keys(touched).some((k) => k.includes("react-select-")) &&
                              !values.product
                            }
                            touched={touched}
                            isDisabled={chosenProducts.length === products.length}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <FormField
                            inputProps={{ min: 1 }}
                            type={quantity.type}
                            label={quantity.label}
                            name={quantity.name}
                            placeholder={quantity.placeholder}
                            value={values.quantity}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.quantity && touched.quantity}
                            disabled={chosenProducts.length === products.length}
                          />
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{ mb: "0.38rem" }}>
                          <SoftButton
                            variant="gradient"
                            color="success"
                            type="submit"
                            onClick={(e) => handleSubmit(e)}
                            disabled={
                              !values.product ||
                              !values.quantity ||
                              chosenProducts.length === products.length ||
                              loading
                            }
                          >
                            Dodaj
                          </SoftButton>
                        </Grid>
                      </Grid>
                    );
                  }}
                </Formik>
                <DataTable
                  table={{ ...dataTableData, rows: productsTableData }}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  loading={loading}
                />
                {currentChosenProducts.length > 0 && (
                  <>
                    <Divider sx={{}} />
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt="1.5rem"
                    >
                      <SoftBox>
                        <SoftTypography variant="body2" sx={{ color: "#000", fontWeight: "bold" }}>
                          Iznos narudžbe
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" gap="0.5rem">
                        {basePrice > subTotal && (
                          <SoftTypography
                            sx={({ typography }) => ({
                              fontSize: typography.size.md,
                              fontWeight: typography.fontWeightRegular,
                              color: "#000",
                              fontStyle: "italic",
                              textDecoration: "line-through",
                            })}
                          >
                            {formatPrice(basePrice, selectedCurrency.label)}
                          </SoftTypography>
                        )}
                        <SoftTypography
                          sx={({ typography }) => ({
                            fontSize: typography.size.md,
                            fontWeight: typography.fontWeightBold,
                            color: "#000",
                          })}
                        >
                          &nbsp;&nbsp;{formatPrice(subTotal, selectedCurrency.label)}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt="1.5rem"
                    >
                      <SoftBox>
                        <SoftTypography
                          sx={({ typography }) => ({
                            fontSize: typography.size.sm,
                            fontWeight: typography.fontWeightRegular,
                          })}
                        >
                          Dostava
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex">
                        <SoftTypography
                          sx={({ typography }) => ({
                            fontSize: typography.size.sm,
                            fontWeight: typography.fontWeightBold,
                            color: "#98999e",
                          })}
                        >
                          &nbsp;&nbsp;
                          {formatPrice(getCorrespondingShippingPrice(), selectedCurrency.label)}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <Divider sx={{ mt: "1.5rem" }} />
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt="1.5rem"
                      pb="1rem"
                    >
                      <SoftBox>
                        <SoftTypography variant="body2" sx={{ color: "#000", fontWeight: "bold" }}>
                          Ukupno
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox display="flex" gap="0.5rem">
                        <SoftTypography
                          sx={({ typography }) => ({
                            fontSize: typography.size.md,
                            fontWeight: typography.fontWeightBold,
                            color: "#000",
                          })}
                        >
                          &nbsp;&nbsp;{formatPrice(Number(total), selectedCurrency.label)}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </>
                )}
              </SoftBox>
            </Grid>

            <Grid
              container
              rowSpacing={3}
              columnSpacing={1}
              width="100%"
              mb={2}
              justifyContent="space-between"
            >
              <Grid item>
                <SoftButton variant="contained" onClick={handleBack} disabled={loading}>
                  <Icon>chevron_left</Icon>&nbsp;Nazad
                </SoftButton>
              </Grid>
              <Grid item>
                <SoftButton
                  variant="outlined"
                  color="error"
                  onClick={declineOrder}
                  disabled={loading}
                  sx={{ marginRight: "0.5rem" }}
                >
                  Otkaži
                </SoftButton>
                {!editMode ? (
                  <SoftButton
                    variant="gradient"
                    color="success"
                    onClick={(e) => createOrder(e)}
                    disabled={!chosenProducts || chosenProducts.length === 0 || loading}
                  >
                    {loading ? <CircularProgress color="white" size="1rem" /> : "Kreiraj"}
                  </SoftButton>
                ) : (
                  <SoftButton
                    variant="gradient"
                    color="success"
                    disabled={!chosenProducts || chosenProducts.length === 0 || loading}
                    onClick={(e) => updateOrder(e)}
                  >
                    {loading ? <CircularProgress color="white" size="1rem" /> : "Ažuriraj"}
                  </SoftButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </SoftBox>
    </Card>
  );
}

CreateNewOrderForm.propTypes = {
  handleModalClose: PropTypes.func,
  editMode: PropTypes.bool,
  orderId: PropTypes.number,
};

export default CreateNewOrderForm;
