/* eslint-disable react/prop-types */

import { Divider, Stack } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";

import ProductCell from "pages/admin/products/components/product-cell";

const outOfStock = (
  <SoftBadge variant="contained" color="error" size="sm" badgeContent="Nedostupno" container />
);
const inStock = (
  <SoftBadge variant="contained" color="success" size="sm" badgeContent="Dostupno" container />
);

const productsTableData = {
  columns: [
    {
      Header: "Proizvod",
      accessor: "product",
      width: "30%",
      Cell: ({ value: [name, data] }) => (
        <ProductCell image={data.image} name={name} checkable={false} />
      ),
    },
    {
      Header: "Veličina",
      accessor: "size",
      Cell: ({ value }) => <SoftTypography variant="caption">{value}</SoftTypography>
    },
    {
      Header: "Jedinična cijena",
      accessor: "unitPrice",
      Cell: ({ value }) => value && value.length >= 2 ?
        <SoftBox display="flex">
          <SoftTypography variant="caption">{value[0].price} {value[0].currency}</SoftTypography>
          <Divider orientation="vertical" sx={{ height: "0.875rem", mx: "0.7rem", backgroundColor: "#000" }} />
          <SoftTypography variant="caption">{value[1].price} {value[1].currency}</SoftTypography>
        </SoftBox> : 'Nije definisana',
    },
    {
      Header: "Akcija",
      accessor: "sales",
      Cell: ({ value }) => (
        value && value.length > 0 ?
          <Stack direction="column" spacing={1}>
            {value.map((sale, i) =>
              <SoftBox key={i} display="flex" alignItems="center" gap="0.875rem">
                <SoftBadge key={i} variant="contained" color="secondary" size="xs" badgeContent={`${sale.quantity}`} container></SoftBadge>
                <SoftBox display="flex">
                  <SoftTypography variant="caption">{sale.price} {sale.currency}</SoftTypography>
                  <Divider orientation="vertical" sx={{ height: "0.875rem", mx: "0.7rem", backgroundColor: "#000" }} />
                  <SoftTypography variant="caption">{sale.secondPrice} {sale.secondCurrency}</SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
          </Stack> : "Akcija nije definisana"),
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => (value === "AVAILABLE" ? inStock : outOfStock),
    },
    { Header: "Opcije", accessor: "action", width: "8%" },
  ],

  rows: [],
};

export default productsTableData;
