import React from "react";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { formatPrice } from "utils/formatPrice";

const Price = ({ originalPrice, beforeSalePrice, currency = "BAM" }) => {
  return (
    <SoftTypography
      sx={({ typography }) => ({
        fontSize: typography.size.lg,
        fontWeight: typography.fontWeightMedium,
        color: "#000",
      })}
    >
      {beforeSalePrice && (
        <SoftTypography
          component="span"
          sx={({ typography }) => ({
            fontSize: typography.size.sm,
            fontWeight: typography.fontWeightRegular,
            color: "#000",
            textDecoration: "line-through",
            marginRight: "0.5rem",
          })}
        >
          {formatPrice(Number(beforeSalePrice), currency)}
        </SoftTypography>
      )}
      {formatPrice(Number(originalPrice), currency)}
    </SoftTypography>
  );
};

Price.propTypes = {
  originalPrice: PropTypes.number,
  currency: PropTypes.string,
};

export default Price;
