import { RESOURCES_SERVER_URL_PREFIX } from "api";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import React from "react";
import PropTypes from "prop-types";

const GridBox = ({
  imageUrl = `${RESOURCES_SERVER_URL_PREFIX}/static/icons/nature-leaf-icon.png`,
  alt = "Default image",
  title = "Default Title",
  description = "Default description text",
  hideImage,
  sx,
}) => {
  return (
    <SoftBox sx={{ background: "transparent", ...sx }} width="90%">
      <SoftBox>
        <SoftBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="1rem"
          justifyContent="center"
          mb={2}
        >
          {!hideImage && <SoftBox component="img" src={imageUrl} alt={alt} width="2.5rem" />}
          <SoftTypography variant="h5" fontWeight="bold" sx={{ color: "#000" }}>
            {title}
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="center">
          <SoftTypography
            variant="body2"
            textAlign="center"
            sx={{ fontSize: "14px", color: "#000" }}
          >
            {description}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

GridBox.propTypes = {
  imageUrl: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sx: PropTypes.object,
  hideImage: PropTypes.bool,
};

export default GridBox;
