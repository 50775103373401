import Card from "@mui/material/Card";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useSoftUIController } from "context";
import { logout } from "context";

import CreditCard from "icons/CreditCard";
import { useNavigate } from "react-router-dom";

function UserDropdownItems() {
  const [, dispatch] = useSoftUIController();
  const navigate = useNavigate();

  const sidenavItems = [
    { icon: <CreditCard />, label: "Odjavi se", onClick: () => { logout(dispatch); navigate("/login") } },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, onClick }, key) => {
    const itemKey = `item-${key}`;

    return (
      <SoftBox key={itemKey} component="li" pt={key === 0 ? 0 : 1} sx={{ width: '200px' }} onClick={onClick}>
        <SoftTypography
          component="a"
          variant="button"
          fontWeight="regular"
          color="text"
          sx={({
            borders: { borderRadius },
            palette: { light },
            transitions,
          }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),
            cursor: 'pointer',
            pl: 2,
            lineHeight: 3,

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <SoftBox mr={1.5} display="flex">
            {icon}
          </SoftBox>
          {label}
        </SoftTypography>
      </SoftBox>
    );
  });

  return (
    <Card
      sx={{
        borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
        boxShadow: 'none'
      }}
    >
      <SoftBox
        component="ul"
        display="flex"
        flexDirection="column"
        m={0}
        sx={{ listStyle: "none", backgroundColor: "transparent" }}
      >
        {renderSidenavItems}
      </SoftBox>
    </Card>
  );
}

export default UserDropdownItems;
