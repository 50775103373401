import PropTypes from "prop-types";

import Link from "@mui/material/Link";

import typography from "assets/theme/base/typography";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX } from "api";

function Footer({
  company = { href: "https://www.creative-tim.com/", name: "Creative Tim" },
  links = [
    { href: `${FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX}/dashboard`, name: "Kontrolna tabla" },
    { href: `${FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX}/orders`, name: "Narudžbe" },
    { href: `${FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX}/products`, name: "Proizvodi" },
    { href: `${FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX}/reviews`, name: "Recenzije" },
  ],
}) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <SoftBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </SoftTypography>
        </Link>
      </SoftBox>
    ));

  return (
    <SoftBox display="flex" flexDirection="column" sx={{ backgroundColor: "#fafafa" }} pb={3}>
      <SoftBox
        width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        pb={1}
      >
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
          minHeight="5rem"
        >
          <SoftTypography fontSize={size.sm}>
            &copy; {new Date().getFullYear()}, Mama za pet - Sva prava zadržana
          </SoftTypography>
        </SoftBox>
        <SoftBox
          component="ul"
          sx={({ breakpoints }) => ({
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            listStyle: "none",
            mt: 3,
            mb: 0,
            p: 0,

            [breakpoints.up("lg")]: {
              mt: 0,
            },
          })}
        >
          {renderLinks()}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
