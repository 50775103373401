import { Grid, Skeleton } from "@mui/material";
import SoftBox from "components/SoftBox";

const ProductDetailsSkeleton = ({ imagePosition = "left" }) => {
  return (
    <Grid container>
      {imagePosition === "left" && (
        <Grid item lg={6} xs={12}>
          <Skeleton variant="rectangular" width="100%" height={800} />
        </Grid>
      )}

      <Grid
        item
        lg={6}
        xs={12}
        sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        px={{ xs: "1rem", lg: "3rem" }}
      >
        {/* Product Description Skeleton */}
        <Skeleton variant="text" width="60%" height={80} sx={{ mb: "1rem" }} />
        <Skeleton variant="text" width="80%" height={40} sx={{ mb: "0.5rem" }} />
        <Skeleton variant="text" width="90%" height={40} sx={{ mb: "0.5rem" }} />
        <Skeleton variant="text" width="80%" height={40} sx={{ mb: "0.5rem" }} />
        <Skeleton variant="text" width="90%" height={40} sx={{ mb: "0.5rem" }} />

        {/* Product Price Details Skeleton */}
        <Skeleton variant="rectangular" width="50%" height={40} sx={{ mb: "1rem" }} />

        {/* Quantity and Add to Cart Skeleton */}
        <SoftBox display="flex" alignItems="center" mt="1rem" gap="0.5rem">
          <Skeleton variant="rectangular" width={120} height={40} />
          <Skeleton variant="rectangular" width={160} height={40} />
        </SoftBox>

        {/* Product Sets Skeleton */}
        <SoftBox mt="1rem">
          <Skeleton variant="text" width="40%" height={30} sx={{ mb: "0.5rem" }} />
          <Skeleton variant="text" width="80%" height={20} sx={{ mb: "0.5rem" }} />
        </SoftBox>
        <SoftBox mt="1rem">
          <Skeleton variant="text" width="40%" height={30} sx={{ mb: "0.5rem" }} />
          <Skeleton variant="text" width="80%" height={20} sx={{ mb: "0.5rem" }} />
        </SoftBox>
      </Grid>

      {imagePosition === "right" && (
        <Grid item lg={6} xs={12}>
          <Skeleton variant="rectangular" width="100%" height={300} />
        </Grid>
      )}
    </Grid>
  );
};

export default ProductDetailsSkeleton;
