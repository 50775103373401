import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";

const SelectBox = ({
  caption,
  options,
  field,
  value,
  setFieldValue,
  handleBlur,
  errorMessage,
  validationFailed,
  touched,
  isDisabled,
  sx
}) => {
  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
      sx={sx}
    >
      <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <SoftTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {caption}
        </SoftTypography>
      </SoftBox>
      <SoftSelect
        placeholder={field.placeholder}
        options={options}
        label={field.label}
        name={field.name}
        value={value}
        onChange={(option) => setFieldValue(field.name, option)}
        onBlur={handleBlur}
        isDisabled={isDisabled}
      />
      <SoftBox mt={0.75}>
        <SoftTypography component="div" variant="caption" color="error" sx={{ position: "absolute" }}>
          {validationFailed ? errorMessage : null}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

SelectBox.propTypes = {
  caption: "",
  options: [],
  field: {},
  value: "",
  errorMessage: "",
  setFieldValue: () => { },
  handleBlur: () => { },
  validationFailed: false,
  isDisabled: false,
  sx: {}
}

SelectBox.propTypes = {
  caption: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  validationFailed: PropTypes.bool,
  touched: PropTypes.object,
  isDisabled: PropTypes.bool,
  sx: PropTypes.object
};

export default SelectBox;