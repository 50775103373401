export const shipmentStatusSelectData = [
  { value: "ORDERED", label: "Naručeno" },
  { value: "READY_TO_SHIP", label: "Spremno za isporuku" },
  { value: "SHIPPED", label: "Isporučeno" },
  { value: "RETURNED", label: "Vraćeno" },
  { value: "DELIVERED", label: "Dostavljeno" },
  { value: "CANCELED", label: "Otkazano" },
]

export const paymentStatusSelectData = [
  { value: "NOT_PAID", label: "Neplaćeno" },
  { value: "PAID", label: "Plaćeno" },
]