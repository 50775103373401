
import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";

function ShipmentStatusCell({ icon, color, status }) {
  const selectColor = (status) => {
    switch (status) {
      case "Naručeno": return "secondary";
      case "Spremno za isporuku": return "#344767";
      case "Isporučeno": return "#379188";
      case "Otkazano": return "error";
      default: "light"
    }
  }

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBadge
        variant="contained"
        color="secondary"
        badgeContent={
          <SoftBox display="flex" alignItems="center" gap="0.3rem" sx={({ palette }) => ({
            color: palette[selectColor(status)] ? palette[selectColor(status)].main : selectColor(status),
            fontWeight: "bold",
          })}>
            <Icon>{icon}</Icon>
            {status}
          </SoftBox>}
        container
        size="xs"
      />
    </SoftBox>
  );
}

ShipmentStatusCell.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default ShipmentStatusCell;
