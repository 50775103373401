import { useCallback, useEffect, useState } from "react";
import { Divider, Grid, CircularProgress, Box, Icon } from "@mui/material";

import breakpoints from "assets/theme/base/breakpoints";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "pages/layout/dashboard-layout";
import GradientLineChart from "pages/admin/dashboard/components/charts/line-charts/gradient-line-chart";
import DefaultStatisticsCard from "pages/admin/dashboard/components/statistic-cards/default-statistics-card";

import HorizontalBarChart from "pages/admin/dashboard/components/charts/bar-charts/horizontal-bar-chart";
import { get } from "api";
import { SERVER_URL_PREFIX } from "api";
import moment from "moment";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import { FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX } from "api";

const Dashboard = () => {
  const { values } = breakpoints;
  const [refreshToken, setRefreshToken] = useState(0); // State to trigger refresh

  // Function to trigger a refresh
  const refreshData = () => {
    setRefreshToken((prev) => prev + 1);
  };

  // Helper hook for fetching data
  const useFetch = (url, transformData, dependencies = []) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      setLoading(true);
      setError(null);
      get(url)
        .then((response) => response.json())
        .then((data) => setData(transformData ? transformData(data) : data))
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    }, [url, transformData, ...dependencies]);

    return { data, loading, error };
  };

  // Transform function to adapt new backend format for charts
  const transformComparisonData = useCallback((response) => {
    const labels = response.map((item) => moment(item.date).format("Do MMM YYYY"));
    const currentValues = response.map((item) => item.currentValue);
    const previousValues = response.map((item) => item.previousValue);

    return {
      labels,
      datasets: [
        { label: "Trenutni period", color: "success", data: currentValues },
        { label: "Prethodni period", color: "secondary", data: previousValues },
      ],
    };
  }, []);

  // Fetch data for each card and graph
  const unprocessedOrders = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/unprocessed-orders?numberOfDays=7`,
    null,
    [refreshToken]
  );
  const revenueBruto = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/revenue-bruto?numberOfDays=30`,
    null,
    [refreshToken]
  );
  const numberOfOrders = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/number-of-orders?numberOfDays=30`,
    null,
    [refreshToken]
  );
  const numberOfSoldProducts = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/number-of-sold-products?numberOfDays=30`,
    null,
    [refreshToken]
  );
  const revenueBrutoComparison = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/revenue-bruto-comparison?numberOfDays=30`,
    transformComparisonData,
    [refreshToken]
  );
  const revenueBrutoComparisonYearly = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/revenue-bruto-comparison?numberOfDays=365`,
    transformComparisonData,
    [refreshToken]
  );
  const numberOfOrdersComparison = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/number-of-orders-comparison?numberOfDays=30`,
    transformComparisonData,
    [refreshToken]
  );
  const numberOfSoldProductsComparison = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/number-of-sold-products-comparison?numberOfDays=30`,
    transformComparisonData,
    [refreshToken]
  );
  const numberOfSoldProductsBarChart = useFetch(
    `${SERVER_URL_PREFIX}/api/web/dashboard/number-of-sold-products-by-product?numberOfDays=30`,
    null,
    [refreshToken]
  );

  const formatRevenueBruto = (revenue) => {
    const formatter = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${formatter.format(revenue)} KM`;
  };

  const renderChartWithLoading = (loading, data, ChartComponent, chartProps) => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }
    if (data) {
      return <ChartComponent {...chartProps} />;
    }
    return <SoftTypography color="error">Failed to load data</SoftTypography>;
  };

  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" px="0.5rem">
            <SoftTypography variant="h4">
              Kontrolna tabla
            </SoftTypography>
            <SoftButton variant={"outlined"} color="dark" onClick={refreshData}>
              <Icon>refresh</Icon>&nbsp;Osvježi
            </SoftButton>
          </SoftBox>
          <Divider sx={{ width: "100%", mb: 0 }} />
        </Grid>

        {/* Statistics Cards */}
        <Grid item xs={12}>
          <SoftBox p={1}>
            <SoftTypography
              variant={window.innerWidth < values.sm ? "h3" : "h2"}
              textTransform="capitalize"
              fontWeight="bold"
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Neobrađene narudžbe"
                    count={unprocessedOrders.data?.metric || 0}
                    percentage={{
                      label: <Link to={`/orders`}>
                        <SoftTypography variant="button" fontWeight="regular" color="secondary" sx={{ textDecoration: "underline" }}>
                          Pogledaj narudzbe
                        </SoftTypography>
                      </Link>
                    } || {}}
                    rangeLabel="Poslednjih 7 dana"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Zarada - bruto"
                    count={formatRevenueBruto(revenueBruto.data?.metric || 0)}
                    percentage={revenueBruto.data?.percentage || {}}
                    rangeLabel="Poslednjih 30 dana"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Broj narudžbi"
                    count={numberOfOrders.data?.metric || 0}
                    percentage={numberOfOrders.data?.percentage || {}}
                    rangeLabel="Poslednjih 30 dana"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Broj kupljenih proizvoda"
                    count={numberOfSoldProducts.data?.metric || 0}
                    percentage={numberOfSoldProducts.data?.percentage || {}}
                    rangeLabel="Poslednjih 30 dana"
                  />
                </Grid>
              </Grid>
            </SoftTypography>
          </SoftBox>
        </Grid>

        <Divider sx={{ width: "100%", mb: 0 }} />

        {/* Line Charts */}
        <Grid item xs={12} lg={6}>
          {renderChartWithLoading(
            revenueBrutoComparison.loading,
            revenueBrutoComparison.data,
            GradientLineChart,
            {
              title: "Zarada - bruto",
              description: "Na sedmičnom nivou - u odnosu na 30 dana ranije",
              rangeLabel: "Poslednjih 30 dana",
              chart: revenueBrutoComparison.data,
            }
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {renderChartWithLoading(
            revenueBrutoComparisonYearly.loading,
            revenueBrutoComparisonYearly.data,
            GradientLineChart,
            {
              title: "Zarada - bruto",
              description: "Na sedmičnom nivou - u odnosu na godinu dana ranije",
              rangeLabel: "Poslednjih godinu dana",
              chart: revenueBrutoComparisonYearly.data,
            }
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {renderChartWithLoading(
            numberOfOrdersComparison.loading,
            numberOfOrdersComparison.data,
            GradientLineChart,
            {
              title: "Broj narudžbi",
              description: "Na dnevnom nivou - u odnosu na 30 dana ranije",
              rangeLabel: "Poslednjih 30 dana",
              chart: numberOfOrdersComparison.data,
            }
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {renderChartWithLoading(
            numberOfSoldProductsComparison.loading,
            numberOfSoldProductsComparison.data,
            GradientLineChart,
            {
              title: "Broj prodatih proizvoda",
              description: "Na dnevnom nivou - u odnosu na 30 dana ranije",
              rangeLabel: "Poslednjih 30 dana",
              chart: numberOfSoldProductsComparison.data,
            }
          )}
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={12}>
          {renderChartWithLoading(
            numberOfSoldProductsBarChart.loading,
            numberOfSoldProductsBarChart.data,
            HorizontalBarChart,
            {
              title: "Broj prodatih proizvoda",
              description: "Najprodavaniji proizvodi u poslednjih 30 dana",
              chart: numberOfSoldProductsBarChart.data,
            }
          )}
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
