import PropTypes from "prop-types";

import SoftTypography from "components/SoftTypography";

function DefaultCell({ children }) {
  return (
    <SoftTypography variant="button" color="secondary" fontWeight="regular">
      {children}
    </SoftTypography>
  );
}

DefaultCell.propTypes = {
  children: PropTypes.string.isRequired,
};

export default DefaultCell;
