import appSettings from "./appSettings";
import contact from "./contact";
import socialMediaLinks from "./socialMediaLinks";

const config = {
  appSettings,
  contact,
  socialMediaLinks,
};

export default config;
