import { useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";
// import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultNavbarLink from "app/components/Navbars/DefaultNavbar/DefaultNavbarLink";

import { Collapse } from "@mui/material";

function DefaultNavbarMobile({ routes, open, close, onMenuItemClick }) {
  const { width } = open && open.getBoundingClientRect();
  const [openProductsMenu, setOpenProductsMenu] = useState(false);

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      sx={{
        "& > .MuiPopover-paper": {
          backgroundColor: "white !important",
        },
      }}
      MenuListProps={{ style: { width: `calc(${width}px)` } }}
    >
      <SoftBox px={0.5}>
        <DefaultNavbarLink
          name="Početna"
          className="main-menu-nav-link"
          route="/"
          onClick={onMenuItemClick}
        />
        <DefaultNavbarLink
          name="Prodavnica"
          className="main-menu-nav-link"
          route="/store"
          onClick={onMenuItemClick}
        />
        <DefaultNavbarLink
          name="Proizvodi"
          route="/"
          disableRouting
          onClick={() => setOpenProductsMenu(!openProductsMenu)}
        />
        <Collapse in={openProductsMenu} timeout="auto" unmountOnExit>
          <DefaultNavbarLink
            name="Krema protiv bora"
            className="main-menu-nav-link"
            route="/aboutproducts/krema-protiv-bora"
            sx={{ pl: 3 }}
            labelTextTransform="capitalize"
            onClick={onMenuItemClick}
          />
          <DefaultNavbarLink
            name="Zeolitna maska za lice"
            className="main-menu-nav-link"
            route="/aboutproducts/zeolitna-maska-za-lice"
            sx={{ pl: 3 }}
            labelTextTransform="capitalize"
            onClick={onMenuItemClick}
          />
          <DefaultNavbarLink
            name="Serum za lice"
            className="main-menu-nav-link"
            route="/aboutproducts/serum-za-lice"
            sx={{ pl: 3 }}
            labelTextTransform="capitalize"
            onClick={onMenuItemClick}
          />
          <DefaultNavbarLink
            name="All-In-One Set"
            className="main-menu-nav-link"
            route="/aboutproducts/all-in-one-set"
            sx={{ pl: 3 }}
            labelTextTransform="capitalize"
            onClick={onMenuItemClick}
          />
          <DefaultNavbarLink
            name="Krema-Serum Set"
            className="main-menu-nav-link"
            route="/aboutproducts/krema-serum-set"
            sx={{ pl: 3 }}
            labelTextTransform="capitalize"
            onClick={onMenuItemClick}
          />
          <DefaultNavbarLink
            name="Maska-Serum Set"
            className="main-menu-nav-link"
            route="/aboutproducts/maska-serum-set"
            sx={{ pl: 3 }}
            labelTextTransform="capitalize"
            onClick={onMenuItemClick}
          />
        </Collapse>
        <DefaultNavbarLink
          name="Recenzije"
          className="main-menu-nav-link"
          route="/reviews"
          onClick={onMenuItemClick}
        />
        <DefaultNavbarLink
          name="O meni"
          className="main-menu-nav-link"
          route="/about"
          onClick={onMenuItemClick}
        />
      </SoftBox>
    </Menu>
  );
}

DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
  onMenuItemClick: PropTypes.func,
};

export default DefaultNavbarMobile;
