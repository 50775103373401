const SERVER_URL_PREFIX = process.env.REACT_APP_API_BASE_URL;
const RESOURCES_SERVER_URL_PREFIX = process.env.REACT_APP_RESOURCES_SERVER_BASE_URL;
const FRONTEND_USER_SIDE_SERVER_URL_PREFIX = process.env.REACT_APP_FRONTEND_USER_SIDE_SERVER_URL_PREFIX;
const FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX = process.env.REACT_APP_FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX;

function getToken() {
  return localStorage.getItem("token");
}

function getRefreshToken() {
  return localStorage.getItem("refreshToken");
}

async function refreshAuthToken() {
  const refreshToken = getRefreshToken();
  const response = await fetch(`${SERVER_URL_PREFIX}/api/auth/refresh-token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refreshToken }),
  });

  if (response.ok) {
    const data = await response.json();
    localStorage.setItem("token", data.accessToken);
    localStorage.setItem("refreshToken", data.refreshToken);
    return data.accessToken;
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
    throw new Error("Failed to refresh token");
  }
}

async function fetchWithAuth(url, options, retry = true) {
  const initialHeaders = options.headers || {};
  const headers = addAuthHeader(initialHeaders);
  options.headers = headers;

  let response = await fetch(url, options);

  if ((response.status === 401 || response.status === 403) && retry) {
    try {
      const newToken = await refreshAuthToken();
      options.headers["Authorization"] = `Bearer ${newToken}`;
      response = await fetch(url, options);
    } catch (error) {
      console.error("Token refresh failed", error);
      throw error;
    }
  }

  return response;
}

async function get(url) {
  return await fetchWithAuth(url, { method: "GET" });
}

async function post(url, data, options) {
  return await fetchWithAuth(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    ...options,
  });
}

async function put(url, data) {
  return await fetchWithAuth(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

async function patch(url, data) {
  return await fetchWithAuth(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

async function del(url) {
  return await fetchWithAuth(url, { method: "DELETE" });
}

const addAuthHeader = (initialHeaders) => {
  const token = getToken();
  const headers = { ...initialHeaders };
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
};

export {
  get,
  post,
  put,
  patch,
  del,
  SERVER_URL_PREFIX,
  RESOURCES_SERVER_URL_PREFIX,
  FRONTEND_USER_SIDE_SERVER_URL_PREFIX,
  FRONTEND_ADMIN_SIDE_SERVER_URL_PREFIX,
};
