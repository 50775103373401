const dataTableData = {
  columns: [
    { Header: "Proizvod", accessor: "product", width: "50%" },
    { Header: "Količina", accessor: "quantity", align: "center" },
    { Header: "Cijena", accessor: "price", width: "10%" },
    { Header: "Opcije", accessor: "action", width: "8%" }
  ],

  rows: [
    // {
    //   product: <ProductCell image={blackChair} name="Christopher Knight Home" />,
    //   quantity: <DefaultCell>2</DefaultCell>,
    //   price: <DefaultCell>$89.53</DefaultCell>,
    //   action: <ActionCell />
    // },
    // {
    //   product: <ProductCell image={chairPink} name="Bar Height Swivel Barstool" />,
    //   price: <DefaultCell>$99.99</DefaultCell>,
    //   review: <ReviewCell rating={5} />,
    //   availability: (
    //     <SoftBox width="8rem">
    //       <SoftProgress variant="gradient" value={90} color="success" />
    //     </SoftBox>
    //   ),
    //   id: <DefaultCell>87120</DefaultCell>,
    // },
    // {
    //   product: <ProductCell image={chairSteel} name="Signature Design by Ashley" />,
    //   price: <DefaultCell>$129.00</DefaultCell>,
    //   review: <ReviewCell rating={4.5} />,
    //   availability: (
    //     <SoftBox width="8rem">
    //       <SoftProgress variant="gradient" value={60} color="warning" />
    //     </SoftBox>
    //   ),
    //   id: <DefaultCell>412301</DefaultCell>,
    // },
    // {
    //   product: <ProductCell image={chairWood} name="Modern Square" />,
    //   price: <DefaultCell>$59.99</DefaultCell>,
    //   review: <ReviewCell rating={4.5} />,
    //   availability: (
    //     <SoftBox width="8rem">
    //       <SoftProgress variant="gradient" value={40} color="warning" />
    //     </SoftBox>
    //   ),
    //   id: <DefaultCell>001992</DefaultCell>,
    // },
  ],
};

export default dataTableData;
