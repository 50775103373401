/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

function DefaultNavbarMenu({
  open: openProp,
  anchor: anchorProp,
  placement = "bottom-start",
  children,
  style = {},
}) {
  const [anchor, setAnchor] = useState(anchorProp);
  const [open, setOpen] = useState(openProp);

  useEffect(() => {
    setAnchor(anchorProp);
  }, [anchorProp]);

  return (
    <Popper
      anchorEl={anchor}
      open={Boolean(anchor) && (open || openProp)}
      placement={placement}
      transition
      style={{ zIndex: 10, ...style }}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} sx={{ transformOrigin: "left top" }}>
          <SoftBox
            bgColor="white"
            shadow="lg"
            borderRadius="xl"
            p={2}
            onMouseEnter={() => setOpen(true)}
          >
            {children}
          </SoftBox>
        </Grow>
      )}
    </Popper>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMenu.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

export default DefaultNavbarMenu;
