import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftBadge from "components/SoftBadge";

function PaymentStatusCell({ icon, status }) {
  const selectColor = (status) => {
    switch (status) {
      case "Plaćeno": return "#379188";
      case "Nije plaćeno": return "secondary";
      default: "light"
    }
  }

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBadge
        variant="contained"
        color="secondary"
        badgeContent={<SoftBox display="flex" alignItems="center" gap="0.3rem" sx={({ palette }) => ({
          color: palette[selectColor(status)] ? palette[selectColor(status)].main : selectColor(status),
          fontWeight: "bold",
        })}><Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>{status}</SoftBox>}
        container
        size="xs"
      />
    </SoftBox>
  );
}

PaymentStatusCell.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default PaymentStatusCell;
