// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import { Grid } from "@mui/material";
import { login } from "context";
import { useState } from "react";
import { useSoftUIController } from "context";
import { useNavigate } from "react-router-dom";
import brand from "assets/images/logos/logo-transparent-green.png";
import { post, SERVER_URL_PREFIX } from "api";
import { Navigate } from "react-router-dom";
import { getDecodedToken } from "auth/jwt";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [_, dispatch] = useSoftUIController();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [controller] = useSoftUIController();
  const { isAuthenticated } = controller;

  const makeLogin = () => {
    setLoading(true);
    setError(null);

    if (email === '' || password === '') {
      setLoading(false);
      return;
    }

    post(`${SERVER_URL_PREFIX}/api/auth/login`, { username: email, password: password })
      .then(response => response.json())
      .then(data => {
        if (data.accessToken) {
          const { name, email, imageLocation } = getDecodedToken(data.accessToken);
          login(dispatch, { token: data.accessToken, refreshToken: data.refreshToken, user: { name: name, email: email, imageLocation: imageLocation } });
        }
        setLoading(false);
        navigate("/dashboard");
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setError(error);
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      makeLogin();
    }
  }

  return (
    isAuthenticated ? <Navigate to="/" /> :
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} lg={6} xl={5} mt={"2rem"}>
          <Card>
            <SoftBox mt={2} mb={1} textAlign="center">
              <SoftBox
                component="img"
                src={brand}
                alt="Mama za pet brand logo"
                sx={{ height: "5rem" }} />
            </SoftBox>
            <SoftBox p={3}>
              <SoftBox component="form" role="form">
                <SoftBox mb={2}>
                  <SoftInput type="email" placeholder="Email" onChange={e => setEmail(e.target.value)} onKeyPress={handleKeyPress} autoComplete="email" />
                </SoftBox>
                <SoftBox mb={2}>
                  <SoftInput type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} onKeyPress={handleKeyPress} />
                </SoftBox>
                <SoftBox mt={4} mb={1}>
                  <SoftButton variant="gradient" color="info" fullWidth onClick={makeLogin}>
                    Prijavi se
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
            {loading &&
              <SoftBox p={1} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  Učitavanje...
                </SoftTypography>
              </SoftBox>
            }
            {error &&
              <SoftBox p={1} mb={1} textAlign="center">
                <SoftTypography variant="h5" fontWeight="medium">
                  Greška prilikom prijave
                </SoftTypography>
              </SoftBox>
            }
          </Card>
        </Grid>
      </Grid>
  );
}

export default LoginPage;