import { Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";

const ProductDetails = (props) => {
  return (
    <PageLayout>
      <Grid container>
        <Grid item xs={12}>
          <SoftTypography>ProductDetails</SoftTypography>
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default ProductDetails;