import React from "react";
import { Grid, Typography, Box, Link } from "@mui/material";
import PropTypes from "prop-types";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import SoftTypography from "components/SoftTypography";
import { mapProductNameToFrontPageURI } from "pages/user/home";
import { Link as RouterLink } from "react-router-dom";
import { formatPrice } from "utils/formatPrice";

const ProductSet = ({ sets, selectedCurrency }) => {
  return (
    <Box mt={6}>
      <Typography variant="h6" mb={2}>
        Dostupno i u setovima:
      </Typography>
      {sets.map((set) => (
        <Link to={mapProductNameToFrontPageURI(set.name)} component={RouterLink} key={set.name}>
          <Grid container key={set.id} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={3} md={1.5} lg={1.5} mr={2} display="flex" alignItems="center">
              <Box
                component="img"
                src={RESOURCES_SERVER_URL_PREFIX + set.imageLocation}
                alt={set.name}
                sx={{ width: "100%", height: "auto", borderRadius: "4px" }}
              />
            </Grid>
            <Grid
              item
              xs={8}
              md={8.5}
              lg={8}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="subtitle2" fontWeight="bold">
                {set.name}
              </Typography>
              <Box>
                <SoftTypography
                  sx={({ typography }) => ({
                    fontSize: typography.size.sm,
                  })}
                >
                  Sadrži: &nbsp;
                  {set.compositeProductItems.map((item, i) => (
                    <i key={item.itemProduct.name}>
                      <span>
                        {item.quantity !== 1 ? item.quantity + " x " : ""}
                        {item.itemProduct.name}
                        {i < set.compositeProductItems.length - 1 && ", "}
                      </span>
                    </i>
                  ))}
                </SoftTypography>
              </Box>
              <SoftTypography fontWeight="bold" sx={{ fontSize: "0.9rem" }}>
                <SoftTypography
                  component="span"
                  sx={({ typography }) => ({
                    fontSize: typography.size.sm,
                    fontWeight: typography.fontWeightRegular,
                    color: "#000",
                    textDecoration: "line-through",
                    marginRight: "0.5rem",
                  })}
                >
                  {formatPrice(106, "KM")}
                </SoftTypography>
                {formatPrice(
                  selectedCurrency?.toLowerCase() === "bam" ? set.unitPriceBam : set.unitPriceEur,
                  "KM"
                )}
              </SoftTypography>
            </Grid>
          </Grid>
        </Link>
      ))}
    </Box>
  );
};

ProductSet.propTypes = {
  sets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      quantity: PropTypes.string.isRequired,
      unitPriceBam: PropTypes.number.isRequired,
      unitPriceEur: PropTypes.number.isRequired,
      imageLocation: PropTypes.string.isRequired,
      compositeProductItems: PropTypes.arrayOf(
        PropTypes.shape({
          itemProduct: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            quantity: PropTypes.string.isRequired,
            unitPriceBam: PropTypes.number.isRequired,
            unitPriceEur: PropTypes.number.isRequired,
            imageLocation: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            specialOffers: PropTypes.arrayOf(
              PropTypes.shape({
                quantity: PropTypes.string.isRequired,
                priceBAM: PropTypes.number.isRequired,
                priceEUR: PropTypes.number.isRequired,
              })
            ),
          }).isRequired,
          quantity: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default ProductSet;
