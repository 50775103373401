import { useSoftUIController } from "context";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Link from "@mui/material/Link";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import { card, cardContent, cardIconBox, cardIcon } from "app/components/sidenav/styles/sidenavCard";
import { FRONTEND_USER_SIDE_SERVER_URL_PREFIX } from "api";

function SidenavCard() {
  const [controller] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;

  return (
    <Card sx={(theme) => card(theme, { miniSidenav })}>
      {!miniSidenav && <CardContent sx={(theme) => ({ ...cardContent(theme, { sidenavColor }), textAlign: "center" })} >
        <SoftBox lineHeight={1}>
          <SoftTypography variant="h6" color="white">
            Website
          </SoftTypography>
          <SoftBox mb={1.825} mt={-1}>
            <SoftTypography variant="caption" color="white" fontWeight="medium">
              mamazapet.com
            </SoftTypography>
          </SoftBox>
          <SoftButton
            component={Link}
            href={`${FRONTEND_USER_SIDE_SERVER_URL_PREFIX}`}
            target="_blank"
            rel="noreferrer"
            size="small"
            color="white"
            fullWidth
          >
            Posjeti Website
          </SoftButton>
        </SoftBox>
      </CardContent>
      }
      {miniSidenav && <CardContent sx={(theme) => ({ ...cardContent(theme, { sidenavColor }), textAlign: "center" })} >
        <SoftBox
          bgColor="white"
          width="2rem"
          height="2rem"
          borderRadius="md"
          shadow="md"
          mb={2}
          sx={cardIconBox}
        >
          <Icon fontSize="medium" sx={(theme) => cardIcon(theme, { sidenavColor })}>
            star
          </Icon>
        </SoftBox>
      </CardContent>}
    </Card>
  );
}

export default SidenavCard;
