/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import { createContext, useContext, useReducer, useMemo, useEffect } from "react";

import initialValuesGlobal from "pages/user/checkout/checkout-form/schemas/initialValuesGlobal";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { get, SERVER_URL_PREFIX } from "api";
import { getDecodedToken } from "auth/jwt";

// The Soft UI Dashboard PRO React main context
const SoftUI = createContext(null);

// Setting custom name for the context which is visible on react dev tools
SoftUI.displayName = "SoftUIContext";

// Soft UI Dashboard PRO React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "ADD_CART_ITEM": {
      return {
        ...state,
        cartItems: state.cartItems.find(item => item.id === action.value.id) ?
          state.cartItems.map((item) => item.id === action.value.id ? { ...item, quantity: ++item.quantity } : item) :
          [...state.cartItems, action.value]
      };
    }
    case "REMOVE_CART_ITEM": {
      return { ...state, cartItems: state.cartItems.filter(ci => ci.id !== action.value) };
    }
    case "RESET_CART_ITEMS": {
      return { ...state, cartItems: [] };
    }
    case "CHANGE_PRODUCT_PRICE": {
      return { ...state, cartItems: state.cartItems.map((item) => item.id === action.value.id ? { ...item, price: action.value.realPrice, calculatedPrice: action.value.calculatedPrice } : item) }
    }
    case "UPDATE_PRODUCT": {
      return { ...state, cartItems: state.cartItems.map((item) => item.id === action.value.id ? action.value : item) }
    }
    case "SELECT_CURRENCY": {
      return { ...state, selectedCurrency: action.value }
    }
    case "SET_CHECKOUT_VALUES": {
      return { ...state, checkoutValues: { ...action.value, country: action.value.country ? action.value.country.value : "" } }
    }
    case "SET_COUPON": {
      return { ...state, coupon: action.value }
    }
    case "SET_COUNTRIES": {
      return { ...state, countries: action.value }
    }
    case "SELECT_ORDER": {
      return { ...state, selectedOrders: state.selectedOrders.find(o => o === action.value.id) ? state.selectedOrders.filter(o => o !== action.value.id) : [...state.selectedOrders, action.value.id] }
    }
    case "SELECT_ORDERS": {
      return { 
        ...state, 
        selectedOrders: [...new Set([...state.selectedOrders, ...action.value])] 
      };
    }    
    case "CLEAR_SELECT_ORDERS": {
      return { ...state, selectedOrders: [] }
    }
    case 'LOGIN':
      localStorage.setItem('token', action.value.token);
      localStorage.setItem('refreshToken', action.value.refreshToken);
      return {
        ...state,
        isAuthenticated: true,
        user: action.value.user,
        token: action.value.token,
        refreshToken: action.value.refreshToken,
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        refreshToken: null,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Soft UI Dashboard PRO React context provider
function SoftUIControllerProvider({ children }) {
  const initialState = {
    miniSidenav: false,
    transparentSidenav: true,
    sidenavColor: "success",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
    selectedCurrency: localStorage.getItem('currency') ? JSON.parse(localStorage.getItem('currency')) : { value: "bam", label: "BAM" },
    checkoutValues: localStorage.getItem('checkoutValues') ? JSON.parse(localStorage.getItem('checkoutValues')) : initialValuesGlobal,
    coupon: null, // { code: 'ABC123', discount: 10, type: 'percentage'}
    countries: localStorage.getItem('countries') ? JSON.parse(localStorage.getItem('countries')) : [],
    selectedOrders: [],

    // Authentication
    isAuthenticated: !!localStorage.getItem('token'),
    user: {
      name: !!localStorage.getItem('token') ? getDecodedToken(localStorage.getItem('token')).name || 'Korisnik' : 'Korisnik',
      email: !!localStorage.getItem('token') ? getDecodedToken(localStorage.getItem('token')).email || '' : '',
      imageLocation: !!localStorage.getItem('token') ? getDecodedToken(localStorage.getItem('token')).imageLocation || 'static/images/users/default.png' : 'static/images/users/default.png',
    },
    token: localStorage.getItem('token') || null,
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const { cartItems, selectedCurrency, checkoutValues, selectedOrders, coupon, countries } = controller;

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  useEffect(() => { localStorage.setItem('cartItems', JSON.stringify(cartItems)) }, [cartItems]);
  useEffect(() => { localStorage.setItem('currency', JSON.stringify(selectedCurrency)) }, [selectedCurrency]);
  useEffect(() => { localStorage.setItem('checkoutValues', JSON.stringify(checkoutValues)) }, [checkoutValues]);
  useEffect(() => { localStorage.setItem('countries', JSON.stringify(countries)) }, [countries]);

  useEffect(() => {
    cartItems.forEach(product =>
      get(`${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${product.quantity}&currency=${selectedCurrency.value.toUpperCase()}`)
        .then(response => response.json())
        .then(data => {
          updateProduct(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice, quantity: product.quantity, unitPrice: data.unitPrice });
        }))
  }, [selectedCurrency]);

  return <SoftUI.Provider value={value}>{children}</SoftUI.Provider>;
}

// Soft UI Dashboard PRO React custom hook for using context
function useSoftUIController() {
  const context = useContext(SoftUI);

  if (!context) {
    throw new Error("useSoftUIController should be used inside the SoftUIControllerProvider.");
  }

  return context;
}

// Typechecking props for the SoftUIControllerProvider
SoftUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const addCartItem = (dispatch, value) => dispatch({ type: "ADD_CART_ITEM", value });
const removeCartItem = (dispatch, value) => dispatch({ type: "REMOVE_CART_ITEM", value });
const resetCartItems = (dispatch, value) => dispatch({ type: "RESET_CART_ITEMS", value });
const changeProductPrice = (dispatch, value) => dispatch({ type: "CHANGE_PRODUCT_PRICE", value });
const updateProduct = (dispatch, value) => dispatch({ type: "UPDATE_PRODUCT", value });
const selectCurrency = (dispatch, value) => dispatch({ type: "SELECT_CURRENCY", value });
const setCheckoutValues = (dispatch, value) => dispatch({ type: "SET_CHECKOUT_VALUES", value });
const setCoupon = (dispatch, value) => dispatch({ type: "SET_COUPON", value });
const setCountries = (dispatch, value) => dispatch({ type: "SET_COUNTRIES", value });
const selectOrder = (dispatch, value) => dispatch({ type: "SELECT_ORDER", value });
const selectOrders = (dispatch, value) => dispatch({ type: "SELECT_ORDERS", value });
const clearSelectOrders = (dispatch, value) => dispatch({ type: "CLEAR_SELECT_ORDERS", value });
const login = (dispatch, value) => dispatch({ type: "LOGIN", value });
const logout = (dispatch, value) => dispatch({ type: "LOGOUT", value });

export {
  SoftUIControllerProvider,
  useSoftUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDirection,
  setLayout,
  addCartItem,
  removeCartItem,
  resetCartItems,
  changeProductPrice,
  updateProduct,
  selectCurrency,
  setCheckoutValues,
  setCoupon,
  setCountries,
  selectOrder,
  selectOrders,
  clearSelectOrders,
  login,
  logout
};
