import { Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import PageLayout from "pages/layout/page-layout";
import ProductDescription from "pages/user/product-pages/krema-protiv-bora/product-description";
import Reviews from "pages/user/reviews";
import { useDocumentTitle } from "hooks/use-document-title";
import { SERVER_URL_PREFIX } from "api";
import ProductDetails from "pages/user/components/product-details";
import { Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "api";
import { useSoftUIController, setOpenConfigurator } from "context";
import ScrollableProductList from "pages/user/product-pages/components/scrollable-product-list";
import SoftTypography from "components/SoftTypography";
import { addCartItem, updateProduct } from "context";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import config from "config/config";
import { useMetaTag } from "hooks/use-document-title";
import useGTMEvent from "hooks/use-gtm-event";
import ProductDetailsSkeleton from "pages/user/components/product-details/product-details-skeleton";

const ZeolitnaMaskaZaLice = () => {
  useDocumentTitle("Zeolitna maska za lice | Mama za pet – Detoks i Njega Kože");
  useMetaTag(
    "description",
    "Maska sa zeolitom i kombinacijom eteričnih ulja pruža detoksikaciju i njegu kože. Idealna za uklanjanje toksina i akni."
  );
  useMetaTag("keywords", "maska za lice, zeolit maska, prirodna njega kože, detoksikacija kože");

  const [tabIndex, setTabIndex] = useState(0);
  const sendEventToGTM = useGTMEvent();

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [controller, dispatch] = useSoftUIController();
  const { selectedCurrency, cartItems } = controller;
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const getProductsAddToCardData = (productName, productQuantity) => {
    const product = products.find((p) => p.name === productName);

    if (product) {
      const { id, name, imageLocation, quantity, unitPriceBam, unitPriceEur } = product;
      const price = selectedCurrency?.value === "bam" ? unitPriceBam : unitPriceEur;

      return {
        id: String(id),
        name: name,
        img: `${RESOURCES_SERVER_URL_PREFIX}${imageLocation}`,
        quantity: productQuantity,
        unitPrice: price,
        unitQuantity: quantity,
      };
    }

    return undefined;
  };

  const handleAddToCart = (product, eventSource) => {
    const currentProduct = cartItems.find((item) => item.id === product.id);
    get(
      `${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${currentProduct ? currentProduct.quantity + 1 : product.quantity
      }&currency=${selectedCurrency.value.toUpperCase()}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!currentProduct) {
          addCartItem(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
          });
          sendEventToGTM("add_to_cart", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            quantity: 1,
            page_source: "Zeolitna maska za lice",
            event_source: eventSource,
          });
        } else {
          const newQuantity = currentProduct.quantity + product.quantity;
          updateProduct(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
            quantity: newQuantity,
          });
          sendEventToGTM("update_cart_quantity", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            new_quantity: newQuantity,
            page_source: "Zeolitna maska za lice",
            event_source: eventSource,
          });
        }
      });
  };

  const handleAdd = (product, eventSource) => {
    setTimeout(handleConfiguratorOpen, 300);
    handleAddToCart(getProductsAddToCardData(product.name, product.quantity), eventSource);
  };

  const getSetsContainingProduct = (targetProduct, products) => {
    return products.filter((product) =>
      product.compositeProductItems?.some((item) => item.itemProduct?.id === targetProduct.id)
    );
  };

  const getCartItemQuantity = (productName) => {
    const product = cartItems.find((item) => item.name === productName);

    if (product) {
      return product.quantity;
    }

    return 0;
  };

  const getMaxCartQuantity = (productName) => {
    return config.appSettings.maxQuantityPerItem - getCartItemQuantity(productName);
  };

  return (
    <PageLayout sx={{ overflow: "hidden" }}>
      <Divider sx={{ mb: "0" }} />
      {products && products.length > 0 && !isLoadingProducts ? (
        <ProductDetails
          product={products.find((p) => p.id === 2)}
          onAddToCart={(product) => handleAdd(product, "add_to_cart_button")}
          productImages={[
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/gallery/maska-za-lice/maska-gallery-1.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/gallery/maska-za-lice/maska-gallery-2.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/gallery/maska-za-lice/maska-gallery-3.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/gallery/maska-za-lice/maska-gallery-4.webp`,
            `${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting/new/gallery-shooting-8.webp`,
          ]}
          descriptionContentArray={[
            "Prirodni mineral  zeolit u maski dubinski čisti kožu, uklanja toksine i pomaže u regeneraciji.",
            "Piling efekt: maska nježno uklanja mrtve ćelije kože, ostavljajući lice svilenkasto glatkim i spremnim za regeneraciju.",
            "Dubinska hidratacija: Hladno cijeđena ulja i prirodni minerali obnavljaju nivo vlage u koži, čineći je mekom, elastičnom i blistavom.",
            "Prikladna za sve tipove kože: Nježna formula pogodna je za osjetljivu, suhu, masnu i problematičnu kožu."
          ]}
          sets={getSetsContainingProduct(
            products.find((p) => p.name === "Zeolitna maska za lice"),
            products
          )}
          maxQuantity={getMaxCartQuantity("Zeolitna maska za lice")}
        />
      ) : (
        <ProductDetailsSkeleton />
      )}
      <Grid
        container
        width={{ xs: "100%", lg: "100%", xl: "100%" }}
        mt={{ xs: "3rem", lg: "0" }}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: "0rem",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          sx={{
            width: "100%",
            mb: "1rem",
            backgroundColor: "white !important",
            borderBottom: "1px solid #e0e0e0",
            borderTop: "1px solid #e0e0e0",
            borderRadius: "0",
            padding: "0",
            "& .MuiTab-root": {
              minHeight: "64px !important",
              color: "black",
              maxWidth: "220px !important",
              "&:hover": {
                color: "#6ac4bb !important",
              },
            },
            "& .Mui-selected": {
              color: "#6ac4bb !important",
              borderBottom: "3px solid #7eddd3",
              borderRadius: "0",
            },
            "& .MuiTabs-indicator": {
              display: "none",
              borderBottom: "1px solid black",
            },
          }}
        >
          <Tab label="O PROIZVODU" sx={{ fontSize: "14px" }} />
          <Tab label="SASTOJCI" sx={{ fontSize: "14px" }} />
          <Tab label="NAČIN UPOTREBE" sx={{ fontSize: "14px" }} />
        </Tabs>

        {tabIndex === 0 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Daje rezultate već nakon 7 dana!"
            textVariant="body2"
            textArray={[
              "Prikladna za sve tipove kože: Nježna formula pogodna je za osjetljivu, suhu, masnu i problematičnu kožu.",
              "Nevidljiva na koži: Za razliku od mnogih drugih maski, Mamazapet maska se potpuno upija u kožu i ne ostavlja tragove. Možete je koristiti prije spavanja, bez brige da će zaprljati jastuk – izgleda kao da ste nanijeli hidratantnu kremu.",
              "Piling efekt: Pored detoksikacije, maska nježno uklanja mrtve ćelije kože, ostavljajući lice svilenkasto glatkim i spremnim za regeneraciju.",
              "Dubinska hidratacija: Hladno cijeđena ulja i prirodni minerali obnavljaju nivo vlage u koži, čineći je mekom, elastičnom i blistavom.",
              "Ciljano djelovanje na bubuljice: Maska se može koristiti svake večeri na problematičnim mjestima kako bi smanjila crvenilo i ubrzala regeneraciju kože.",
              "Ujednačen ten: Redovno korištenje smanjuje mrlje i diskoloracije, vraćajući koži prirodnu blistavost.",
              "Prirodna njega bez kompromisa: Hladno cijeđena ulja i biljna eterična ulja osiguravaju da vaša koža dobije najčistije i najefikasnije prirodne sastojke.",
              "Aromaterapijski učinak: Miris eteričnih ulja poput ruže, smilja i limunskog eukaliptusa opušta um i doprinosi osjećaju wellnessa."
            ]}
          />
        )}
        {tabIndex === 1 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Sastojci"
            textVariant="body2"
            textArray={[
              "Zeolit K-96: Prirodni mineral s moćnim detoksikacijskim svojstvima – dubinski čisti kožu, uklanja toksine i pomaže u regeneraciji.",
              "Hladno cijeđeno ulje pšeničnih klica: Bogato vitaminom E, dubinski hrani kožu i pruža dugotrajnu hidrataciju.",
              "Hladno cijeđeno ulje avokada: Pomaže obnavljanju kože i smanjuje fine linije i bore.",
              "Hladno cijeđeno ulje argana: Obnavlja elastičnost i smanjuje osjećaj suhoće.",
              "Eterično ulje ruža i smilje - Regenerišu kožu, smanjuju crvenilo i vraćaju prirodan sjaj.",
              "Eterično ulje kardamon i tamanu - Pomažu u smanjenju bubuljica i nepravilnosti.",
              "Eterično ulje origano i bor - Čiste pore i pružaju osvježavajući osjećaj.",
            ]}
          />
        )}
        {tabIndex === 2 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto",
            }}
            titleSx={{ pb: "1rem" }}
            title="Način upotrebe"
            textVariant="body2"
            textArray={[
              "Mama za pet zeolitna maska lako se uklapa u tvoju večernju njegu kože, pružajući dubinsku njegu dok spavaš.",
              "1. Priprema kože: Prije nanošenja maske, očisti lice blagim sredstvom za čišćenje. Čista koža omogućava bolju apsorpciju aktivnih sastojaka maske.",
              "2. Nanošenje maske: Nanesi tanki sloj maske na lice i dekolte.Nježno umasiraj masku u kožu kružnim pokretima. Masaža dodatno potiče mikrocirkulaciju i omogućava da hranjivi sastojci dublje prodru u kožu.",
              "3. Ostavi masku preko noći: Maska je formulisana tako da se brzo upija i ne ostavlja tragove na koži ili jastuku. Nakon nanošenja, ne trebaš je isprati – ponaša se poput kreme koja djeluje cijelu noć, dok vi spavate.",
              "4. Jutarnja rutina: Ujutro očisti lice mlakom vodom i blagim sredstvom za čišćenje kako biste uklonili ostatke maske. Zatim, nanesite Mama za pet serum i/ili hidratantnu kremu kako bi zaključala vlagu i započela dan sa blistavom, svježom kožom.",
              "Učestalost korištenja: Masku koristite 1-2 puta sedmično kao dio vaše redovne rutine njege kože. Za ciljano djelovanje na bubuljicama, masku možete nanositi lokalno svake večeri na problematične zone.",
            ]}
          />
        )}
      </Grid>

      <Divider sx={{ mb: "0", mt: "3rem" }} />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        pt="1rem"
        px={{ xs: "1rem", md: "0" }}
      >
        <SoftTypography variant="h4" sx={{ color: "#000" }}>
          Pogledajte i ostale proizvode koji bi vam se mogli svidjeti
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ mb: "0" }} />

      <ScrollableProductList
        products={products.filter((p) => p.name !== "Zeolitna maska za lice")}
        selectedCurrency={selectedCurrency}
        handleAddToCart={(product) => handleAdd(product, "suggestions_add_to_cart_button")}
      />

      <SoftBox display="flex" justifyContent="center" mt="4rem" />

      <Reviews productIds={[2]} />
    </PageLayout>
  );
};

export default ZeolitnaMaskaZaLice;
