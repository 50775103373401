import { CircularProgress, Divider, Grid, Rating } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";
import SoftProgress from "components/SoftProgress";
import StarIcon from "@mui/icons-material/Star";
import { get, SERVER_URL_PREFIX } from "api";
import { useEffect, useState } from "react";
import SoftPagination from "components/SoftPagination";
import PropTypes from "prop-types";
import ReviewCard from "pages/user/review-card";
import Pagination from "components/Pagination";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import { useDocumentTitle } from "hooks/use-document-title";
import { useMetaTag } from "hooks/use-document-title";

const Reviews = ({ productIds = [], sx }) => {
  useDocumentTitle("Recenzije | Mama za pet – Sigurna Kupovina");
  useMetaTag(
    "description",
    "Pročitajte recenzije Mama za pet proizvoda. Pogledajte šta naše kupce najviše oduševljava."
  );
  useMetaTag(
    "keywords",
    "mama za pet recenzije, mama za pet iskustva, mama za pet utisci, mama za pet kupci, mama za pet proizvodi recenzije"
  );

  const [page, setPage] = useState(0);
  const [overallRating, setOverallRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);

  const [reviewsResponse, setreviewsResponse] = useState(null);
  const [reviewsResponseLoading, setReviewsResponseLoading] = useState(true);
  const [reviewsResponseError, setReviewsResponseError] = useState(null);

  const [overallStatistics, setOverallStatistics] = useState([]);
  const [overallStatisticsLoading, setOverallStatisticsLoading] = useState(true);
  const [overallStatisticsError, setOverallStatisticsError] = useState(null);

  // Step 1: Add state for selected image
  const [selectedImage, setSelectedImage] = useState(null);

  // Step 2: Image click handler
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Step 3: Display preview
  const renderImagePreview = () => {
    if (!selectedImage) return null;

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: "9999",
        }}
        onClick={() => setSelectedImage(null)}
      >
        <img src={selectedImage} alt="Preview" style={{ maxWidth: "80%", maxHeight: "80%" }} />
      </div>
    );
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = productIds.length > 0
          ? await get(
            `${SERVER_URL_PREFIX}/api/web/review?productIds=${productIds.join(',')}&size=4&page=${page}`
          )
          : await get(`${SERVER_URL_PREFIX}/api/web/review/approved?size=8&page=${page}`);
        const data = await response.json();
        setreviewsResponse(data);
      } catch (error) {
        setReviewsResponseError(error);
      } finally {
        setReviewsResponseLoading(false);
      }
    };
    fetchReviews();
  }, [page]);

  useEffect(() => {
    const fetchOverallStatistics = async () => {
      try {
        const response = productIds?.length > 0
          ? await get(`${SERVER_URL_PREFIX}/api/web/review/statistics/product/${productIds[0]}`)
          : await get(`${SERVER_URL_PREFIX}/api/web/review/statistics/overall`);
        const data = await response.json();
        setOverallStatistics(data);
      } catch (error) {
        setOverallStatisticsError(error);
      } finally {
        setOverallStatisticsLoading(false);
      }
    };
    fetchOverallStatistics();
  }, []);

  const calculateOverallAverageRating = (data) => {
    const { ratingCounts } = data;
    let totalRatings = 0;
    let totalVotes = 0;

    Object.keys(ratingCounts).forEach((rating) => {
      const count = ratingCounts[rating];
      totalRatings += rating * count;
      totalVotes += count;
    });

    return {
      averageRating: totalVotes > 0 ? totalRatings / totalVotes : 0,
      totalReviews: totalVotes,
    };
  };

  useEffect(() => {
    if (overallStatistics?.ratingCounts) {
      const ratingData = calculateOverallAverageRating(overallStatistics);
      setOverallRating(ratingData.averageRating.toFixed(1));
      setTotalReviews(ratingData.totalReviews);
    }
  }, [overallStatistics]);

  const generateProgress = (label, value, color) => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={3} md={2} sx={{ padding: "0 !important" }}>
          <SoftTypography variant="caption">{label}</SoftTypography>
        </Grid>
        <Grid item xs={8} md={7} sx={{ padding: "0 !important", marginTop: "6px" }}>
          <SoftProgress value={Number(value)} color={color} sx={{ width: "100%" }} />
        </Grid>
        <Grid
          item
          xs={1}
          sx={{ padding: "0 !important", display: "flex", justifyContent: "flex-end" }}
        >
          <SoftTypography variant="caption">{value}%</SoftTypography>
        </Grid>
      </Grid>
    );
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const calculateRatingByCount = (ratingIndex) => {
    if (totalReviews === 0) return 0;
    return Math.round((overallStatistics.ratingCounts[ratingIndex] / totalReviews) * 100);
  };

  const renderPagination = () => {
    return (
      <Pagination
        totalPages={reviewsResponse.totalPages}
        currentPage={page}
        onPageChange={(newPage) => {
          scrollToElement("review-heading-container");
          setPage(newPage);
        }}
      />
    );
  };

  if (reviewsResponseLoading || overallStatisticsLoading) {
    return (
      <PageLayout
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...sx,
        }}
      >
        <Divider sx={{ width: "100%" }} />
        <SoftBox display="flex" justifyContent="center" id="review-heading-container">
          <SoftTypography variant="h3">Recenzije</SoftTypography>
        </SoftBox>
        <Divider sx={{ width: "100%", marginBottom: "40px" }} />
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          gap="3rem"
        >
          <SoftTypography variant="h5">Recenzije se učitavaju...</SoftTypography>
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: "#7eddd3",
              animationDuration: "550ms",
              // position: 'absolute',
              // left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={40}
            thickness={4}
          />
        </SoftBox>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      sx={{ overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Divider sx={{ width: "100%" }} />
      <SoftBox display="flex" justifyContent="center" id="review-heading-container">
        <SoftTypography variant="h3">Recenzije</SoftTypography>
      </SoftBox>
      <Divider sx={{ width: "100%", marginBottom: "40px" }} />

      <SoftBox display="flex" justifyContent="center" width="100%">
        <Grid container maxWidth="1000px" width="100%">
          <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center">
            <SoftBox display="flex" justifyContent="center" alignItems="center">
              <SoftBox display="flex" flexDirection="column" alignItems="center" gap="10px">
                <SoftTypography variant="h2">{overallRating}</SoftTypography>
                <Rating
                  name="text-feedback"
                  value={Number(overallRating)}
                  readOnly
                  precision={0.1}
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
                <SoftTypography variant="caption">
                  Na osnovu{" "}
                  <SoftTypography variant="caption" sx={{ fontWeight: "bold" }}>
                    {totalReviews}
                  </SoftTypography>{" "}
                  recenzija
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={{ xs: "3rem", md: "0" }}
            mx={{ xs: "1rem", md: "0" }}
          >
            {generateProgress("Odlično", calculateRatingByCount(5), "#56a545")}
            {generateProgress("Vrlo dobro", calculateRatingByCount(4), "#a9d616")}
            {generateProgress("Dobro", calculateRatingByCount(3), "#f4e502")}
            {generateProgress("Loše", calculateRatingByCount(2), "#f0a400")}
            {generateProgress("Veoma loše", calculateRatingByCount(1), "#e53700")}
          </Grid>
        </Grid>
      </SoftBox>

      <Divider sx={{ width: "100%", marginBottom: "40px", marginTop: "40px" }} />

      <SoftBox
        display="flex"
        justifyContent="center"
        flexDirection="column"
        maxWidth="900px"
        width="100%"
        gap="30px"
      >
        {reviewsResponse && reviewsResponse.content && reviewsResponse.content.length > 0 ? (
          reviewsResponse.content.map((review) => (
            <SoftBox key={review.customerName + review.product.id + review.createdAt} mx="1rem">
              <ReviewCard
                name={review.customerName}
                rating={Number(review.rating)}
                text={review.review}
                date={review.createdAt}
                images={review.images}
                product={review.product}
                onImageClick={handleImageClick}
              />
              <Divider sx={{ width: "100%" }} />
            </SoftBox>
          ))
        ) : reviewsResponseError || overallStatisticsError ? (
          <SoftTypography variant="body2" color="error" sx={{ textAlign: "center" }}>
            Greška prilikom učitavanja recenzija
          </SoftTypography>
        ) : (
          <SoftTypography variant="body2" sx={{ textAlign: "center" }}>
            Nema recenzija za prikaz
          </SoftTypography>
        )}
        {reviewsResponse && reviewsResponse.content && reviewsResponse.content.length > 0 && (
          <SoftPagination mr="1rem">{renderPagination()}</SoftPagination>
        )}
      </SoftBox>
      {selectedImage && renderImagePreview()}
    </PageLayout>
  );
};

Reviews.propTypes = {
  productIds: PropTypes.array,
  sx: PropTypes.object,
};

export default Reviews;
