import SoftBox from "components/SoftBox";
import miniBrand from "assets/images/logos/mini-logo-transparent-green.png";
import { Divider, Grid, Icon } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import typography from "assets/theme/base/typography";
import SoftButton from "components/SoftButton";
import breakpoints from "assets/theme/base/breakpoints";
import { useEffect, useState } from "react";
import config from "config/config";
import { socialMediaLinks } from "../social-follow-component";
import Link from "components/Link/Link";
import { HashLink } from "react-router-hash-link";

const servicesLinks = [
  {
    label: "Početna",
    href: "/",
  },
  {
    label: "Prodavnica",
    href: "/store",
  },
  {
    label: "Recenzije",
    href: "/reviews",
  },
  {
    label: "O meni",
    href: "/about",
  },
];

const socialLinks = [
  {
    label: "Instagram",
    href: config.socialMediaLinks.instagram,
  },
  {
    label: "Facebook",
    href: config.socialMediaLinks.facebook,
  },
  {
    label: "TikTok",
    href: config.socialMediaLinks.tiktok,
  },
];

const legalLinks = [
  {
    label: "Politika o kolačićima",
    href: "/cookies-policy",
  },
  {
    label: "Uslovi korišćenja",
    href: "/terms-and-conditions",
  },
  {
    label: "Politika privatnosti",
    href: "/privacy-policy",
  },
];

function Footer() {
  const { size } = typography;

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
    return () => window.removeEventListener("resize", () => setInnerWidth(window.innerWidth));
  }, []);

  const renderSocial = socialMediaLinks.map(({ label, link, icon }) => (
    <Link key={label + link} href={link} target="_blank" rel="noreferrer" fontSize={size.xl}>
      {icon}
    </Link>
  ));

  const renderLinks = (links, openInNewTab = false) =>
    links.map(({ label, href }) => (
      <Link
        key={label + href}
        href={href}
        fontSize={size.sm}
        pr={1}
        lineHeight={1}
        width="fit-content"
        target={openInNewTab ? "_blank" : "_self"}
      >
        {label}
      </Link>
    ));

  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      sx={{ backgroundColor: "#fafafa", px: { xs: "1rem", lg: "5rem" }, marginTop: "auto" }}
      py={5}
    >
      <SoftBox
        display="flex"
        justifyContent="space-between"
        mb={8}
        flexDirection={innerWidth < breakpoints.values.lg ? "column" : "row"}
        gap="3rem"
      >
        <SoftBox display="flex" alignItems="center" gap="1rem">
          <SoftBox component="img" src={miniBrand} alt="Mama za pet mini brand logo" width="5rem" />
          <SoftBox>
            <SoftTypography variant="h5">
              Recepture Mama za pet proizvoda stare su više od 20 godina
            </SoftTypography>
            <SoftTypography variant="body2" sx={{ fontStyle: "italic" }}>
              U svakom pakovanju čeka te zeolit i 12 biljnih eteričnih ulja koji pomažu kod akni,
              ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu.
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
          <SoftButton
            component={HashLink}
            to="/store"
            color="info"
            variant="gradient"
            sx={{ maxWidth: "20rem", minWidth: "12rem" }}
          >
            <Icon>shopping_bag</Icon>&nbsp; Posjeti prodavnicu
          </SoftButton>
        </SoftBox>
      </SoftBox>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} xl={3}>
          <SoftBox>
            <SoftTypography variant="h5" mb="1rem">
              Natalija Todorović
            </SoftTypography>
            <SoftBox display="flex" flexDirection="column" gap="0.3rem">
              <SoftBox display="flex">
                <SoftTypography variant="body2" sx={{ fontStyle: "italic", fontWeight: "bold" }}>
                  Email:&nbsp;&nbsp;
                </SoftTypography>
                <SoftTypography variant="body2">{config.contact.infoEmail}</SoftTypography>
              </SoftBox>
              <SoftBox display="flex">
                <SoftTypography variant="body2" sx={{ fontStyle: "italic", fontWeight: "bold" }}>
                  Telefon:&nbsp;&nbsp;
                </SoftTypography>
                <SoftTypography variant="body2">{config.contact.telephone}</SoftTypography>
              </SoftBox>
              <SoftBox display="flex" gap="0.5rem">
                {renderSocial}
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={6} sm={4} md={3} xl={2} sx={{ mt: { xs: "2rem", md: "0" } }}>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="h5" mb="1rem">
              Servisi
            </SoftTypography>
            <SoftBox display="flex" flexDirection="column" gap="1rem">
              {renderLinks(servicesLinks)}
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={6} sm={4} md={3} xl={2} sx={{ mt: { xs: "2rem", md: "0" } }}>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="h5" mb="1rem">
              Social
            </SoftTypography>
            <SoftBox display="flex" flexDirection="column" gap="1rem">
              {renderLinks(socialLinks, true)}
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={6} sm={4} md={2} xl={2} sx={{ mt: { xs: "1rem", sm: "2rem", md: "0" } }}>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="h5" mb="1rem">
              Legal
            </SoftTypography>
            <SoftBox display="flex" flexDirection="column" gap="1rem">
              {renderLinks(legalLinks)}
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
      <Divider sx={{ mt: "3rem", mb: "2rem" }} />
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={0}
        gap={2}
      >
        <SoftBox>
          <SoftTypography fontSize={size.sm}>
            &copy; {new Date().getFullYear()}, Mama za pet - Sva prava zadržana
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" gap="0.5rem">
          {renderSocial}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default Footer;
