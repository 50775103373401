const selectData = {
  country: [
    { value: "bih", label: "Bosna i Hercegovina" },
    { value: "srb", label: "Srbija" },
  ],
  products: [
    { value: "krema-protiv-bora", label: "Krema protiv bora" },
  ]
};

export default selectData;
