import CountryCell from "pages/user/checkout/checkout-form/country-cell";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

const selectData = {
  country: [
    {
      value: "srb", label: <CountryCell name="Srbija" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/serbia-48.png`} />
    },
    {
      value: "bih", label: <CountryCell name="Bosna i Hercegovina" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/bosnia-and-herzegovina-48.png`} />
    },
    {
      value: "me", label: <CountryCell name="Crna Gora" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/montenegro-48.png`} />
    },
    {
      value: "hr", label: <CountryCell name="Hrvatska" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/croatia-48.png`} />
    },
    {
      value: "si", label: <CountryCell name="Slovenija" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/slovenia-48.png`} />
    },
    {
      value: "mk", label: <CountryCell name="Severna Makedonija" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/macedonia-48.png`} />
    },
    {
      value: "de", label: <CountryCell name="Njemačka" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/germany-48.png`} />
    },
    {
      value: "fr", label: <CountryCell name="Francuska" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/france-48.png`} />
    },
    {
      value: "at", label: <CountryCell name="Austrija" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/austria-48.png`} />
    },
    {
      value: "it", label: <CountryCell name="Italija" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/italy-48.png`} />
    },
  ],
  products: [
    { value: "krema-protiv-bora", label: "Krema protiv bora" },
  ]
};

export default selectData;
