import React from "react";
import PropTypes from "prop-types";
import brandLogo from "assets/images/logos/mini-logo-transparent-green.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            textAlign: "center",
            fontFamily: "Roboto, sans-serif",
            backgroundColor: "#f9f9f9",
          }}
        >
          <img
            src={brandLogo}
            alt="Mama za pet logo"
            style={{ width: "120px", marginBottom: "1rem" }}
          />
          <h1 style={{ fontSize: "2rem", marginBottom: "0.5rem", color: "#333" }}>
            Oops! Nešto nije u redu.
          </h1>
          <p style={{ marginBottom: "2rem", color: "#666" }}>
            Došlo je do neočekivane greške. Molimo vas da pokušate ponovo ili nas kontaktirate ako
            se problem nastavi.
          </p>
          <div style={{ display: "flex", gap: "1rem" }}>
            <a href="/">Vrati se na početnu stranu</a>
            ili
            <a href="#" onClick={() => window.location.reload()}>
              Pokušaj ponovo
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
