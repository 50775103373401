import { Grid, Link } from "@mui/material";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

const ProductImage = ({ image, link }) => {
  if (link) {
    return (
      <Grid
        item
        lg={6}
        xs={12}
        sx={{ position: "relative" }}
        minHeight={{ xs: "23rem", sm: "30rem", lg: "35rem", xxl: "50rem" }}
      >
        <SoftBox
          component="img"
          src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/overlay/left.webp`}
          alt="Overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "50%", // Adjust size as needed
            zIndex: 2,
          }}
        />
        <SoftBox
          component="img"
          src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/overlay/right.webp`}
          alt="Overlay"
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "50%", // Adjust size as needed
            zIndex: 2,
          }}
        />
        <Link to={link} component={RouterLink}>
          <SoftBox
            component="img"
            src={image}
            alt="Proizvod"
            sx={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </Link>
      </Grid>
    );
  }

  return (
    <Grid
      item
      lg={6}
      xs={12}
      sx={{ position: "relative" }}
      minHeight={{ xs: "23rem", sm: "30rem", lg: "35rem", xxl: "50rem" }}
    >
      <SoftBox
        component="img"
        src={image}
        alt="Proizvod"
        sx={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Grid>
  );
};

ProductImage.propTypes = {
  image: PropTypes.string,
  link: PropTypes.string,
};

export default ProductImage;
