import checkout from "pages/admin/orders/components/create-new-order-form/schemas/formGlobal";

const {
  formField: {
    fullName,
    phoneNumber,
    streetAndNumber,
    city,
    postalCode,
    country,
    email
  },
} = checkout;

const initialValues = {
  [fullName.name]: "",
  [phoneNumber.name]: "",
  [streetAndNumber.name]: "",
  [city.name]: "",
  [postalCode.name]: "",
  [country.name]: "",
  [email.name]: "",
};

export default initialValues;
