import PropTypes from "prop-types";

import Checkbox from "@mui/material/Checkbox";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ProductCell({ image, name, checked, checkable = true }) {
  return (
    <SoftBox display="flex" alignItems="center">
      {checkable ? <Checkbox defaultChecked={checked} /> : null}
      <SoftBox width="3.75rem">
        <SoftBox
          component="img"
          src={image}
          alt={`Proizvod ${name}`}
          width="100%"
          borderRadius="20%"
        />
      </SoftBox>
      <SoftTypography ml="1rem" variant="button" fontWeight="medium">
        {name}
      </SoftTypography>
    </SoftBox>
  );
}

ProductCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  checkable: PropTypes.bool,
};

export default ProductCell;
