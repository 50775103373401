import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

function DefaultNavbarLink({
  name,
  openHandler,
  closeHandler,
  children,
  collapseStatus,
  light,
  route,
  labelTextTransform = "uppercase",
  disableRouting,
  ...rest
}) {
  return (
    <>
      <SoftBox
        {...rest}
        mx={1}
        p={1}
        onMouseEnter={openHandler || undefined}
        onMouseLeave={closeHandler || undefined}
        display="flex"
        alignItems="center"
        color={light ? "white" : "dark"}
        sx={{
          cursor: "pointer",
          userSelect: "none",
          ...rest.sx,
          width: "100%", // Ensures the clickable area spans the entire width
        }}
      >
        <NavLink
          onClick={disableRouting ? (event) => event.preventDefault() : undefined}
          to={route}
          className={({ isActive }) => {
            let baseClassName = "main-menu-nav-link";
            return baseClassName + (isActive ? " active" : "");
          }}
          style={{
            display: "flex", // Ensures the NavLink spans the container
            alignItems: "center",
            textDecoration: "none", // Removes default underline for links
            width: "100%", // Makes the NavLink cover the entire SoftBox
          }}
        >
          <SoftTypography
            variant="button"
            fontWeight="regular"
            textTransform={labelTextTransform}
            color="inherit"
            sx={{ fontWeight: "100%" }}
          >
            {name}
          </SoftTypography>
        </NavLink>
      </SoftBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  openHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  closeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  route: PropTypes.string,
  labelTextTransform: PropTypes.string,
  disableRouting: PropTypes.bool,
};

export default DefaultNavbarLink;
