import React from 'react';
import MuiLink from '@mui/material/Link';
import PropTypes from 'prop-types';

const Link = ({ children, sx, ...rest }) => {
  return (
    <MuiLink
      sx={{
        transition: 'all 0.1s',
        '&:hover': {
          color: 'secondary.main',
          textDecoration: 'underline',
          opacity: 0.8,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </MuiLink>
  );
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

export default Link;
