import React from 'react';
import { Box, Icon, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ProductBadge = ({ text, icon }) => {
  return (
    <Box display="flex" gap="0.3rem">
      <Icon sx={{ color: "#fff" }}>{icon}</Icon>
      <Typography sx={({ typography }) => ({
        fontSize: typography.size.sm,
        fontWeight: typography.fontWeightMedium,
        fontFamily: 'system-ui',
        color: "#fff"
      })}>
        {text}
      </Typography>
    </Box>
  );
};

ProductBadge.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
};

export default ProductBadge;