import Link from "components/Link/Link";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

const ProductDescription = ({
  title,
  subtitle,
  textArray,
  textVariant = "body2",
  titleLink,
  sx,
}) => (
  <SoftBox sx={sx}>
    <SoftBox display="flex" flexDirection="column" mb={2}>
      <SoftTypography
        variant="h3"
        fontSize={{ xs: "24px", sm: "26px", md: "32px" }}
        sx={{ color: "#000" }}
      >
        {titleLink !== undefined ? (
          <Link component={RouterLink} to={titleLink}>
            {title}
          </Link>
        ) : (
          title
        )}
      </SoftTypography>
      {subtitle && (
        <SoftTypography
          sx={{
            fontSize: "1rem",
            color: "black !important",
            fontStyle: "italic",
            fontWeight: "500",
          }}
        >
          {subtitle}
        </SoftTypography>
      )}
    </SoftBox>
    <SoftBox mb={2} display="flex" flexDirection="column" gap="1rem">
      {textArray.map((text, i) => (
        <SoftTypography
          variant={textVariant}
          fontSize={{ xs: "14px", sm: "16px" }}
          sx={{
            color: "#000",
            position: "relative",
            paddingLeft: "1rem",
            "&::before": {
              content: '"•"',
              position: "absolute",
              left: 0,
              color: "#000",
            },
          }}
          key={i}
        >
          {text}
        </SoftTypography>
      ))}
    </SoftBox>
  </SoftBox>
);

ProductDescription.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  textArray: PropTypes.array,
  textVariant: PropTypes.string,
  titleLink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProductDescription;
