/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "pages/user/checkout/checkout-form/schemas/formGlobal";

const {
  formField: {
    fullName,
    phoneNumber,
    streetAndNumber,
    city,
    postalCode,
    country,
    email
  },
} = checkout;

const initialValues = {
  [fullName.name]: "",
  [phoneNumber.name]: "",
  [streetAndNumber.name]: "",
  [city.name]: "",
  [postalCode.name]: "",
  [country.name]: "",
  [email.name]: "",
};

export default initialValues;
