import { useState } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";

import image1 from "assets/images/ecommerce/wooden-table.jpeg";

function ProductImages({ imageUrls = [] }) {
  const [currentImage, setCurrentImage] = useState(
    imageUrls && imageUrls.length > 0 ? imageUrls[0] : image1
  );
  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  return (
    <SoftBox>
      <SoftBox
        component="img"
        src={currentImage}
        alt="Slika proizvoda"
        borderRadius="lg"
        width="100%"
      />
      {imageUrls.length > 1 && (
        <SoftBox mt={2} pt={1}>
          <Grid container spacing={3}>
            {imageUrls.map((img, i) => (
              <Grid key={i} item xs={2}>
                <SoftBox
                  component="img"
                  id={i}
                  src={img}
                  alt="Mala slika proizvoda"
                  borderRadius="lg"
                  shadow="md"
                  width="100%"
                  sx={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
                  onClick={handleSetCurrentImage}
                />
              </Grid>
            ))}
          </Grid>
        </SoftBox>
      )}
    </SoftBox>
  );
}

ProductImages.propTypes = {
  imageUrls: PropTypes.array,
};

export default ProductImages;
