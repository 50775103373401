import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import moment from "moment";
import localization from "moment/locale/bs";

import { SoftUIControllerProvider } from "context";

import AdminApp from "app/AdminApp";
import UserApp from "app/UserApp";
import brand from "assets/images/logos/mini-logo-transparent-green.png";

import "index.css";
import TagManager from "react-gtm-module";
import ErrorBoundary from "app/components/error-boundary/ErrorBoundary";

const tagManagerArgs = {
  gtmId: "GTM-5PHXQQ2G",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
moment.locale("bs", localization);

// Function to extract query parameters
const getQueryParam = (param) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

// Check for "pristup" query parameter and set localStorage
if (getQueryParam("pristup") === "lozinka") {
  localStorage.setItem("pristup", "lozinka");
}

// Check subdomain
const getSubdomain = () => {
  const x = window.location.hostname.split(".")[0];
  return x === "localhost" ? "" : x;
};

// Under construction page
const UnderConstructionPage = () => {
  return (
    <div style={{ textAlign: "center", padding: "50px", fontFamily: "Roboto" }}>
      <img src={brand} alt="logo" style={{ width: "100px", height: "100px" }} />
      <h1>Mamazapet webshop je u fazi izrade</h1>
      <p>Očekujte nas uskoro!</p>
    </div>
  );
};

// Determine if the user has access
const hasAccessKey = localStorage.getItem("pristup") === "lozinka";

// Render application
root.render(
  hasAccessKey ? (
    <BrowserRouter>
      <SoftUIControllerProvider>
        <ErrorBoundary>{getSubdomain() === "platform" ? <AdminApp /> : <UserApp />}</ErrorBoundary>
      </SoftUIControllerProvider>
    </BrowserRouter>
  ) : (
    <UnderConstructionPage />
  )
);
