import { useMemo, useEffect, useState } from "react";

import PropTypes from "prop-types";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
} from "react-table";

import { useSoftUIController, clearSelectOrders } from "context";

import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import SearchIcon from "@mui/icons-material/Search";
import TableContainer from "@mui/material/TableContainer";
import FilterListIcon from "@mui/icons-material/FilterList";
import CircularProgress from "@mui/material/CircularProgress";
import { Close } from "@mui/icons-material";
import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Link,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftPagination from "components/SoftPagination";
import SoftDatePicker from "components/SoftDatePicker";

import ChipsArray from "pages/admin/orders/components/chips-array";
import ProductsDataTableHeadCell from "pages/admin/orders/components/order-table-head-cell";
import ProductsDataTableBodyCell from "pages/admin/orders/components/order-table-body-cell";
import { selectOrder } from "context";
import { selectOrders } from "context";
import Checkbox from "@mui/material/Checkbox";

function ProductsDataTable({
  entriesPerPage = { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch,
  showTotalEntries = true,
  table,
  pagination = { variant: "gradient", color: "info" },
  isSorted = true,
  noEndBorder,
  controlledPageCount = 1,
  columns = [],
  data = [],
  fetchData,
  loading,
  error,
  filterState,
  setFilterState,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  searchQuery,
  setSearchQuery,
  forceUpdate,
  setForceUpdate,
  totalElements = 0,
}) {
  const [controller, dispatch] = useSoftUIController();
  const { selectedOrders } = controller;

  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  // const entries = entriesPerPage.entries ? entriesPerPage.entries : [5, 10, 15, 20, 25];
  // const columns = useMemo(() => table.columns, [table]);
  // const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      pageCount: controlledPageCount,
      manualPagination: true,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter, filters },
  } = tableInstance;

  const [openFilterPanel, setOpenFilterPanel] = useState(false);

  const handleSetStartDate = (newDate) => setStartDate(newDate);
  const handleSetEndDate = (newDate) => setEndDate(newDate);

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  // const setEntriesPerPage = ({ value }) => setPageSize(value);

  // useEffect(() => {
  //   fetchData(pageIndex, pageSize, filterState, startDate, endDate);
  // }, [fetchData, pageIndex, pageSize, filterState, startDate, endDate])

  useEffect(() => {
    fetchData(pageIndex, pageSize, filterState, startDate, endDate, searchQuery);
  }, [forceUpdate, pageIndex, searchQuery]);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SoftPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setSearchQuery(value);
  }, 1000);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  // FILTERS
  const chipsData = useMemo(
    () =>
      Object.keys(filterState)
        .map((k) => filterState[k].map((x) => ({ ...x, group: k })))
        .flatMap((x) => x)
        .filter((x) => x.checked),
    [filterState]
  );

  const isChecked = (group, key) => filterState[group].find((i) => i.key === key).checked;

  const isAnyFilterActive = useMemo(() => {
    if (startDate || endDate) {
      return true;
    }
    for (const key of Object.keys(filterState)) {
      if (filterState[key].some((r) => r.checked)) {
        return true;
      }
    }
    return false;
  }, [filterState, startDate, endDate]);

  const handleFilterChange = (group, key) =>
    setFilterState((prevState) => ({
      ...prevState,
      [group]: prevState[group].map((x) => (x.key === key ? { ...x, checked: !x.checked } : x)),
    }));

  const handleOpenFilterPanel = () => {
    setOpenFilterPanel((prevState) => !prevState);
  };

  const handleDeleteChip = (data) => {
    handleFilterChange(data.group, data.key);
    setForceUpdate((prevState) => !prevState);
  };

  const onFilterSubmit = () => {
    fetchData(pageIndex, pageSize, filterState, startDate, endDate, searchQuery);
  };

  const deselectAll = () => {
    clearSelectOrders(dispatch);
  };
  const removeFilters = () => {
    const newFilterState = {};
    for (const key of Object.keys(filterState)) {
      newFilterState[key] = filterState[key].map((x) => ({ ...x, checked: false }));
    }
    setFilterState(newFilterState);
    setStartDate(null);
    setEndDate(null);
    setForceUpdate((prevState) => !prevState);
  };

  const deselectOrder = (orderId) => selectOrder(dispatch, { id: orderId });
  const handleSelectOrders = (orderIds) => selectOrders(dispatch, [...orderIds]);

  const handleSelectAllOrders = () => {
    fetchData(
      0,
      tableInstance.state.pageSize * tableInstance.pageCount,
      filterState,
      startDate,
      endDate,
      searchQuery,
      {
        handler: handleSelectOrders,
        currentPage: pageIndex,
      }
    );
  };

  return (
    <TableContainer sx={{ boxShadow: "none", borderRadius: 0 }}>
      {entriesPerPage || canSearch ? (
        <Grid container direction="column">
          <SoftBox
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
            p="1.5rem"
          >
            <SoftBox display="flex" alignItems="center" width="100%" gap="1rem">
              <SoftButton
                variant="text"
                color={!openFilterPanel ? "secondary" : "dark"}
                onClick={handleOpenFilterPanel}
                sx={{ px: 0 }}
              >
                <FilterListIcon />
                &nbsp;&nbsp;Filteri
              </SoftButton>
              {(chipsData.length > 0 || startDate || endDate) && (
                <SoftButton
                  variant="text"
                  color="black"
                  style={{ textTransform: "none" }}
                  onClick={removeFilters}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    lineHeight: "1rem",
                    "&:hover": {
                      transform: "none",
                      opacity: 0.5,
                    },
                  }}
                >
                  <Close /> Poništi filtere
                </SoftButton>
              )}
            </SoftBox>
            <SoftBox
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ChipsArray data={chipsData} handleDelete={handleDeleteChip} />
            </SoftBox>
          </SoftBox>
          <Collapse in={openFilterPanel} sx={{ width: "100%" }}>
            <SoftBox px="0.5rem" width="100%">
              <SoftBox
                display="flex"
                gap="2rem"
                my="1rem"
                width="100%"
                pl={{ xs: "1rem", md: "1rem" }}
                pb="1rem"
                sx={{ overflowX: "auto" }}
              >
                <SoftBox sx={{ minWidth: "12rem" }}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={({ typography: { size } }) => ({ fontSize: size.xs })}
                    >
                      Država
                    </FormLabel>
                    <FormGroup>
                      {filterState.country.map((f, i) => (
                        <FormControlLabel
                          sx={{ paddingLeft: "10px" }}
                          key={`country${i}`}
                          control={
                            <Checkbox
                              checked={isChecked("country", f.key)}
                              onChange={() => handleFilterChange("country", f.key)}
                              name={f.key}
                              size="small"
                            />
                          }
                          label={
                            <SoftTypography
                              sx={({ typography: { size, fontWeightMedium } }) => ({
                                fontSize: size.xs,
                                fontWeight: fontWeightMedium,
                              })}
                            >
                              {f.label}
                            </SoftTypography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </SoftBox>
                <SoftBox sx={{ minWidth: "11rem" }}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={({ typography: { size } }) => ({ fontSize: size.xs })}
                    >
                      Status narudžbe
                    </FormLabel>
                    <FormGroup>
                      {filterState.shipmentStatus.map((f, i) => (
                        <FormControlLabel
                          sx={{ paddingLeft: "10px" }}
                          key={`shipmentStatus${i}`}
                          control={
                            <Checkbox
                              checked={isChecked("shipmentStatus", f.key)}
                              onChange={() => handleFilterChange("shipmentStatus", f.key)}
                              name={f.key}
                            />
                          }
                          label={
                            <SoftTypography
                              sx={({ typography: { size, fontWeightMedium } }) => ({
                                fontSize: size.xs,
                                fontWeight: fontWeightMedium,
                              })}
                            >
                              {f.label}
                            </SoftTypography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </SoftBox>
                <SoftBox sx={{ minWidth: "8rem" }}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={({ typography: { size } }) => ({ fontSize: size.xs })}
                    >
                      Status plaćanja
                    </FormLabel>
                    <FormGroup>
                      {filterState.paymentStatus.map((f, i) => (
                        <FormControlLabel
                          sx={{ paddingLeft: "10px" }}
                          key={`paymentStatus${i}`}
                          control={
                            <Checkbox
                              checked={isChecked("paymentStatus", f.key)}
                              onChange={() => handleFilterChange("paymentStatus", f.key)}
                              name={f.key}
                            />
                          }
                          label={
                            <SoftTypography
                              sx={({ typography: { size, fontWeightMedium } }) => ({
                                fontSize: size.xs,
                                fontWeight: fontWeightMedium,
                              })}
                            >
                              {f.label}
                            </SoftTypography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </SoftBox>
                <SoftBox display="flex" flexDirection="column" sx={{ minWidth: "8rem" }}>
                  <FormControl component="fieldset" variant="standard">
                    <FormLabel
                      component="legend"
                      sx={({ typography: { size } }) => ({ fontSize: size.xs })}
                    >
                      Interval
                    </FormLabel>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                      py={1}
                    >
                      <SoftDatePicker
                        value={startDate}
                        onChange={handleSetStartDate}
                        input={{ placeholder: "Startni datum" }}
                        options={{
                          enableTime: true,
                          dateFormat: "d-M-Y H:i",
                          time_24hr: true,
                          defaultDate: Date.now(),
                          defaultHour: 0,
                          defaultMinute: 0,
                        }}
                      />
                    </SoftBox>
                  </FormControl>
                  <FormControl component="fieldset" variant="standard">
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftDatePicker
                        value={endDate}
                        onChange={handleSetEndDate}
                        input={{ placeholder: "Završni datum" }}
                        options={{
                          enableTime: true,
                          dateFormat: "d-M-Y H:i",
                          time_24hr: true,
                          defaultDate: Date.now(),
                          defaultHour: new Date().getHours(),
                          defaultMinute: new Date().getMinutes(),
                        }}
                      />
                    </SoftBox>
                  </FormControl>
                </SoftBox>
                <SoftBox display={{ xs: "none", md: "block" }}>
                  <SoftButton
                    variant="gradient"
                    color="success"
                    disabled={loading || !isAnyFilterActive}
                    onClick={onFilterSubmit}
                  >
                    <SoftBox display="flex" alignItems="center">
                      {loading ? (
                        <CircularProgress color="white" size="1rem" />
                      ) : (
                        <>
                          <SearchIcon color="white" />
                          <SoftTypography color="white" variant="caption" fontWeight="bold">
                            &nbsp;&nbsp;PRETRAŽI
                          </SoftTypography>
                        </>
                      )}
                    </SoftBox>
                  </SoftButton>
                </SoftBox>
              </SoftBox>
              <SoftBox
                display={{ xs: "flex", md: "none" }}
                pb={{ xs: "1rem", md: "0" }}
                justifyContent="flex-end"
              >
                <SoftButton
                  variant="gradient"
                  color="success"
                  disabled={loading || !isAnyFilterActive}
                  onClick={onFilterSubmit}
                >
                  <SoftBox display="flex">
                    {loading ? (
                      <CircularProgress color="white" size="1rem" />
                    ) : (
                      <>
                        <SearchIcon color="white" />
                        <SoftTypography color="white" variant="caption" fontWeight="bold">
                          &nbsp;&nbsp;PRETRAŽI
                        </SoftTypography>
                      </>
                    )}
                  </SoftBox>
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </Collapse>
          <SoftBox
            mx="1rem"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="1rem"
          >
            {canSearch && (
              <SoftBox width="100%" display={{ xs: "flex", md: "none" }}>
                <SoftInput
                  placeholder="Pretraži..."
                  value={search}
                  onChange={({ currentTarget }) => {
                    setSearch(search);
                    onSearchChange(currentTarget.value);
                  }}
                />
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
      ) : null}
      <SoftBox>
        <SoftBox
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          pl="2.25rem"
          pr="1.5rem"
          mb="0.5rem"
          pt={{ xs: "1rem", md: "0" }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedOrders.length > 0}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleSelectOrders(page.map((row) => row.values.id));
                  } else {
                    deselectAll();
                  }
                }}
              />
            }
            label={
              <SoftTypography variant="caption" fontSize="0.875rem">
                {selectedOrders.length > 0
                  ? `Selektovanih narudžbi: ${selectedOrders.length}`
                  : "Nema selektovanih narudžbi"}
              </SoftTypography>
            }
          />
          {canSearch && (
            <SoftBox width="20rem" ml="auto" display={{ xs: "none", md: "block" }}>
              <SoftInput
                placeholder="Pretraži..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </SoftBox>
          )}
        </SoftBox>
        <Collapse
          in={
            page.map((visibleRow) => visibleRow.values.id).length > 0 &&
            page
              .map((visibleRow) => visibleRow.values.id)
              .every((visibleOrderId) => selectedOrders.includes(visibleOrderId)) &&
            tableInstance.pageCount > 1
          }
        >
          <SoftBox
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "1rem",
              borderTop: "1px solid #e4e4e4",
              borderBottom: "1px solid #e4e4e4",
              backgroundColor: "#f8f8f8",
            }}
          >
            <SoftTypography variant="body2">
              Sve narudžbe na ovoj stranici su selektovane.{" "}
              <Link
                href="#"
                sx={{
                  color: "#30b5a7",
                }}
                unerline="hover"
                onClick={(e) => {
                  e.preventDefault();
                  handleSelectAllOrders();
                }}
              >
                Selektuj narudžbe sa svih stranica.
              </Link>
            </SoftTypography>
          </SoftBox>
        </Collapse>
      </SoftBox>

      <SoftBox sx={{ overflowX: "auto", mt: "0.5rem" }}>
        <Table {...getTableProps()}>
          <SoftBox component="thead">
            {headerGroups.map((headerGroup, key) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={key}>
                {headerGroup.headers.map((column, key) => (
                  <ProductsDataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    key={key}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    // sorted={setSortedValue(column)}
                    sorted={false}
                  >
                    {column.render("Header")}
                  </ProductsDataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </SoftBox>
          <TableBody {...getTableBodyProps()}>
            {!loading &&
              page.map((row, key) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()} key={key}>
                    {row.cells.map((cell, key) => (
                      <ProductsDataTableBodyCell
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        {...cell.getCellProps()}
                        key={key}
                      >
                        {cell.render("Cell")}
                      </ProductsDataTableBodyCell>
                    ))}
                  </TableRow>
                );
              })}
            {loading && (
              <TableRow key={"loading-cell"}>
                <SoftBox component="td" rowSpan={1000} colSpan={1000} textAlign="center">
                  <SoftTypography sx={{ my: "1rem" }} variant="body2">
                    Podaci se ucitavaju...
                  </SoftTypography>
                  <CircularProgress color="success" sx={{ my: "1rem" }} />
                </SoftBox>
              </TableRow>
            )}
            {!loading && error && (
              <TableRow key={"loading-cell"}>
                <SoftBox component="td" rowSpan={1000} colSpan={1000} textAlign="center">
                  <SoftTypography sx={{ my: "1rem" }} variant="body2" color="error">
                    Došlo je do greške prilikom učitavanja podataka
                  </SoftTypography>
                </SoftBox>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </SoftBox>

      <SoftBox
        display="flex"
        flexDirection={{ xs: "row", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        py={!showTotalEntries && pageOptions.length === 1 ? 0 : "3rem"}
        px={{ xs: 0, sm: "2rem" }}
      >
        <SoftTypography variant="button" color="secondary" fontWeight="regular">
          Ukupan broj narudžbi: {totalElements}
        </SoftTypography>
        {showTotalEntries && (
          <SoftBox mb={{ xs: 3, sm: 0 }}>
            {/* <SoftTypography variant="button" color="secondary" fontWeight="regular">
              Prikazan opseg {entriesStart} do {entriesEnd} od ukupno {rows.length} narudžbi
            </SoftTypography> */}
            {/* <SoftTypography variant="button" color="secondary" fontWeight="regular">
              Ukupno {rows.length} narudžbi
            </SoftTypography> */}
          </SoftBox>
        )}
        {pageOptions.length > 1 && (
          <SoftPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <SoftPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </SoftPagination>
            )}
            {renderPagination.length > 6 ? (
              <SoftBox width="5rem" mx={1}>
                <SoftInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </SoftBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <SoftPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </SoftPagination>
            )}
          </SoftPagination>
        )}
      </SoftBox>
    </TableContainer>
  );
}

// Typechecking props for the ProductsDataTable
ProductsDataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  controlledPageCount: PropTypes.number,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  filterState: PropTypes.object,
  setFilterState: PropTypes.func,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
  forceUpdate: PropTypes.bool,
  setForceUpdate: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  totalElements: PropTypes.number,
};

export default ProductsDataTable;
