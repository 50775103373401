const form = {
  formId: "new-order-form",
  formField: {
    fullName: {
      name: "fullName",
      label: "Ime i prezime",
      type: "text",
      placeholder: "Unesite ime i prezime",
      errorMsg: "Ime i prezime je obavezno.",
    },
    phoneNumber: {
      name: "phoneNumber",
      label: "Broj telefona",
      type: "text",
      placeholder: "Format: +38765000111",
      errorMsg: "Telefon je obavezan.",
    },
    streetAndNumber: {
      name: "streetAndNumber",
      label: "Ulica i broj",
      type: "text",
      placeholder: "Unesite ulicu i broj",
      errorMsg: "Ulica i broj su obavezni.",
    },
    city: {
      name: "city",
      label: "Grad",
      type: "text",
      placeholder: "Unesite grad",
      errorMsg: "Grad je obavezan.",
    },
    postalCode: {
      name: "postalCode",
      label: "Poštanski broj",
      type: "text",
      placeholder: "Unesite poštanski broj",
      errorMsg: "Poštanski broj je obavezan.",
    },
    country: {
      name: "country",
      label: "Država",
      type: "text",
      placeholder: "Izaberite državu",
      errorMsg: "Država je obavezna.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "Unesite email",
      errorMsg: "Email je obavezan.",
    },
    notes: {
      name: "notes",
      label: "Napomena",
      type: "textarea",
      placeholder: "Unesite napomenu",
    },
  },
};

export default form;
