const form = {
  formId: "add-product-to-order-form",
  formField: {
    product: {
      name: "product",
      label: "Proizvod",
      type: "text",
      placeholder: "Izaberite proizvod",
      errorMsg: "Proizvod je obavezan.",
    },
    quantity: {
      name: "quantity",
      label: "Količina",
      type: "number",
      placeholder: "Količina",
      errorMsg: "Količina je obavezna.",
    }
  },
};

export default form;
