import React from "react";
import SoftPagination from "components/SoftPagination";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const Pagination = ({ totalPages = 1, currentPage = 0, onPageChange }) => {
  const maxButtons = 5; // Including the first, last, and current pages
  let startPage, endPage;

  if (totalPages <= maxButtons) {
    // Show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // Calculate range of pages to show
    const pagesBeforeCurrentPage = Math.floor(maxButtons / 2);
    const pagesAfterCurrentPage = Math.ceil(maxButtons / 2) - 1;
    if (currentPage + 1 <= pagesBeforeCurrentPage) {
      // Near the start
      startPage = 1;
      endPage = maxButtons;
    } else if (currentPage + 1 + pagesAfterCurrentPage >= totalPages) {
      // Near the end
      startPage = totalPages - maxButtons + 1;
      endPage = totalPages;
    } else {
      // Somewhere in the middle
      startPage = currentPage + 1 - pagesBeforeCurrentPage;
      endPage = currentPage + 1 + pagesAfterCurrentPage;
    }
  }

  const pagination = [];
  // Always add the first page
  pagination.push(
    <SoftPagination item key={1} onClick={() => onPageChange(0)} active={currentPage === 0}>
      1
    </SoftPagination>
  );

  // Add "..." before current range if necessary
  if (startPage > 2) {
    pagination.push(<SoftTypography key="start-ellipsis">...</SoftTypography>);
  }

  // Add the current range of pages
  for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
    if (pageNumber - 1 !== 0 && pageNumber - 1 !== totalPages - 1) {
      // Avoid duplicating first and last pages
      pagination.push(
        <SoftPagination
          item
          key={pageNumber}
          onClick={() => onPageChange(pageNumber - 1)}
          active={currentPage + 1 === pageNumber}
        >
          {pageNumber}
        </SoftPagination>
      );
    }
  }

  // Add "..." after current range if necessary
  if (endPage < totalPages - 1) {
    pagination.push(<SoftTypography key="end-ellipsis">...</SoftTypography>);
  }

  // Always add the last page
  if (totalPages !== 1) {
    // Avoid duplicating if only one page exists
    pagination.push(
      <SoftPagination
        item
        key={totalPages}
        onClick={() => onPageChange(totalPages - 1)}
        active={currentPage + 1 === totalPages}
      >
        {totalPages}
      </SoftPagination>
    );
  }

  return <>{pagination}</>;
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
