import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const CoverText = ({
  content,
  variant = "h2",
  title,
  titleVariant = "h4",
  description,
  descriptionVariant = "body2",
  component,
  hideDescription,
  ...rest
}) => {
  return (
    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" gap="1rem" {...rest}>
      <SoftTypography variant={titleVariant} sx={{ color: "#000", fontWeight: "600" }}>
        {title}
      </SoftTypography>
      <SoftTypography
        variant={descriptionVariant}
        sx={{ color: "#000", mb: "1rem", display: `${hideDescription ? "none" : "flex"}` }}
      >
        {description}
      </SoftTypography>
      {component}
    </SoftBox>
  );
};

CoverText.propTypes = {
  content: PropTypes.array,
  variant: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.string,
  component: PropTypes.node,
  hideDescription: PropTypes.bool,
  titleVariant: PropTypes.string,
  descriptionVariant: PropTypes.string,
};

export default CoverText;
