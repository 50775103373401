import { Rating } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "pages/user/checkout/checkout-form/form-field";
import PropTypes from "prop-types";
import { useState } from "react";
import ImageFilePicker from "./image-file-picker";

const ProductReviewItem = ({
  productId,
  name,
  unitQuantity = "30ml",
  img,
  values,
  errors,
  touched,
  isSubmitting,
  ratingFieldName,
  reviewFieldName,
  setValues,
  setImages,
}) => {
  const [rating, setRating] = useState(0);

  return (
    <SoftBox display="flex" gap="1rem">
      <SoftBox>
        <SoftBox
          component="img"
          src={img}
          alt={`Proizvod ${name}`}
          width={{ xs: "4rem", md: "12.7rem" }}
          borderRadius="lg"
        />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column" gap="0.7rem" width="100%">
        <SoftBox display="flex" justifyContent="space-between" alignItems="start">
          <SoftBox display="flex" flexDirection="column">
            <SoftBox
              display="flex"
              alignItems="baseline"
              flexDirection={{ xs: "column", md: "row" }}
            >
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.md,
                  fontWeight: typography.fontWeightBold,
                })}
              >
                {name}&nbsp;&nbsp;
              </SoftTypography>
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.sm,
                })}
              >
                {unitQuantity}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" flexDirection="column" justifyContent="space-between">
          <SoftBox display="flex" flexDirection="column" gap="0.7rem">
            <SoftBox ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Ocjena *
              </SoftTypography>
            </SoftBox>
            <Rating
              name="simple-controlled"
              value={rating}
              sx={{
                width: "fit-content",
              }}
              onChange={(event, newValue) => {
                setRating(newValue);
                setValues({ ...values, [ratingFieldName]: newValue });
              }}
            />
          </SoftBox>
          <FormField
            multiline
            rows={3}
            // minRows={3}
            // maxRows={5}
            label={"Recenzija *"}
            name={reviewFieldName}
            placeholder={"Unesite recenziju"}
            value={values[reviewFieldName]}
            // onChange={handleChange}
            // onBlur={handleBlur}
          />
          <ImageFilePicker productId={productId} setImages={setImages} />
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
};

ProductReviewItem.propTypes = {
  name: PropTypes.string,
  unitQuantity: PropTypes.string,
  img: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isSubmitting: PropTypes.bool,
  ratingFieldName: PropTypes.string,
  reviewFieldName: PropTypes.string,
  setValues: PropTypes.func,
  setImages: PropTypes.func,
  productId: PropTypes.string.isRequired,
};

export default ProductReviewItem;
