import { Grid, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import ProductDescription from "pages/user/product-pages/components/product-description";
import ProductPriceDetails from "pages/user/components/product-price-details";
import ProductSet from "./product-set";
import React, { useState } from "react";
import { useSoftUIController } from "context";
import PropTypes from "prop-types";
import ProductGallery from "../product-gallery/ProductGallery";
import PrimaryQuantityCounter from "../primary-quantity-counter/PrimaryQuantityCounter";

const ProductDetails = ({
  product,
  onAddToCart,
  productImages,
  descriptionContentArray,
  imagePosition = "left",
  descriptionContentComponent,
  onQuantityChange,
  maxQuantity,
  sets,
}) => {
  const [controller] = useSoftUIController();
  const { selectedCurrency } = controller;

  const [quantity, setQuantity] = useState(1);

  const handleIncreaseQuantity = () => {
    onQuantityChange && onQuantityChange(quantity + 1);
    setQuantity((prev) => prev + 1);
  };
  const handleDecreaseQuantity = () => {
    onQuantityChange && onQuantityChange(quantity - 1);
    setQuantity((prev) => Math.max(prev - 1, 1));
  };

  const getSetContents = (set) => {
    return (
      "Sadrži: " +
      set.compositeProductItems
        .map((item, i) => {
          const quantityText = item.quantity !== 1 ? `${item.quantity} x ` : "";

          const itemText = `${quantityText}${item.itemProduct.name}`;

          const comma = i < set.compositeProductItems.length - 1 ? ", " : "";

          return itemText + comma;
        })
        .join("")
    );
  };

  return (
    <Grid container>
      {imagePosition === "left" && (
        <Grid item lg={6} xs={12}>
          <ProductGallery images={productImages} />
        </Grid>
      )}

      {descriptionContentComponent ? (
        <Grid item lg={6} xs={12} sx={{ display: "flex", alignItems: "flex-start" }}>
          {descriptionContentComponent}
        </Grid>
      ) : (
        <Grid
          item
          lg={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
          px={{ xs: "1rem", lg: "3rem" }}
          pt={"2rem"}
          // mt="-120px"
        >
          <ProductDescription
            title={product.name}
            subtitle={product.type === "COMPOSITE" ? getSetContents(product) : undefined}
            textVariant="body"
            textArray={descriptionContentArray}
            sx={{ mb: "1rem" }}
          />
          {product && Object.keys(product).length > 0 && (
            <ProductPriceDetails product={product} selectedCurrency={selectedCurrency?.value} />
          )}
          <SoftBox display="flex" alignItems="center" mt="1rem" gap="0.5rem">
            <PrimaryQuantityCounter
              value={quantity}
              maxValue={maxQuantity}
              onIncrease={handleIncreaseQuantity}
              onDecrease={handleDecreaseQuantity}
            />
            <SoftButton
              disabled={quantity > maxQuantity}
              variant="contained"
              color="success"
              onClick={() => onAddToCart({ ...product, quantity })}
            >
              <Icon sx={{ marginRight: "0.5rem" }}>add_shopping_cart</Icon>
              Dodaj u korpu
            </SoftButton>
          </SoftBox>

          {/* Display Product Sets */}
          {sets && sets.length > 0 && (
            <ProductSet sets={sets} selectedCurrency={selectedCurrency?.value} />
          )}
        </Grid>
      )}

      {imagePosition === "right" && (
        <Grid item lg={6} xs={12}>
          <ProductGallery images={productImages} />
        </Grid>
      )}
    </Grid>
  );
};

ProductDetails.propTypes = {
  imagePosition: PropTypes.oneOf(["left", "right"]).isRequired,
  descriptionContentComponent: PropTypes.element,
  onQuantityChange: PropTypes.func,
  product: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  productImages: PropTypes.array.isRequired,
  descriptionContentArray: PropTypes.array,
  sets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  maxQuantity: PropTypes.number,
};

export default ProductDetails;
