import React from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

const Signature = ({ sx }) => (
  <SoftBox display="flex" justifyContent="space-between" px="2rem" py="2rem" sx={sx}>
    <SoftBox></SoftBox>
    <SoftBox>
      <SoftBox
        component="img"
        src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/potpis.png`}
        alt="Natalija Todorovic potpis"
        sx={{ height: "2.4rem", px: "0.4rem" }}
      />
    </SoftBox>
    <SoftBox></SoftBox>
  </SoftBox>
);

Signature.propTypes = {
  /** Custom styles applied to the root container via the `sx` prop */
  sx: PropTypes.object,
};

export default Signature;
