import React from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import brand from "assets/images/logos/mini-logo-transparent-green.png";
import config from "config/config";
import TikTokIcon from "../tiktok-icon";
import Link from "components/Link/Link";

export const socialMediaLinks = [
  {
    label: "mamazapet",
    link: config.socialMediaLinks.instagram,
    icon: <InstagramIcon />,
  },
  {
    label: "mamazapet",
    link: config.socialMediaLinks.facebook,
    icon: <FacebookIcon />,
  },
  {
    label: "mamazapet",
    link: config.socialMediaLinks.tiktok,
    icon: <TikTokIcon color="#344767" fontSize="1.25rem" />,
  },
];

const SocialFollowComponent = ({ sx, onlyIcons }) => {
  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="2rem"
      sx={sx}
    >
      {!onlyIcons && (
        <>
          <SoftBox
            component="img"
            src={brand}
            alt="Mama za pet brand logo"
            sx={{ height: "4rem" }}
          />
          <SoftTypography variant="h3" sx={{ color: "#000", textAlign: "center" }}>
            Zapratite me na društvenim mrežama
          </SoftTypography>
        </>
      )}
      <SoftBox
        display="flex"
        sx={{ gap: { xs: "1.5rem", sm: "2rem", md: "3rem" } }}
        flexDirection={{ xs: "column", md: "row" }}
      >
        {socialMediaLinks.map(({ label, link, icon }) => (
          <Link href={link} key={link}>
            <SoftBox display="flex" alignItems="center" gap={1}>
              {icon}
              <SoftTypography sx={{ fontSize: 16, fontWeight: "500" }}>{label}</SoftTypography>
            </SoftBox>
          </Link>
        ))}
      </SoftBox>
    </SoftBox>
  );
};

SocialFollowComponent.propTypes = {
  sx: PropTypes.object,
  onlyIcons: PropTypes.bool,
};

export default SocialFollowComponent;
