import React from 'react';
import { Grid, Skeleton } from "@mui/material";
import SoftBox from "components/SoftBox";

const ProductSkeleton = () => {
  return (
    <Grid item xs={12} lg={6} xxl={4}>
      <SoftBox display="flex" flexDirection="column" position="relative">
        <Skeleton variant="rectangular" width="100%" height={400} />

        <SoftBox display="flex" justifyContent="space-between" px="1rem" pt="1rem">
          <SoftBox display="flex" flexDirection="column" flex={1}>
            <Skeleton variant="text" width="60%" height={30} />
            <Skeleton variant="text" width="40%" height={20} />
            <Skeleton variant="text" width="100%" height={20} sx={{ mt: "0.8rem" }} />

            <SoftBox display="flex" gap={{ xs: "0.5rem", md: "1rem" }} flexDirection={{ xs: "row", xxl: "row" }} flexWrap="wrap">
              <Skeleton variant="rectangular" width={100} height={30} />
              <Skeleton variant="rectangular" width={100} height={30} />
            </SoftBox>

            <SoftBox display="flex" alignItems="start" pt="1rem">
              <Skeleton variant="rectangular" width={150} height={40} />
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Grid>
  );
};

export default ProductSkeleton;
