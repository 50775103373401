import { useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { shipmentStatusSelectData } from "pages/admin/order-details/data/selectData";
import { SERVER_URL_PREFIX } from "api";
import { post } from "api";

function ImportOrdersForm({ handleClose, setForceUpdate }) {
  const [shipmentStatus, setShipmentStatus] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const changeShipmentStatus = (value) => {
    setShipmentStatus(value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const upload = () => {
    if (selectedFile) {
      setIsUploading(true);
      setUploadError(false);

      const formData = new FormData();
      formData.append("file", selectedFile);
      const url = `${SERVER_URL_PREFIX}/api/admin/import?${
        shipmentStatus ? "status=" + shipmentStatus.value : ""
      }`;

      post(url, formData, {
        headers: {},
        body: formData,
      })
        .then(async (response) => {
          if (response.ok) {
            setIsUploading(false);
            handleClose();
            setForceUpdate((prev) => !prev);

            Swal.fire({
              title: "Narudžbe ažurirane!",
              text: "Dokument sa narudžbama je uspješno obrađen.",
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            throw new Error("Upload failed");
          }
        })
        .catch((error) => {
          setIsUploading(false);
          setUploadError(true);

          Swal.fire({
            title: "Greška!",
            text: "Desila se greška pri obradi dokumenta sa narudžbama.",
            icon: "error",
            confirmButtonText: "OK",
          });

          console.error("Error uploading file:", error);
        });
    }
  };

  return (
    <SoftBox p="1rem">
      <SoftTypography variant="h5">Unos narudžbi</SoftTypography>
      <SoftBox mt={3} display="flex" flexDirection="column" gap="2rem">
        <SoftBox maxWidth="12rem" minWidth="100%">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Status narudžbe
          </SoftTypography>
          <SoftSelect
            caption={"Status narudžbe"}
            value={shipmentStatus}
            options={shipmentStatusSelectData}
            onChange={changeShipmentStatus}
            fullWidth
          />
        </SoftBox>
        <SoftBox sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Izaberite izgenerisani dokument sa narudžbama
            </SoftTypography>
          </SoftBox>
          <input
            type="file"
            accept=".txt"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-input"
          />
          <label htmlFor="file-input" style={{ width: "100%" }}>
            <SoftButton component="span" color="primary" fullWidth>
              {selectedFile ? selectedFile.name : "Izaberite fajl"}
            </SoftButton>
          </label>
        </SoftBox>

        {uploadError && (
          <SoftBox display="flex" justifyContent="center" mt={2}>
            <SoftTypography color="error" variant="body2">
              Greška prilikom slanja fajla.
            </SoftTypography>
          </SoftBox>
        )}

        <SoftBox display="flex" justifyContent="flex-end" alignItems="center" mt={2} gap="0.5rem">
          <SoftButton color="dark" variant="outlined" onClick={handleClose} disabled={isUploading}>
            Zatvori
          </SoftButton>
          <SoftButton
            color="success"
            onClick={upload}
            disabled={!shipmentStatus || !selectedFile || isUploading}
          >
            {isUploading && <CircularProgress size={20} color="inherit" />}
            &nbsp;&nbsp;Potvrdi
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

ImportOrdersForm.propTypes = {
  handleClose: PropTypes.func,
  setForceUpdate: PropTypes.func,
};

export default ImportOrdersForm;
