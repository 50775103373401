import { badgeClasses, Grid, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { useSoftUIController, setOpenConfigurator } from "context";
import Link from "components/Link/Link";
import { mapProductNameToFrontPageURI } from "pages/user/home";
import { formatPrice } from "utils/formatPrice";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

const Product = ({
  id,
  title,
  type = "UNIT",
  compositeProductItems,
  price,
  currency,
  image,
  quantity,
  onAddToCart,
  specialOffers,
  onlyInfo,
  lazyLoading,
  isProductMaxCartQuantityPerItemReached,
  description,
  descriptionVariant = "caption",
  badgeText = "AKCIJA",
  badgeTextColor = "#fff",
  badgeColor = "#A9ABAD",
  badgeIcon = "star",
  showBadge,
  priceBeforeSale,
}) => {
  const [_, dispatch] = useSoftUIController();

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  const handleAdd = (product) => () => {
    setTimeout(handleConfiguratorOpen, 300);
    onAddToCart(product);
  };

  return (
    <Grid item xs={12} lg={6} xxl={4}>
      <SoftBox display="flex" flexDirection="column" position="relative">
        {!onlyInfo && showBadge && (
          <SoftBox
            display="flex"
            flexDirection="column"
            position="absolute"
            sx={{
              backgroundColor: badgeColor,
              top: "0.5rem",
              left: "0.5rem",
              px: "0.7rem",
              py: "0.4rem",
              border: `1px solid ${badgeColor}`,
              borderRadius: "0.5rem",
            }}
          >
            <SoftBox display="flex" gap="0.3rem">
              <Icon sx={{ color: badgeTextColor }}>{badgeIcon}</Icon>
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.sm,
                  fontWeight: typography.fontWeightMedium,
                  fontFamily: "system-ui",
                  color: badgeTextColor,
                })}
              >
                {badgeText}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        )}
        <SoftBox position="relative" sx={{ minHeight: "23rem" }}>
          {/* Left Overlay */}
          <SoftBox
            component="img"
            src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/overlay/left.webp`}
            alt="Overlay"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "30%",
              zIndex: 2,
            }}
          />
          {/* Right Overlay */}
          <SoftBox
            component="img"
            src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/overlay/right.webp`}
            alt="Overlay"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              width: "30%",
              zIndex: 2,
            }}
          />
          <RouterLink to={mapProductNameToFrontPageURI(title)}>
            <SoftBox
              component="img"
              loading={lazyLoading ? "lazy" : "eager"}
              src={image}
              alt={`Proizvod ${title}`}
              sx={{
                cursor: "pointer",
                width: "100%",
                objectFit: "cover",
              }}
            />
          </RouterLink>
        </SoftBox>
        <SoftBox display="flex" justifyContent="space-between" px="1rem" pt="1rem">
          {!onlyInfo && (
            <>
              <SoftBox display="flex" flexDirection="column">
                <SoftBox display="flex" flexDirection="column">
                  <Link
                    component={RouterLink}
                    to={mapProductNameToFrontPageURI(title)}
                    sx={{
                      width: "fit-content",
                    }}
                  >
                    <SoftTypography
                      sx={({ typography }) => ({
                        fontSize: typography.size.xl,
                        fontWeight: typography.fontWeightMedium,
                        color: "#000",
                      })}
                    >
                      {title}&nbsp;&nbsp;
                    </SoftTypography>
                  </Link>

                  {type === "UNIT" ? (
                    <SoftTypography
                      sx={({ typography }) => ({
                        fontSize: typography.size.sm,
                      })}
                    >
                      Količina: &nbsp;<i>{quantity}</i>
                    </SoftTypography>
                  ) : (
                    <SoftTypography
                      sx={({ typography }) => ({
                        fontSize: typography.size.sm,
                      })}
                    >
                      Sadrži: &nbsp;
                      {compositeProductItems
                        .sort((a, b) => a.id - b.id)
                        .map((item, i) => (
                          <i key={i}>
                            <span>
                              {item.quantity !== 1 ? item.quantity + " x " : ""}
                              {item.itemProduct.name}
                              {i < compositeProductItems.length - 1 && ", "}
                            </span>
                          </i>
                        ))}
                    </SoftTypography>
                  )}
                </SoftBox>
                {description && (
                  <SoftTypography
                    variant={descriptionVariant}
                    fontSize={{ xs: "14px", sm: "14px" }}
                    sx={{ color: "#000", mt: "1rem" }}
                  >
                    {description}
                  </SoftTypography>
                )}
                {priceBeforeSale ? (
                  <SoftBox display="flex" alignItems="center" mt="0.8rem">
                    <Icon sx={{ color: "#7eddd3", fontSize: "30px !important" }}>star</Icon>
                    <SoftTypography
                      sx={({ typography }) => ({
                        fontSize: typography.size.md,
                        color: "#000",
                        textDecoration: "line-through",
                      })}
                    >
                      {formatPrice(Number(priceBeforeSale), currency.label)}
                    </SoftTypography>
                    <SoftTypography
                      sx={({ typography }) => ({
                        fontSize: typography.size.lg,
                        fontWeight: typography.fontWeightMedium,
                        color: "#000",
                        pl: "0.5rem",
                      })}
                    >
                      {formatPrice(Number(price), currency.label)}
                    </SoftTypography>
                  </SoftBox>
                ) : (
                  <SoftTypography
                    sx={({ typography }) => ({
                      fontSize: typography.size.lg,
                      fontWeight: typography.fontWeightMedium,
                      color: "#000",
                      mt: "0.8rem",
                    })}
                  >
                    {formatPrice(Number(price), currency.label)}
                  </SoftTypography>
                )}

                <SoftBox
                  display="flex"
                  gap={{ xs: "0.5rem", md: "1rem" }}
                  flexDirection={{ xs: "row", xxl: "row" }}
                  flexWrap="wrap"
                >
                  {specialOffers.map((offer, i) => (
                    <SoftBox
                      key={i}
                      display="flex"
                      sx={{
                        backgroundColor: "#f7f7f7",
                        px: "0.4rem",
                        py: "0.2rem",
                        mt: "0.2rem",
                        borderRadius: "0.5rem",
                        maxWidth: "fit-content",
                      }}
                    >
                      <Icon color="success">star</Icon>
                      <SoftBox display="flex">
                        <SoftTypography
                          sx={({ typography, borders }) => ({
                            fontSize: typography.size.sm,
                            fontWeight: typography.fontWeightBold,
                            color: "#98999e",
                          })}
                        >
                          &nbsp;{offer.quantity} za{" "}
                          {formatPrice(
                            currency.value === "bam" ? offer.priceBAM : offer.priceEUR,
                            currency.label
                          )}
                          &nbsp;&nbsp;
                        </SoftTypography>
                        <SoftTypography
                          sx={({ typography, borders }) => ({
                            fontSize: typography.size.sm,
                            fontWeight: typography.fontWeightRegular,
                            color: "#98999e",
                            fontStyle: "italic",
                            textDecoration: "line-through",
                          })}
                        >
                          {formatPrice(offer.quantity * price, currency.label)}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  ))}
                </SoftBox>
                <SoftBox display="flex" alignItems="start" pt="1rem">
                  <SoftButton
                    disabled={isProductMaxCartQuantityPerItemReached}
                    variant="contained"
                    color="success"
                    onClick={handleAdd({
                      id: id,
                      name: title,
                      img: image,
                      quantity: 1,
                      unitPrice: price,
                      unitQuantity: quantity,
                    })}
                  >
                    <Icon>add_shopping_cart</Icon>&nbsp;&nbsp;Dodaj u korpu
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </>
          )}
        </SoftBox>
      </SoftBox>
    </Grid>
  );
};

Product.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  compositeProductItems: PropTypes.array,
  price: PropTypes.string,
  currency: PropTypes.object,
  image: PropTypes.string,
  onAddToCart: PropTypes.func,
  onlyInfo: PropTypes.bool,
  quantity: PropTypes.string,
  specialOffers: PropTypes.array,
  lazyLoading: PropTypes.bool,
  isProductMaxCartQuantityPerItemReached: PropTypes.bool,
  description: PropTypes.string,
  descriptionVariant: PropTypes.string,
  badgeText: PropTypes.string,
  badgeColor: PropTypes.string,
  badgetIcon: PropTypes.string,
  showBadge: PropTypes.bool,
  badgeTextColor: PropTypes.string,
  priceBeforeSale: PropTypes.string,
};

export default Product;
