import React from "react";
import { Grid, Divider, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const ProblemSolutionSection = () => {
  const problems = [
    "Ne izlaziš iz kuće bez pudera (kako bi sakrila nedostatke na licu).",
    "Bubuljice, akne i proširene pore su tvoja svakodnevica.",
    "Lice ti je upaljeno zbog iritacija.",
    "Prašina i teški metali se nakupljaju u porama, što dovodi do umornog izgleda, bora i alergijskih reakcija.",
    "Dehidrirana i suha koža - gubitak vlage i elastičnosti zbog stresa ili neadekvatne njege.",
    "Starenje kože - pojava bora, opuštena koža i neujednačen ten.",
    "Gubitak čvrstoće i prirodnog sjaja kože.",
    "Potraga za jednostavnom i efikasnom rutinom",
  ];

  const solutions = [
    "Zeolit uklanja nakupljene toksine, zagađenja i ostatke šminke, sprječavajući pojavu bubuljica, proširenih pora i alergijskih reakcija.",
    "Hranjivi sastojci poput ulja smilja, argana i avokada dubinski hrane kožu, povećavajući njenu elastičnost i smanjujući vidljivost bora.",
    "Eterična ulja čajevca, lavande i bergamota imaju antibakterijsko i protuupalno djelovanje, smanjujući akne, crvenilo i ožiljke.",
    "Smilje i ruža u kremi protiv bora, zeolitnoj maski i serumu ubrzavaju obnavljanje kože, smanjujući ožiljke i tamne mrlje.",
    "Receptura stara 20 godina: jednostavna rutina za sve tipove kože i generacije.",
  ];

  return (
    <SoftBox px={{ xs: 0, sm: 0, md: 6 }} py={{ xs: 3, sm: 4, md: 5 }} backgroundColor="#f8f8f8">
      <Grid container spacing={4}>
        {/* Problems Column */}
        <Grid item xs={12} md={6}>
          <SoftTypography variant="h4" mb={2} sx={{ color: "#000" }}>
            Da li su ovo tvoji problemi?
          </SoftTypography>
          <Divider sx={{ m: "0", mb: "2rem" }} />
          {problems.map((problem, index) => (
            <SoftBox key={index} display="flex" mb={2}>
              <Icon sx={{ color: "#ff5252", mr: 1 }}>close</Icon>
              <SoftTypography variant="body2" sx={{ textAlign: "left", color: "#000", fontSize: "14px" }}>{problem}</SoftTypography>
            </SoftBox>
          ))}
        </Grid>
        {/* Solutions Column */}
        <Grid item xs={12} md={6}>
          <SoftTypography variant="h4" mb={2} sx={{ color: "#000" }}>
            Evo kako ih rješavamo!
          </SoftTypography>
          <Divider sx={{ m: "0", mb: "2rem" }} />
          {solutions.map((solution, index) => (
            <SoftBox key={index} display="flex" mb={2}>
              <Icon sx={{ color: "#4caf50", mr: 1 }}>check_circle</Icon>
              <SoftTypography variant="body2" sx={{ textAlign: "left", color: "#000", fontSize: "14px" }}>{solution}</SoftTypography>
            </SoftBox>
          ))}
        </Grid>
      </Grid>
    </SoftBox>
  );
};

export default ProblemSolutionSection;
