/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Soft UI Dashboard PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

const { light, text, dark, grey, success } = colors;
const { borderRadius } = borders;
const { size } = typography;

const menuItem = {
  styleOverrides: {
    root: {
      minWidth: pxToRem(160),
      minHeight: "unset",
      padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
      // borderRadius: borderRadius.md,
      fontSize: size.sm,
      color: text.main,
      transition: "background-color 300ms ease, color 300ms ease",


      "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
        // backgroundColor: light.main,
        backgroundColor: "transparent",
        // backgroundColor: `${success.main} !important`,
        color: success.main,
        // paddingBottom: "1rem",
        // backgroundImage: `linear-gradient(to bottom, ${rgba(dark.main, 0)}, ${rgba(
        //   dark.main,
        //   0
        // )}, ) !important`,
        // borderBottom: `3px solid ${success.main}`,
      },

      "&:hover .MuiBox-root": {
        color: "#54d1c4",
      }
    },
  },
};

export default menuItem;
